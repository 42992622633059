import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom";
import { AboutScreen, AuthPhoneScreen, BookingDetailScreen, BookingsScreen, HomeScreen, PrivacyPolicyScreen, UsersScreen, YatriBookingScreen } from "../../screens";
import { selectIsLoggedIn, useAppSelector } from "../../store";

export const ROUTES = {
    HOME: "/",
    ABOUT: "/about",
    AUTH_PHONE: "/auth/phone",    
    // AUTH_VERIFY_OTP: "/auth/verifyOtp/:phone",    
    USER: "/users/show/:user_id",  
    PRIVACY_POLICY: "/privacy-policy",
    YATRI_BOOKING: "book/users/:user_id",    
    USER_BOOKINGS: "/bookings",
    USER_BOOKING_DETAILS: "/bookings/detail",    
    NOT_FOUND: "*",
  } as const;


// const PublicRoute = ({ children }) => {
//   const isLoggedIn = useAppSelector(selectIsLoggedIn);
//   console.log("PublicRoute - isLoggedIn ::", isLoggedIn);
//   return isLoggedIn ? <Navigate to="/" /> : children;
// };

// PublicRoute: Accessible to both authenticated and unauthenticated users
const PublicRoute = ({ children }) => children;

const PrivateRoute = ({ children }) => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  console.log("PrivateRoute - isLoggedIn ::", isLoggedIn);
  return isLoggedIn ? children : <Navigate to="/auth/phone" />;
};

// const router = createBrowserRouter(
//     createRoutesFromElements(
//       <>
//         <Route path={ROUTES.HOME} element={<HomeScreen />} />
//         <Route path={ROUTES.ABOUT} element={<AboutScreen />} />
//         <Route path={ROUTES.AUTH_PHONE} element={<AuthPhoneScreen />} />
//         {/* <Route path={ROUTES.AUTH_VERIFY_OTP} element={<AuthVerifyOtpScreen />} /> */}
//         <Route path={ROUTES.USER} element={<UsersScreen />} />        
//         <Route path={ROUTES.YATRI_BOOKING} element={<YatriBookingScreen />} />                
//         <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicyScreen />} />      
//         <Route path={ROUTES.NOT_FOUND} element={<div>Not found!</div>} />            
//       </>
//     )
//   );

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* AuthPhoneScreen: Public-only route */}
      <Route
        path={ROUTES.AUTH_PHONE}
        element={
          <PublicRoute>
            <AuthPhoneScreen />
          </PublicRoute>
        }
      />    
      {/* BookingsScreen, BookingDetailScreen: Private-only route */}
      <Route
        path={ROUTES.USER_BOOKINGS}
        element={
          <PrivateRoute>
            <BookingsScreen />
          </PrivateRoute>
        }
      />      
      <Route
        path={ROUTES.USER_BOOKING_DETAILS}
        element={
          <PrivateRoute>
            <BookingDetailScreen />
          </PrivateRoute>
        }
      />            
      {/* Other Routes: Accessible by both authenticated and unauthenticated users */}
      <Route
        path={ROUTES.HOME}
        element={
          <PublicRoute>
            <HomeScreen />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTES.ABOUT}
        element={
          <PublicRoute>
            <AboutScreen />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTES.USER}
        element={
          <PublicRoute>
            <UsersScreen />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTES.YATRI_BOOKING}
        element={
          <PublicRoute>
            <YatriBookingScreen />
          </PublicRoute>
        }
      />      
      <Route
        path={ROUTES.PRIVACY_POLICY}
        element={
          <PublicRoute>
            <PrivacyPolicyScreen />
          </PublicRoute>
        }
      />

      {/* Catch-All Route */}
      <Route path={ROUTES.NOT_FOUND} element={<div>404 Not Found</div>} />           
    </>
  )
);

export function AppRoutes(){

    return <RouterProvider router={router} />
}