import { Container } from "../../elements";
import { YStack } from "tamagui";
import { Home } from "./more-cta";

export function More({ onMenuPress }: { onMenuPress: () => void }) {
   
   
    return (
      <Container backgroundColor={'transparent'} paddingHorizontal="$2" paddingVertical="$2" gap="$2">

        <YStack gap="$1" onPress={onMenuPress}>
          {/* <MoreOptions /> */}
          <Home />
        </YStack>
      </Container>
    );
   }
   