// import { useDispatch, useSelector, useStore } from 'react-redux';

// Custom hook to use dispatch, selector, and store
// export const useRedux = () => {
//   const dispatch = useDispatch();
//   const store = useStore();
//   const selector = useSelector;
  
//   return { dispatch, store, selector };
// };

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { store } from './config';


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;