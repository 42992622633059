import { Container, ContainerProps, Spinner } from '../../../elements'

export function ContentLoader(props: ContainerProps) {
  return (
    <Container
      zIndex={1000}
      position="absolute"
      height="100%"
      width="100%"
      left={0}
      top={0}
      borderRadius={0}
      backgroundColor="$background"
      justifyContent="center"
      alignItems="center"
      {...props}>
      <Spinner size="large" color={'$primary'} />
    </Container>
  );
}