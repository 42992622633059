import { XStack } from "tamagui";
import { Icon } from "../icon";
import { Info } from "@tamagui/lucide-icons";
import { Typography, useThemeColorTokens } from "../../../elements";
import { ReactNode, useMemo } from "react";

type AlertProps = {
    severity: 'error' | 'info' | 'warning' | 'success'; 
    content?:string; children?: ReactNode
};

export function Alert({severity = 'info', content, children}: AlertProps){
    const {onErrorContainer, errorContainer, onPrimaryContainer, primaryContainer, onWarningContainer, warningContainer, onSuccessContainer, successContainer} = useThemeColorTokens();

    const colorConfig = useMemo(()=>{
        switch(severity){
            case 'error':
                return {
                    icon: "$error",
                    text: onErrorContainer,
                    container: errorContainer
                }
            case 'warning':
                return {
                    icon: "$warning",
                    text: onWarningContainer,
                    container: warningContainer
                }                
            case 'success':
                return {
                    icon: "$success",
                    text: onSuccessContainer,
                    container: successContainer
                }

            default:
                return {
                    icon: "$primary",
                    text: onPrimaryContainer,
                    container: primaryContainer
                }          
        }
    }, [severity, onErrorContainer, errorContainer, onPrimaryContainer, primaryContainer, onWarningContainer, warningContainer, onSuccessContainer, successContainer ])

    return (
        <XStack gap={"$1"} alignItems='center' backgroundColor={colorConfig.container} borderRadius={"$1"} padding={"$1"}>
            <Icon icon={<Info />} color={colorConfig.icon}/>
            {children && children}
            <Typography variant='body2' color={colorConfig.text}>{content}</Typography>
        </XStack>
    )
}

