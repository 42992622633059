export const light_rose = {
    primary: '#bd0143',
    onPrimary: '#ffffff',
    primaryContainer: '#ffd9dc',
    onPrimaryContainer: '#400011',
    primaryFixed: '#ffd9dc',
    onPrimaryFixed: '#400011',
    primaryFixedDim: '#ffb2ba',
    onPrimaryFixedVariant: '#910031',
    secondary: '#765659',
    onSecondary: '#ffffff',
    secondaryContainer: '#ffd9dc',
    onSecondaryContainer: '#2c1518',
    secondaryFixed: '#ffd9dc',
    onSecondaryFixed: '#2c1518',
    secondaryFixedDim: '#e5bdc0',
    onSecondaryFixedVariant: '#5c3f42',
    tertiary: '#785830',
    onTertiary: '#ffffff',
    tertiaryContainer: '#ffddb7',
    onTertiaryContainer: '#2a1700',
    tertiaryFixed: '#ffddb7',
    onTertiaryFixed: '#2a1700',
    tertiaryFixedDim: '#e9bf8f',
    onTertiaryFixedVariant: '#5e411b',
    error: '#ba1a1a',
    onError: '#ffffff',
    errorContainer: '#ffdad6',
    onErrorContainer: '#410002',
    outline: '#847374',
    background: '#fffbff',
    background2: '#f2f4f7',    
    onBackground: '#201a1a',
    surface: '#fff8f7',
    onSurface: '#201a1a',
    surfaceVariant: '#f4ddde',
    onSurfaceVariant: '#524344',
    inverseSurface: '#362f2f',
    inverseOnSurface: '#fbeeee',
    inversePrimary: '#ffb2ba',
    shadow: '#000000',
    surfaceTint: '#bd0143',
    outlineVariant: '#d7c1c3',
    scrim: '#000000',
    surfaceContainerHighest: '#ece0e0',
    surfaceContainerHigh: '#f2e5e5',
    surfaceContainer: '#f8ebeb',
    surfaceContainerLow: '#fef1f1',
    surfaceContainerLowest: '#ffffff',
    surfaceBright: '#fff8f7',
    surfaceDim: '#e4d7d7',
    primaryOpacity8P: '#bd014314',
    primaryOpacity12P: '#bd01431f',
    primaryOpacity16P: '#bd014329',
    onPrimaryOpacity8P: '#ffffff14',
    onPrimaryOpacity12P: '#ffffff1f',
    onPrimaryOpacity16P: '#ffffff29',
    primaryContainerOpacity8P: '#ffd9dc14',
    primaryContainerOpacity12P: '#ffd9dc1f',
    primaryContainerOpacity16P: '#ffd9dc29',
    onPrimaryContainerOpacity8P: '#40001114',
    onPrimaryContainerOpacity12P: '#4000111f',
    onPrimaryContainerOpacity16P: '#40001129',
    primaryFixedOpacity8P: '#ffd9dc14',
    primaryFixedOpacity12P: '#ffd9dc1f',
    primaryFixedOpacity16P: '#ffd9dc29',
    onPrimaryFixedOpacity8P: '#40001114',
    onPrimaryFixedOpacity12P: '#4000111f',
    onPrimaryFixedOpacity16P: '#40001129',
    primaryFixedDimOpacity8P: '#ffb2ba14',
    primaryFixedDimOpacity12P: '#ffb2ba1f',
    primaryFixedDimOpacity16P: '#ffb2ba29',
    onPrimaryFixedVariantOpacity8P: '#91003114',
    onPrimaryFixedVariantOpacity12P: '#9100311f',
    onPrimaryFixedVariantOpacity16P: '#91003129',
    secondaryOpacity8P: '#76565914',
    secondaryOpacity12P: '#7656591f',
    secondaryOpacity16P: '#76565929',
    onSecondaryOpacity8P: '#ffffff14',
    onSecondaryOpacity12P: '#ffffff1f',
    onSecondaryOpacity16P: '#ffffff29',
    secondaryContainerOpacity8P: '#ffd9dc14',
    secondaryContainerOpacity12P: '#ffd9dc1f',
    secondaryContainerOpacity16P: '#ffd9dc29',
    onSecondaryContainerOpacity8P: '#2c151814',
    onSecondaryContainerOpacity12P: '#2c15181f',
    onSecondaryContainerOpacity16P: '#2c151829',
    secondaryFixedOpacity8P: '#ffd9dc14',
    secondaryFixedOpacity12P: '#ffd9dc1f',
    secondaryFixedOpacity16P: '#ffd9dc29',
    onSecondaryFixedOpacity8P: '#2c151814',
    onSecondaryFixedOpacity12P: '#2c15181f',
    onSecondaryFixedOpacity16P: '#2c151829',
    secondaryFixedDimOpacity8P: '#e5bdc014',
    secondaryFixedDimOpacity12P: '#e5bdc01f',
    secondaryFixedDimOpacity16P: '#e5bdc029',
    onSecondaryFixedVariantOpacity8P: '#5c3f4214',
    onSecondaryFixedVariantOpacity12P: '#5c3f421f',
    onSecondaryFixedVariantOpacity16P: '#5c3f4229',
    tertiaryOpacity8P: '#78583014',
    tertiaryOpacity12P: '#7858301f',
    tertiaryOpacity16P: '#78583029',
    onTertiaryOpacity8P: '#ffffff14',
    onTertiaryOpacity12P: '#ffffff1f',
    onTertiaryOpacity16P: '#ffffff29',
    tertiaryContainerOpacity8P: '#ffddb714',
    tertiaryContainerOpacity12P: '#ffddb71f',
    tertiaryContainerOpacity16P: '#ffddb729',
    onTertiaryContainerOpacity8P: '#2a170014',
    onTertiaryContainerOpacity12P: '#2a17001f',
    onTertiaryContainerOpacity16P: '#2a170029',
    tertiaryFixedOpacity8P: '#ffddb714',
    tertiaryFixedOpacity12P: '#ffddb71f',
    tertiaryFixedOpacity16P: '#ffddb729',
    onTertiaryFixedOpacity8P: '#2a170014',
    onTertiaryFixedOpacity12P: '#2a17001f',
    onTertiaryFixedOpacity16P: '#2a170029',
    tertiaryFixedDimOpacity8P: '#e9bf8f14',
    tertiaryFixedDimOpacity12P: '#e9bf8f1f',
    tertiaryFixedDimOpacity16P: '#e9bf8f29',
    onTertiaryFixedVariantOpacity8P: '#5e411b14',
    onTertiaryFixedVariantOpacity12P: '#5e411b1f',
    onTertiaryFixedVariantOpacity16P: '#5e411b29',
    errorOpacity8P: '#ba1a1a14',
    errorOpacity12P: '#ba1a1a1f',
    errorOpacity16P: '#ba1a1a29',
    onErrorOpacity8P: '#ffffff14',
    onErrorOpacity12P: '#ffffff1f',
    onErrorOpacity16P: '#ffffff29',
    errorContainerOpacity8P: '#ffdad614',
    errorContainerOpacity12P: '#ffdad61f',
    errorContainerOpacity16P: '#ffdad629',
    onErrorContainerOpacity8P: '#41000214',
    onErrorContainerOpacity12P: '#4100021f',
    onErrorContainerOpacity16P: '#41000229',
    outlineOpacity8P: '#84737414',
    outlineOpacity12P: '#8473741f',
    outlineOpacity16P: '#84737429',
    backgroundOpacity8P: '#fffbff14',
    backgroundOpacity12P: '#fffbff1f',
    backgroundOpacity16P: '#fffbff29',
    onBackgroundOpacity8P: '#201a1a14',
    onBackgroundOpacity12P: '#201a1a1f',
    onBackgroundOpacity16P: '#201a1a29',
    surfaceOpacity8P: '#fff8f714',
    surfaceOpacity12P: '#fff8f71f',
    surfaceOpacity16P: '#fff8f729',
    onSurfaceOpacity8P: '#201a1a14',
    onSurfaceOpacity12P: '#201a1a1f',
    onSurfaceOpacity16P: '#201a1a29',
    surfaceVariantOpacity8P: '#f4ddde14',
    surfaceVariantOpacity12P: '#f4ddde1f',
    surfaceVariantOpacity16P: '#f4ddde29',
    onSurfaceVariantOpacity8P: '#52434414',
    onSurfaceVariantOpacity12P: '#5243441f',
    onSurfaceVariantOpacity16P: '#52434429',
    inverseSurfaceOpacity8P: '#362f2f14',
    inverseSurfaceOpacity12P: '#362f2f1f',
    inverseSurfaceOpacity16P: '#362f2f29',
    inverseOnSurfaceOpacity8P: '#fbeeee14',
    inverseOnSurfaceOpacity12P: '#fbeeee1f',
    inverseOnSurfaceOpacity16P: '#fbeeee29',
    inversePrimaryOpacity8P: '#ffb2ba14',
    inversePrimaryOpacity12P: '#ffb2ba1f',
    inversePrimaryOpacity16P: '#ffb2ba29',
    shadowOpacity8P: '#00000014',
    shadowOpacity12P: '#0000001f',
    shadowOpacity16P: '#00000029',
    surfaceTintOpacity8P: '#bd014314',
    surfaceTintOpacity12P: '#bd01431f',
    surfaceTintOpacity16P: '#bd014329',
    outlineVariantOpacity8P: '#d7c1c314',
    outlineVariantOpacity12P: '#d7c1c31f',
    outlineVariantOpacity16P: '#d7c1c329',
    scrimOpacity8P: '#00000014',
    scrimOpacity12P: '#0000001f',
    scrimOpacity16P: '#00000029',
    surfaceContainerHighestOpacity8P: '#ece0e014',
    surfaceContainerHighestOpacity12P: '#ece0e01f',
    surfaceContainerHighestOpacity16P: '#ece0e029',
    surfaceContainerHighOpacity8P: '#f2e5e514',
    surfaceContainerHighOpacity12P: '#f2e5e51f',
    surfaceContainerHighOpacity16P: '#f2e5e529',
    surfaceContainerOpacity8P: '#f8ebeb14',
    surfaceContainerOpacity12P: '#f8ebeb1f',
    surfaceContainerOpacity16P: '#f8ebeb29',
    surfaceContainerLowOpacity8P: '#fef1f114',
    surfaceContainerLowOpacity12P: '#fef1f11f',
    surfaceContainerLowOpacity16P: '#fef1f129',
    surfaceContainerLowestOpacity8P: '#ffffff14',
    surfaceContainerLowestOpacity12P: '#ffffff1f',
    surfaceContainerLowestOpacity16P: '#ffffff29',
    surfaceBrightOpacity8P: '#fff8f714',
    surfaceBrightOpacity12P: '#fff8f71f',
    surfaceBrightOpacity16P: '#fff8f729',
    surfaceDimOpacity8P: '#e4d7d714',
    surfaceDimOpacity12P: '#e4d7d71f',
    surfaceDimOpacity16P: '#e4d7d729'
};
export const dark_rose = {
    primary: '#ffb2ba',
    onPrimary: '#670020',
    primaryContainer: '#910031',
    onPrimaryContainer: '#ffd9dc',
    primaryFixed: '#ffd9dc',
    onPrimaryFixed: '#400011',
    primaryFixedDim: '#ffb2ba',
    onPrimaryFixedVariant: '#910031',
    secondary: '#e5bdc0',
    onSecondary: '#43292c',
    secondaryContainer: '#5c3f42',
    onSecondaryContainer: '#ffd9dc',
    secondaryFixed: '#ffd9dc',
    onSecondaryFixed: '#2c1518',
    secondaryFixedDim: '#e5bdc0',
    onSecondaryFixedVariant: '#5c3f42',
    tertiary: '#e9bf8f',
    onTertiary: '#442b07',
    tertiaryContainer: '#5e411b',
    onTertiaryContainer: '#ffddb7',
    tertiaryFixed: '#ffddb7',
    onTertiaryFixed: '#2a1700',
    tertiaryFixedDim: '#e9bf8f',
    onTertiaryFixedVariant: '#5e411b',
    error: '#ffb4ab',
    onError: '#690005',
    errorContainer: '#93000a',
    onErrorContainer: '#ffdad6',
    outline: '#9f8c8d',
    background: '#201a1a',
    background2: '#f2f4f7',    
    onBackground: '#ece0e0',
    surface: '#181212',
    onSurface: '#d0c4c4',
    surfaceVariant: '#524344',
    onSurfaceVariant: '#d7c1c3',
    inverseSurface: '#ece0e0',
    inverseOnSurface: '#201a1a',
    inversePrimary: '#bd0143',
    shadow: '#000000',
    surfaceTint: '#ffb2ba',
    outlineVariant: '#524344',
    scrim: '#000000',
    surfaceContainerHighest: '#3a3333',
    surfaceContainerHigh: '#2f2829',
    surfaceContainer: '#241e1e',
    surfaceContainerLow: '#201a1a',
    surfaceContainerLowest: '#120d0d',
    surfaceBright: '#3f3738',
    surfaceDim: '#181212',
    primaryOpacity8P: '#ffb2ba14',
    primaryOpacity12P: '#ffb2ba1f',
    primaryOpacity16P: '#ffb2ba29',
    onPrimaryOpacity8P: '#67002014',
    onPrimaryOpacity12P: '#6700201f',
    onPrimaryOpacity16P: '#67002029',
    primaryContainerOpacity8P: '#91003114',
    primaryContainerOpacity12P: '#9100311f',
    primaryContainerOpacity16P: '#91003129',
    onPrimaryContainerOpacity8P: '#ffd9dc14',
    onPrimaryContainerOpacity12P: '#ffd9dc1f',
    onPrimaryContainerOpacity16P: '#ffd9dc29',
    primaryFixedOpacity8P: '#ffd9dc14',
    primaryFixedOpacity12P: '#ffd9dc1f',
    primaryFixedOpacity16P: '#ffd9dc29',
    onPrimaryFixedOpacity8P: '#40001114',
    onPrimaryFixedOpacity12P: '#4000111f',
    onPrimaryFixedOpacity16P: '#40001129',
    primaryFixedDimOpacity8P: '#ffb2ba14',
    primaryFixedDimOpacity12P: '#ffb2ba1f',
    primaryFixedDimOpacity16P: '#ffb2ba29',
    onPrimaryFixedVariantOpacity8P: '#91003114',
    onPrimaryFixedVariantOpacity12P: '#9100311f',
    onPrimaryFixedVariantOpacity16P: '#91003129',
    secondaryOpacity8P: '#e5bdc014',
    secondaryOpacity12P: '#e5bdc01f',
    secondaryOpacity16P: '#e5bdc029',
    onSecondaryOpacity8P: '#43292c14',
    onSecondaryOpacity12P: '#43292c1f',
    onSecondaryOpacity16P: '#43292c29',
    secondaryContainerOpacity8P: '#5c3f4214',
    secondaryContainerOpacity12P: '#5c3f421f',
    secondaryContainerOpacity16P: '#5c3f4229',
    onSecondaryContainerOpacity8P: '#ffd9dc14',
    onSecondaryContainerOpacity12P: '#ffd9dc1f',
    onSecondaryContainerOpacity16P: '#ffd9dc29',
    secondaryFixedOpacity8P: '#ffd9dc14',
    secondaryFixedOpacity12P: '#ffd9dc1f',
    secondaryFixedOpacity16P: '#ffd9dc29',
    onSecondaryFixedOpacity8P: '#2c151814',
    onSecondaryFixedOpacity12P: '#2c15181f',
    onSecondaryFixedOpacity16P: '#2c151829',
    secondaryFixedDimOpacity8P: '#e5bdc014',
    secondaryFixedDimOpacity12P: '#e5bdc01f',
    secondaryFixedDimOpacity16P: '#e5bdc029',
    onSecondaryFixedVariantOpacity8P: '#5c3f4214',
    onSecondaryFixedVariantOpacity12P: '#5c3f421f',
    onSecondaryFixedVariantOpacity16P: '#5c3f4229',
    tertiaryOpacity8P: '#e9bf8f14',
    tertiaryOpacity12P: '#e9bf8f1f',
    tertiaryOpacity16P: '#e9bf8f29',
    onTertiaryOpacity8P: '#442b0714',
    onTertiaryOpacity12P: '#442b071f',
    onTertiaryOpacity16P: '#442b0729',
    tertiaryContainerOpacity8P: '#5e411b14',
    tertiaryContainerOpacity12P: '#5e411b1f',
    tertiaryContainerOpacity16P: '#5e411b29',
    onTertiaryContainerOpacity8P: '#ffddb714',
    onTertiaryContainerOpacity12P: '#ffddb71f',
    onTertiaryContainerOpacity16P: '#ffddb729',
    tertiaryFixedOpacity8P: '#ffddb714',
    tertiaryFixedOpacity12P: '#ffddb71f',
    tertiaryFixedOpacity16P: '#ffddb729',
    onTertiaryFixedOpacity8P: '#2a170014',
    onTertiaryFixedOpacity12P: '#2a17001f',
    onTertiaryFixedOpacity16P: '#2a170029',
    tertiaryFixedDimOpacity8P: '#e9bf8f14',
    tertiaryFixedDimOpacity12P: '#e9bf8f1f',
    tertiaryFixedDimOpacity16P: '#e9bf8f29',
    onTertiaryFixedVariantOpacity8P: '#5e411b14',
    onTertiaryFixedVariantOpacity12P: '#5e411b1f',
    onTertiaryFixedVariantOpacity16P: '#5e411b29',
    errorOpacity8P: '#ffb4ab14',
    errorOpacity12P: '#ffb4ab1f',
    errorOpacity16P: '#ffb4ab29',
    onErrorOpacity8P: '#69000514',
    onErrorOpacity12P: '#6900051f',
    onErrorOpacity16P: '#69000529',
    errorContainerOpacity8P: '#93000a14',
    errorContainerOpacity12P: '#93000a1f',
    errorContainerOpacity16P: '#93000a29',
    onErrorContainerOpacity8P: '#ffdad614',
    onErrorContainerOpacity12P: '#ffdad61f',
    onErrorContainerOpacity16P: '#ffdad629',
    outlineOpacity8P: '#9f8c8d14',
    outlineOpacity12P: '#9f8c8d1f',
    outlineOpacity16P: '#9f8c8d29',
    backgroundOpacity8P: '#201a1a14',
    backgroundOpacity12P: '#201a1a1f',
    backgroundOpacity16P: '#201a1a29',
    onBackgroundOpacity8P: '#ece0e014',
    onBackgroundOpacity12P: '#ece0e01f',
    onBackgroundOpacity16P: '#ece0e029',
    surfaceOpacity8P: '#18121214',
    surfaceOpacity12P: '#1812121f',
    surfaceOpacity16P: '#18121229',
    onSurfaceOpacity8P: '#d0c4c414',
    onSurfaceOpacity12P: '#d0c4c41f',
    onSurfaceOpacity16P: '#d0c4c429',
    surfaceVariantOpacity8P: '#52434414',
    surfaceVariantOpacity12P: '#5243441f',
    surfaceVariantOpacity16P: '#52434429',
    onSurfaceVariantOpacity8P: '#d7c1c314',
    onSurfaceVariantOpacity12P: '#d7c1c31f',
    onSurfaceVariantOpacity16P: '#d7c1c329',
    inverseSurfaceOpacity8P: '#ece0e014',
    inverseSurfaceOpacity12P: '#ece0e01f',
    inverseSurfaceOpacity16P: '#ece0e029',
    inverseOnSurfaceOpacity8P: '#201a1a14',
    inverseOnSurfaceOpacity12P: '#201a1a1f',
    inverseOnSurfaceOpacity16P: '#201a1a29',
    inversePrimaryOpacity8P: '#bd014314',
    inversePrimaryOpacity12P: '#bd01431f',
    inversePrimaryOpacity16P: '#bd014329',
    shadowOpacity8P: '#00000014',
    shadowOpacity12P: '#0000001f',
    shadowOpacity16P: '#00000029',
    surfaceTintOpacity8P: '#ffb2ba14',
    surfaceTintOpacity12P: '#ffb2ba1f',
    surfaceTintOpacity16P: '#ffb2ba29',
    outlineVariantOpacity8P: '#52434414',
    outlineVariantOpacity12P: '#5243441f',
    outlineVariantOpacity16P: '#52434429',
    scrimOpacity8P: '#00000014',
    scrimOpacity12P: '#0000001f',
    scrimOpacity16P: '#00000029',
    surfaceContainerHighestOpacity8P: '#3a333314',
    surfaceContainerHighestOpacity12P: '#3a33331f',
    surfaceContainerHighestOpacity16P: '#3a333329',
    surfaceContainerHighOpacity8P: '#2f282914',
    surfaceContainerHighOpacity12P: '#2f28291f',
    surfaceContainerHighOpacity16P: '#2f282929',
    surfaceContainerOpacity8P: '#241e1e14',
    surfaceContainerOpacity12P: '#241e1e1f',
    surfaceContainerOpacity16P: '#241e1e29',
    surfaceContainerLowOpacity8P: '#201a1a14',
    surfaceContainerLowOpacity12P: '#201a1a1f',
    surfaceContainerLowOpacity16P: '#201a1a29',
    surfaceContainerLowestOpacity8P: '#120d0d14',
    surfaceContainerLowestOpacity12P: '#120d0d1f',
    surfaceContainerLowestOpacity16P: '#120d0d29',
    surfaceBrightOpacity8P: '#3f373814',
    surfaceBrightOpacity12P: '#3f37381f',
    surfaceBrightOpacity16P: '#3f373829',
    surfaceDimOpacity8P: '#18121214',
    surfaceDimOpacity12P: '#1812121f',
    surfaceDimOpacity16P: '#18121229'
};

   
   
   
   
   