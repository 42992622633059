// authSlice.js
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PandaSevaCookies, setAccessTokenCookie, UserRole } from '../../services/api';
import { AppStartListening, listenerMiddleware } from '../listener-middleware';
import uuid from 'react-native-uuid';
import { clearAllCookies, setCookie } from '../../libs/cookies';
import { selectRootState } from '../utils';


console.log('Initializing auth-reducer...');

type UserType = {
  id: string | number;
  name: string;
  role: UserRole;
}

type AuthSessionType = {
  accessToken: string;
  refreshToken: string;
  user: UserType; // check if this is required in this type
}

type AuthStateType = {
  accessToken: string | null;
  refreshToken: string | null;
  sessionId: string | null;
  user: UserType | null;
}

const initialState: AuthStateType = {
  // token: '',
  // isAuthenticated: false,
  // deprecate above 
  accessToken: null,
  refreshToken: null,   
  sessionId: null, 
  user: null
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    // setToken(state, action) {
    //   state.token = action.payload.token;
    //   state.isAuthenticated = true;
    // },
    // clearToken(state) {
    //   state.token = '';
    //   state.isAuthenticated = false;
    // },
    saveSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload || null;
    },
    // saveAuthSession: (state, action: PayloadAction<AuthSessionType>) => {
    //   return { ...state, ...action.payload };
    // },
    saveAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload || null
    },
    clearAuth: () => {
      clearAllCookies();
      return { ...initialState };
    }
  },
});
const {reducer: authReducer, actions: authActions} = authSlice;

// // listener/effects
// export function addAuthListeners(startListening: AppStartListening){
//   startListening({
//     actionCreator: authActions.saveAuthSession,
//     effect: async (action, listener) => {
//       console.log('authActions:', authActions);      
//       const { payload } = action;
//       const sessionId = uuid.v4();
//       console.log("sessionId - payload ::", sessionId, payload);      
//       setAccessTokenCookie(payload.accessToken);
//       setCookie({ cookie: { name: PandaSevaCookies.SESSION_ID, value: sessionId }});
//       listener.dispatch(authActions.saveSessionId(sessionId));
//     }
//   });
// }
// addAuthListeners(listenerMiddleware.startListening)


export const selectAccessToken = createSelector(
  selectRootState,
  (state) => state.authSlice?.accessToken
)
export const selectIsLoggedIn = createSelector(
  selectRootState,
  (state) => !!state.authSlice?.accessToken  
)

// export default authSlice.reducer;
export { authReducer, authActions };