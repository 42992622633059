import { CookieType } from './types';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export async function setCookie({ cookie }: { cookie: CookieType; host?: string }) {
 cookies.set(cookie.name, cookie.value, {
   ...cookie,
   expires: cookie.expires ? new Date(cookie.expires) : undefined
 });
}


export async function getCookie({ name }: { name: string; host?: string }) {
 try {
   return cookies.get(name);
 } catch (e) {
   //TODO: log error
 }
}


export async function clearAllCookies() {
 try {
   Object.keys(cookies.getAll()).forEach((cookieName) => cookies.remove(cookieName));
 } catch (e) {
   //TODO: log error
 }
}





