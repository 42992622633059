import { Typography, useThemeColorTokens } from "../../elements";
import { Stack, useMedia, XStack } from "tamagui";
import { ChildrensWithSeparator } from "../ui";
import { Link } from "react-router-dom";

export function Footer({...props}){
    const { surfaceContainerHigh, onSurface } = useThemeColorTokens();    
    const media = useMedia();    

    return (
        <Stack padding={"$4"} backgroundColor={surfaceContainerHigh} width={"100%"} bottom={0} left={0} zIndex={1000} {...props}>
            <ChildrensWithSeparator
                flex={1}
                separator={media.tablet ? null : <Typography variant="caption">·</Typography>}
                flexDirection="row"
                $tablet={{ flexDirection: 'column', gap: '$0.5' }}
                justifyContent="center"
                alignItems="center"
                gap="$1">
                    <Typography userSelect="none" variant="caption" textAlign="center">© 2022 - 2025 Ayanshala Technologies Pvt. Ltd. All rights reserved</Typography>
                    <Link
                        to={"/privacy-policy"}
                        target={'_blank'}>
                        <XStack>
                            <Typography hoverStyle={{ textDecoration: 'underline' }} variant="caption" textAlign="center">Privacy</Typography>
                        </XStack>
                    </Link>
            </ChildrensWithSeparator>
        </Stack>
    )
}