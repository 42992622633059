import { getCookie, setCookie } from "../../libs/cookies";
import { PandaSevaCookies } from "./constants";
import dayjs from 'dayjs';

const cookieExpiry = dayjs().add(5, 'years').format('YYYY-MM-DDTHH:mm:ss.sssZ').toString();

export async function setAccessTokenCookie(accessToken: string) {
 if (accessToken) {
   setCookie({
     cookie: {
       name: PandaSevaCookies.ACCESS_TOKEN,
       value: accessToken,
       version: '1',
       expires: cookieExpiry,
       secure: false,//true,    //!isDebugBuild(),
       httpOnly: false//true   //!isDebugBuild()
     }
   });
 }
}

export async function getAccessTokenCookie() {
  const cook = await getCookie({ name: PandaSevaCookies.ACCESS_TOKEN })
  console.log("getAccessTokenCookie cook ::", cook);

  return cook;
//  return "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJiMmRkNmJlNy04YWViLTRmNzItOTQ1NC1mMmQ1NGY0YTlhNzAiLCJleHAiOjE3NzQyNDM5ODksImlhdCI6MTc0MjcwNzk4OX0.tmCtvPIfV6dkHgTi4cELQrvmxok5YMN_ByluzNjIrjQ";
}



