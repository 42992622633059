import { X } from '@tamagui/lucide-icons'
import { useThemeColorTokens } from '../../elements';
import {
  Adapt,
  Button,
  Dialog,
  Fieldset,
  Input,
  Label,
  Paragraph,
  Sheet,
  TooltipSimple,
  Unspaced,
  View,
  XStack,
} from 'tamagui'
// import { SelectDemoItem } from './SelectDemo'

// export function YatriBooking() {
//   return (
//     <View gap="$4">
//       <DialogInstance />
//       <DialogInstance disableAdapt />
//     </View>
//   )
// }

export function YatriBooking({ disableAdapt }: { disableAdapt?: boolean }) {
    const { primary, surfaceContainerLowest, onSurfaceOpacity16P, onSurface } = useThemeColorTokens();
    function onBookNowPress(){

    }    
  return (
    <Dialog modal>
      <Dialog.Trigger asChild>
          <Button padding={20} backgroundColor={primary} size='md' color={'white'} children={'Book Now'}/>
      </Dialog.Trigger>

      {!disableAdapt && (
        <Adapt when="sm" platform="touch">
          <Sheet animation="medium" zIndex={200000} modal dismissOnSnapToBottom>
            <Sheet.Frame padding="$4" gap="$4">
              <Adapt.Contents />
            </Sheet.Frame>
            <Sheet.Overlay
              backgroundColor="$shadow6"
              animation="lazy"
              enterStyle={{ opacity: 0 }}
              exitStyle={{ opacity: 0 }}
            />
          </Sheet>
        </Adapt>
      )}

      <Dialog.Portal>
        <Dialog.Overlay
          key="overlay"
          backgroundColor={onSurfaceOpacity16P}
          animation="slow"
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
        />

        <Dialog.Content
          bordered
          elevate
          key="content"
          animateOnly={['transform', 'opacity']}
          animation={[
            'quicker',
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}
          enterStyle={{ x: 0, y: -20, opacity: 0, scale: 0.9 }}
          exitStyle={{ x: 0, y: 10, opacity: 0, scale: 0.95 }}
          backgroundColor={surfaceContainerLowest}
          gap="$4"
        >
          <Dialog.Title>Yatri Booking</Dialog.Title>
          <Dialog.Description>
            Make changes to your profile here. Click save when you're done.
          </Dialog.Description>
          <Fieldset gap="$4" horizontal>
            <Label width={130} justifyContent="flex-end" htmlFor="name">
              Name
            </Label>
            <Input flex={1} id="name" defaultValue="Nate Wienert" />
          </Fieldset>
          <Fieldset gap="$4" horizontal>
            <Label width={130} justifyContent="flex-end" htmlFor="username">
              <TooltipSimple label="Pick your favorite" placement="bottom-start">
                <Paragraph>Food</Paragraph>
              </TooltipSimple>
            </Label>
            {/* <SelectDemoItem /> */}
          </Fieldset>

          <XStack alignSelf="flex-end" gap="$4">
            {/* <DialogInstance /> */}

            <Dialog.Close displayWhenAdapted asChild>
              <Button theme="accent" aria-label="Close">
                Save changes
              </Button>
            </Dialog.Close>
          </XStack>

          <Unspaced>
            <Dialog.Close asChild>
              <Button
                position="absolute"
                top="$3"
                right="$3"
                size="$2"
                circular
                icon={X}
              />
            </Dialog.Close>
          </Unspaced>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog>
  )
}