import { Check as CheckIcon } from '@tamagui/lucide-icons'
import { Typography } from '../../../elements'
import type { CheckboxProps } from 'tamagui'
import { Checkbox as TMCheckbox, Label, XStack, YStack } from 'tamagui'

// export function CheckboxDemo() {
//   return (
//     <YStack width={300} alignItems="center" gap="$2">
//       <CheckboxWithLabel size="$3" />
//       <CheckboxWithLabel size="$4" defaultChecked />
//       <CheckboxWithLabel size="$5" disabled label="Accept terms (disabled)" />
//     </YStack>
//   )
// }

export function Checkbox({
  size,
  label = 'Accept terms and conditions',
  ...checkboxProps
}: CheckboxProps & { label?: string }) {
  const id = `checkbox-${(size || '').toString().slice(1)}`
  return (
    <XStack width={300} alignItems="center" gap="$4">
      <TMCheckbox id={id} size={size} {...checkboxProps}>
        <TMCheckbox.Indicator>
          <CheckIcon />
        </TMCheckbox.Indicator>
      </TMCheckbox>

      {/* <Label size={size} htmlFor={id}>
        {label}
      </Label> */}
      <Typography variant="body1" color={'$onSurface'}>{label}</Typography>            
    </XStack>
  )
}