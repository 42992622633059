// import { useGridSpacing, useIsExecutingInNativeApp, useMount } from '../../../hooks';
// import { getCookie } from '../../../libs/react-native-cookies';
import { Grid, GridProps, Stack, Typography, useBreakpoint } from '../../elements';
import { useState } from 'react';
import { Platform } from 'react-native';
import { useGridSpacing } from '../../hooks';


export type CenteredLayoutProps = GridProps;


export function CenteredLayout({ children, ...props }: CenteredLayoutProps) {
 const [showGrids, toggleShowGrids] = useState<boolean>(false);
 const gridSpacing = useGridSpacing();
 const marginByBreakpoint = useBreakpoint({
   gtXXs: 16,
   gtSm: 56,
   gtMd: 88,
   gtTablet: 88,
   gtLg: 176,
   gtXXl: 'auto'
 });
 const margin = marginByBreakpoint.gt || marginByBreakpoint.lt;
 const bgColor1 = '$primaryFixedOpacity16P';
 const bgColor2 = '$tertiaryContainerOpacity16P';
//  const isExecutingInNativeApp = useIsExecutingInNativeApp();


//  useMount(() => {
//    async function load() {
//      if (Platform.OS == 'web') {
//        toggleShowGrids((await getCookie({ name: 'grids' })) || false);
//      }
//    }
//    load();
//  });


 return (
   <>
     <Stack
       paddingTop={gridSpacing}
       $gtXXl={{ minWidth: 1440, maxWidth: 1440 }}
       marginHorizontal={margin}
    //    marginTop={Platform.select({ web: isExecutingInNativeApp ? 0 : '$8' })}
       {...props}>
       {children}
     </Stack>
     {showGrids && (
       <Stack
         pointerEvents="none"
         height={'100%'}
         left={0}
         right={0}
         zIndex={1000000000000}
         paddingHorizontal={margin}
         $gtXXl={{ alignItems: 'center' }}
         position="absolute"
         width={'100%'}>
         <Grid container row wrap spacing={gridSpacing} $gtXXl={{ width: 1468 }}>
           <Grid item xs={1}>
             <Stack height={'100%'} backgroundColor={bgColor1}>
               <Typography
                 variant="subtitle2"
                 textAlign="center"
                 alignSelf="center"
                 position="fixed">
                 1
               </Typography>
             </Stack>
           </Grid>
           <Grid item xs={1}>
             <Stack height={'100%'} backgroundColor={bgColor2}>
               <Typography
                 variant="subtitle2"
                 textAlign="center"
                 alignSelf="center"
                 position="fixed">
                 2
               </Typography>
             </Stack>
           </Grid>
           <Grid item xs={1}>
             <Stack height={'100%'} backgroundColor={bgColor1}>
               <Typography
                 variant="subtitle2"
                 textAlign="center"
                 alignSelf="center"
                 position="fixed">
                 3
               </Typography>
             </Stack>
           </Grid>
           <Grid item xs={1}>
             <Stack height={'100%'} backgroundColor={bgColor2}>
               <Typography
                 variant="subtitle2"
                 textAlign="center"
                 alignSelf="center"
                 position="fixed">
                 4
               </Typography>
             </Stack>
           </Grid>
           <Grid item xs={1}>
             <Stack height={'100%'} backgroundColor={bgColor1}>
               <Typography
                 variant="subtitle2"
                 textAlign="center"
                 alignSelf="center"
                 position="fixed">
                 5
               </Typography>
             </Stack>
           </Grid>
           <Grid item xs={1}>
             <Stack height={'100%'} backgroundColor={bgColor2}>
               <Typography
                 variant="subtitle2"
                 textAlign="center"
                 alignSelf="center"
                 position="fixed">
                 6
               </Typography>
             </Stack>
           </Grid>
           <Grid item xs={1}>
             <Stack height={'100%'} backgroundColor={bgColor1}>
               <Typography
                 variant="subtitle2"
                 textAlign="center"
                 alignSelf="center"
                 position="fixed">
                 7
               </Typography>
             </Stack>
           </Grid>
           <Grid item xs={1}>
             <Stack height={'100%'} backgroundColor={bgColor2}>
               <Typography
                 variant="subtitle2"
                 textAlign="center"
                 alignSelf="center"
                 position="fixed">
                 8
               </Typography>
             </Stack>
           </Grid>
           <Grid item xs={1}>
             <Stack height={'100%'} backgroundColor={bgColor1}>
               <Typography
                 variant="subtitle2"
                 textAlign="center"
                 alignSelf="center"
                 position="fixed">
                 9
               </Typography>
             </Stack>
           </Grid>
           <Grid item xs={1}>
             <Stack height={'100%'} backgroundColor={bgColor2}>
               <Typography
                 variant="subtitle2"
                 textAlign="center"
                 alignSelf="center"
                 position="fixed">
                 10
               </Typography>
             </Stack>
           </Grid>
           <Grid item xs={1}>
             <Stack height={'100%'} backgroundColor={bgColor1}>
               <Typography
                 variant="subtitle2"
                 textAlign="center"
                 alignSelf="center"
                 position="fixed">
                 11
               </Typography>
             </Stack>
           </Grid>
           <Grid item xs={1}>
             <Stack height={'100%'} backgroundColor={bgColor2}>
               <Typography
                 variant="subtitle2"
                 textAlign="center"
                 alignSelf="center"
                 position="fixed">
                 12
               </Typography>
             </Stack>
           </Grid>
         </Grid>
       </Stack>
     )}
   </>
 );
}
