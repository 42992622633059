import { createMedia, createTamagui } from 'tamagui'
import { config as tamaguiConfig, tokens as tamaguiTokens, themes as tamaguiThemes } from '@tamagui/config/v3'
import { fonts } from './fonts'
import { tokens } from './tokens'
import {themes} from './themes';


const media = {
  xxs: { maxWidth: 1 - 1 },
  gtXXs: { minWidth: 1 },
 
  xs: { maxWidth: 360 - 1 },
  gtXs: { minWidth: 360 },
 
  sm: { maxWidth: 600 - 1},
  gtSm: { minWidth: 600  },
  
  md: { maxWidth: 768 - 1 },
  gtMd: { minWidth: 768 },
 
  tablet: { maxWidth: 1024 - 1 },
  gtTablet: { minWidth: 1024 },

  lg: { maxWidth: 1280 - 1 },
  gtLg: { minWidth: 1280 },
 
  xl: { maxWidth: 1536 - 1 },
  gtXl: { minWidth: 1536 },
 
  xxl: { maxWidth: 1920 - 1  },
  gtXXl: { minWidth: 1920 },
 };
 
 export type MediaType = keyof typeof media;
 

/**
 * https://blog.stackademic.com/tamagui-ui-kit-custom-fonts-in-react-native-18b3669c7d29
 */
// you usually export this from a tamagui.config.ts file
// export const config = createTamagui({
//   ...tamaguiConfig,
//   shouldAddPrefersColorThemes: true,
//   themeClassNameOnRoot: true,
//   fonts,
//   // themes: {...tamaguiThemes, ...themes},
//   // tokens: {...tamaguiTokens, ...tokens},
//   themes: {...themes},
//   tokens: {...tamaguiTokens},
//   media: createMedia(media)  
// })


export const config = createTamagui({
  shouldAddPrefersColorThemes: true,
  themeClassNameOnRoot: true,
  fonts,
  themes,
  tokens,
  media: createMedia(media)  
})




