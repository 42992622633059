import { Typography, useThemeColorTokens } from "../../elements";
import { Icon } from "..";
import { XStack, YStack } from "tamagui";
import { Briefcase, Earth, Globe, MessagesSquare, Network } from "@tamagui/lucide-icons";
import { useGetPublicPandaProfileQuery } from "../../services/api";
import { useMemo } from "react";
import { getFirstName } from "../../utils/app";
import { useParams } from "react-router-dom";

export function PandaAboutSection(){
    const {primary} = useThemeColorTokens();    
    const {user_id} = useParams();     
    const {data: pandaProfileData} = useGetPublicPandaProfileQuery({
        panda_id: user_id
    });

    const {fName, lingual, pilgrimsRegions, poojaServices} = useMemo(()=>{
        const fName = getFirstName(pandaProfileData?.name);        
        return {
            fName: fName,
            lingual: pandaProfileData?.lingual.join(','),
            pilgrimsRegions: pandaProfileData?.region.join(','),
            poojaServices: pandaProfileData?.poojaType.join(',')
        }
    }, [pandaProfileData?.name, pandaProfileData?.lingual, pandaProfileData?.region, pandaProfileData?.poojaType])


    return (
            <YStack gap={"$3"} paddingBottom="$3">
                <Typography variant="h2">About {fName}</Typography>

                <YStack gap={"$4"}>
                    <XStack gap={"$3"}>
                        <YStack gap={"$2"} flex={1}>
                            <XStack gap={"$1.5"}>
                                <Icon size={20} color={'$onSurface'} icon={<Earth />}/>                                
                                <Typography variant="body2" textTransform='capitalize'>Service Providing Region: {pandaProfileData?.address}</Typography>  
                            </XStack>
                            <XStack gap={"$1.5"}>
                                <Icon size={20} color={'$onSurface'} icon={<MessagesSquare />}/>                                                     
                                <Typography variant="body2" textTransform='capitalize'>Speaks {lingual}</Typography>                            
                            </XStack>                        
                            <XStack gap={"$1.5"}>
                                <Icon size={20} color={'$onSurface'} icon={<Globe />}/>                                                     
                                <Typography variant="body2" textTransform='capitalize'>Regions of pilgrim origin: {pilgrimsRegions}</Typography>                            
                            </XStack>
                        </YStack>
                        <YStack gap={"$2"} flex={1}>
                            <XStack gap={"$1.5"}>
                                <Icon size={20} color={'$onSurface'} icon={<Network />}/>                                                     
                                <Typography variant="body2" textTransform='capitalize'>Gotra: {pandaProfileData?.gotra}</Typography>                            
                            </XStack>
                            <XStack gap={"$1.5"}>
                                <Icon size={20} color={'$onSurface'} icon={<Briefcase />}/>
                                <Typography variant="body2" textTransform='capitalize'>Pooja services: {poojaServices}</Typography>                            
                            </XStack>
                            {/* <XStack gap={"$1.5"}>
                                <Icon size={20} color={'$success'} icon={<Check />}/>                                                     
                                <Typography variant="body2">About Kumar</Typography>                            
                            </XStack> */}
                        </YStack>
                    </XStack>                

                    <Typography variant={"body2"}>
                        Namaste! As an experienced Panda in {pandaProfileData?.address}, I provide pinddaan pooja services with devotion and professionalism, ensuring every ritual is performed authentically and with care, offering yatris a respectful and fulfilling spiritual experience.                    
                    </Typography>
                </YStack>
            </YStack>        
    )
}
