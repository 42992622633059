import { createTransform, persistReducer } from "redux-persist";
import { authReducer } from "./auth/auth-reducer";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { ayanshalaBackendApi } from "../services/api";

console.log('Initializing root-reducer...');

const ayanshalaBackendWhitelistFilter = createTransform(
  (inboundState) => {
    const persistedObject = {};
    const functionPattern = /^(fetchUsers|getAssetAccess)/;
    Object.keys(inboundState).forEach((dynamicKey) => {
      if (functionPattern.test(dynamicKey)) {
        persistedObject[dynamicKey] = inboundState[dynamicKey];
        persistedObject[dynamicKey].status = 'fulfilled';
      }
    });
    return persistedObject;
  },
  (outboundState) => {
    return { ...outboundState };
  },
  { whitelist: ['queries'] }
);

const ayanshalaBackendPersistConfig = {
  key: ayanshalaBackendApi.reducerPath,
  storage: AsyncStorage,
  transforms: [ayanshalaBackendWhitelistFilter]
};

const authSlicePersistConfig = {
  key: 'authSlice',
  storage: AsyncStorage,
  whitelist: []
};

export const rootReducer = {
  [ayanshalaBackendApi.reducerPath]: persistReducer(ayanshalaBackendPersistConfig, ayanshalaBackendApi.reducer),
  authSlice: persistReducer(authSlicePersistConfig, authReducer)
};


export const rootMiddleware = [
  ayanshalaBackendApi.middleware,
];
