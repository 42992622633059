import { Typography } from "../../elements";
import { Icon } from "..";
import { Card, XStack, YStack } from "tamagui";
import { Check, X } from "@tamagui/lucide-icons";
import { useGetPublicPandaProfileQuery } from "../../services/api";
import { useMemo } from "react";
import { getFirstName } from "../../utils/app";
import { useParams } from "react-router-dom";

export function PandaIdentitySection(){
    const {user_id} = useParams();     
    const {data: pandaProfileData} = useGetPublicPandaProfileQuery({
        panda_id: user_id
    });

    const {fName, hasAadhaar, hasProfilePic, hasFamilyLineage} = useMemo(()=>{
        const fName = getFirstName(pandaProfileData?.name);        
        return {
            fName: fName,
            hasAadhaar: !!pandaProfileData?.aadhaarImageUrl,
            hasProfilePic: !!pandaProfileData?.profileImageUrl,
            hasFamilyLineage: !!pandaProfileData?.fatherName && !!pandaProfileData?.gfatherName && !!pandaProfileData?.ggfatherName
        }
    }, [pandaProfileData?.name, pandaProfileData?.aadhaarImageUrl, pandaProfileData?.profileImageUrl, pandaProfileData?.fatherName, pandaProfileData?.gfatherName, pandaProfileData?.ggfatherName])

    return (
            <Card elevate size="$2" bordered        
            $gtTablet={{width: 342}}                 
            // width={342}
            overflow="hidden"
            paddingHorizontal={"$3"}
            paddingVertical={"$4"}
            >
                <YStack gap="$4">
                        <Typography variant="h5">{`${fName}'s confirmed information`}</Typography>

                        <YStack gap="$2">
                            <XStack gap="$3">
                                <Icon size={20} color={hasAadhaar ? '$success' : '$error'} icon={hasAadhaar ? <Check /> : <X />}/>                         
                                <Typography variant="body1">Upload aadhaar</Typography>
                            </XStack>
                            <XStack gap="$3">
                            <Icon size={20} color={hasFamilyLineage ? '$success' : '$error'} icon={hasFamilyLineage ? <Check /> : <X />}/>                         
                                <Typography variant="body1">Family roots</Typography>
                            </XStack>
                            <XStack gap="$3">
                            <Icon size={20} color={hasProfilePic ? '$success' : '$error'} icon={hasProfilePic ? <Check /> : <X />}/>                         
                                <Typography variant="body1">Upload profile photo</Typography>
                            </XStack>
                        </YStack>
                </YStack>
            </Card>
    )
}
