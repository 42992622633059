export enum UserRole {
  PANDA = "PANDA",
  CLIENT = "CLIENT"
}

// SendOtpMutationRequest type
export type SendOtpMutationRequest = {
  mobileNumber: string,
  imeiNumber: string,
  role: UserRole
};

// SendOtpMutationResponse type
export type SendOtpMutationResponse = {
  status: string,
  sessionId: string
};

// VerifyOtpMutationRequest type
export type VerifyOtpMutationRequest = {
  otp: string,
  sessionId: string,
  mobileNumber: string,
  imeiNumber: string,
  role: UserRole
};

// VerifyOtpMutationResponse type
export type VerifyOtpMutationResponse = {
  token: string,
  status: boolean
};
