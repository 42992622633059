import { HomeAppBar, Screen, Footer, CenteredLayout, BackAppBar, FormTextField, FormPhoneField, FormDateTimePicker, Checkbox, AlertDialog, Icon } from "../../components";
import { Grid, Typography, useThemeColorTokens } from "../../elements";
import { Circle, Separator, Spinner, Square, Stack, XStack, YStack, Button, useMedia } from "tamagui";
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { AsYouType } from 'libphonenumber-js'
import { CircleCheckBig } from "@tamagui/lucide-icons";
import { DeviceEventEmitter } from "react-native";
import { usePublicBookingRequestMutation } from "../../services/api";
import { Link, useParams } from "react-router-dom";

enum YatriBookingEvent  {
    CONFIRM = 'Confirm'
}

interface BookingStepsProps {
    onNext?: (fields: any) => Promise<void>;//() => void;
    onPrev?: () => void;
    onSubmit?: (data: any) => Promise<void>;  
    isLoading?: boolean;      
}

const schema = yup.object().shape({
    yatri: yup.object().shape({
      name: yup.string().required('Name is required'),
      phone: yup
        .string()
        .required('Phone number is required')
        .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits'),
      address: yup.object().shape({
        city: yup.string().required('City is required'),
        district: yup.string().required('District is required'),
        state: yup.string().required('State is required'),
        policeStation: yup.string().required('Police station is required'),
        pincode: yup
          .string()
          .required('Pincode is required')
          .matches(/^[0-9]{6}$/, 'Pincode must be 6 digits'),
      }),
      ancestorAddress: yup.object().shape({
        city: yup.string().required('City is required'),
        district: yup.string().required('District is required'),
        state: yup.string().required('State is required'),
        policeStation: yup.string().required('Police station is required'),
        pincode: yup
          .string()
          .required('Pincode is required')
          .matches(/^[0-9]{6}$/, 'Pincode must be 6 digits'),
      }),      
    }),
    attributor: yup.object().shape({
      name: yup.string(),
      phone: yup
        .string(),
        // .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits'),
      comments: yup.string(),
    }),
    numberOfYatri: yup
      .number()
      .required('Number of Yatri is required')
      .min(1, 'At least 1 Yatri is required'),
    numberOfPoojaDays: yup
      .number()
      .required('Number of Pooja Days is required')
      .min(1, 'At least 1 Pooja Day is required'),
    // arrivalDate: yup
    //   .string()
    //   .required('Arrival date is required'),       
  });

function useYatriBookingSteps(){
    // const navigation = useAppNavigation();
    const defaultValues = {
        yatri: {
            name:"",
            phone: "",
            address: {
                city: "",
                district: "",
                state: "",
                policeStation: "",
                pincode: "",                
            },
            ancestorAddress: {
                city: "",
                district: "",
                state: "",
                policeStation: "",
                pincode: "",                
            }                
        },
        attributor: {
            name:"",
            phone: "",
            comments: ""
        },
        numberOfYatri: 1,
        numberOfPoojaDays: 1,
        arrivalDate: ""
    };

    const methods = useForm({
        defaultValues,
        resolver: yupResolver(schema),        
    });
    const {user_id} = useParams();    
    // const [publicBookingRequestMutation, {isLoading}] = usePublicBookingRequestMutation();    
    // const [yatriBooking, {isLoading: isYatriBookingLoading }] = useYatriBookingMutation();

    let orderedStepsLabels = [
        {key: 'Booking Info', component: <BookingInfo onNext={onNext} />},
        {key: 'Yatri Address', component: <YatriAddress onNext={onNext} onPrev={onPrev} />},
        {key: 'Yatri Referrer', component: <YatriReferrer onSubmit={onSubmit} onPrev={onPrev} isLoading={false}/>}
      ];


    const [lastCompletedStepIndex, setLastCompletedStepIndex] = useState<number>(0);

    // handler
    async function onSubmit(data) {
        // console.log("onSubmit - data :: api call", data);
        DeviceEventEmitter.emit(YatriBookingEvent.CONFIRM);
        // // Handle form submission

        // publicBookingRequestMutation({
        //     mobileNumber: data.yatri.phone,
        //     bookingRequest: {
        //         fullName: data.yatri.name,
        //         residentialAddress: data.yatri.address,
        //         ancestralAddress: data.yatri.ancestorAddress,
        //         bookingDate: "2025-03-20T10:45:39.814Z"//dayjs(data.arrivalDate).format('YYYY-MM-DD')
        //     },
        //     extraBookingInfo: {
        //         attributorName: data.attributor.name,
        //         attributorMobileNumber: data.attributor.phone,
        //         comments: data.attributor.comments,
        //         numberOfYatri: Number(data.numberOfYatri),
        //         numberOfPoojaDays: Number(data.numberOfPoojaDays)
        //     },
        //     "pandaId": user_id
        //   })


        /** Remove below code */          
        // const resp = await yatriBooking({
        //   mobileNumber: data.yatri.phone,
        //   bookingRequest: {
        //       fullName: data.yatri.name,
        //       residentialAddress: data.yatri.address,
        //       ancestralAddress: data.yatri.ancestorAddress,
        //       bookingDate: dayjs(data.arrivalDate).format('YYYY-MM-DD')
        //   },
        //   extraBookingInfo: {
        //       attributorName: data.attributor.name,
        //       attributorMobileNumber: data.attributor.phone,
        //       comments: data.attributor.comments,
        //       numberOfYatri: Number(data.numberOfYatri),
        //       numberOfPoojaDays: Number(data.numberOfPoojaDays)
        //   }
        // }).unwrap().catch((error)=> console.log("Booking failed"));
        // console.log("resp.error ::", resp);
        // if(!!resp.bookingId){
        //   console.log("Booking successful");
        // //   navigation.replace("BookingDetailScreen", {bookingId: resp.bookingId});        
        //   methods.reset();
        // }
    };

    async function onNext(fields){        
        const isStepValid = await methods.trigger(fields);
        if (isStepValid) {
            setLastCompletedStepIndex(lastCompletedStepIndex + 1);
        }
    }

    function onPrev(){
        setLastCompletedStepIndex(lastCompletedStepIndex - 1);        
    }

    return {
        orderedStepsLabels,
        lastCompletedStepIndex,
        methods,
        defaultValues
    }
}

function BookingInfo({ onNext }: BookingStepsProps){
    const { register, control, formState: { errors } } = useFormContext();
    const { primary, inverseOnSurface } = useThemeColorTokens();

    return (
        <YStack gap="$2">
            <Typography variant="overline">Booking Info</Typography>                
            {/* <YStack>
                <FormDateTimePicker 
                    size="$5" 
                    {...register('arrivalDate')}
                    control={control}
                    // placeholder="Number of people"
                    helperText={errors?.arrivalDate?.message || "Arrival date of yatri."}
                    error={!!errors?.arrivalDate}                                        
                />
            </YStack> */}

            {/* <XStack>
                <DatePickerExample />
            </XStack> */}

            <FormTextField 
                keyboardType='numeric'                
                size="$5" 
                {...register('numberOfYatri')}
                control={control}
                // defaultValue={"1"}
                placeholder="Number of people"
                helperText={errors?.numberOfYatri?.message || "Number of people coming along with you."}
                error={!!errors?.numberOfYatri}                    
            />

            <FormTextField 
                keyboardType='numeric'
                size="$5" 
                {...register('numberOfPoojaDays')}
                control={control}
                placeholder="Number of pooja days"
                helperText={errors?.numberOfPoojaDays?.message || "Number of pooja days."}
                error={!!errors?.numberOfPoojaDays}
            />

            <Separator height={1} borderColor={"$outlineOpacity16P"}/>

            <YStack gap="$2" paddingBottom="$4">
                <Typography variant="overline">Yatri Info</Typography>                
                <FormTextField
                    size="$5" 
                    {...register('yatri.name')}
                    control={control}
                    placeholder="Full Name"
                    helperText={errors?.yatri?.name?.message || "Enter full name to be used in all communications."}
                    error={!!errors?.yatri?.name}
                />

                <FormPhoneField 
                    size="$5" 
                    {...register('yatri.phone', {
                        onChange: (e) => {
                            const asYouType = new AsYouType();
                            asYouType.input(e.target.value);
                        }
                    })}
                    control={control}
                    placeholder="Phone Number"
                    helperText={errors?.yatri?.phone?.message || "Enter a valid phone number."}
                    error={!!errors?.yatri?.phone}                    
                />
            </YStack>

            <XStack justifyContent="flex-end">
                <Button padding={20} backgroundColor={primary} color={'white'} children={'Next'} 
                    onPress={() => onNext && onNext([
                        'arrivalDate', 
                        'numberOfYatri', 
                        'numberOfPoojaDays', 
                        'yatri.name', 
                        'yatri.phone'])} />
            </XStack>
        </YStack>
    )
}

function YatriAddress({ onNext, onPrev }: BookingStepsProps){
    const { primary, inverseOnSurface, outlineOpacity16P } = useThemeColorTokens();    
    const { register, control, formState: { errors }, setValue, watch } = useFormContext();
    const {defaultValues} = useYatriBookingSteps();    
    const [isSameAddress, toggleIsSameAddress] = useState(false);

    const address = watch("yatri.address"); // Watch for address changes

    // Sync ancestorAddress with address when isSameAddress is true
    useEffect(() => {
      if (isSameAddress) {
        setValue("yatri.ancestorAddress", address);
      } else {
        setValue("yatri.ancestorAddress", defaultValues.yatri.ancestorAddress); // Reset ancestorAddress        
      }
    }, [isSameAddress, address, setValue]);

    function handleCheckedChange(bool){
        toggleIsSameAddress(bool);
    }

    return (
        <YStack gap="$2">            
            <YStack gap="$2">                    
                <Typography variant="overline">Yatri Address</Typography>                
                <FormTextField 
                    size="$5" 
                    {...register('yatri.address.city')}
                    control={control}
                    placeholder="City" 
                    helperText={errors?.yatri?.address?.city?.message || "Enter city of yatri coming from."}
                    error={!!errors?.yatri?.address?.city}                    
                />

                <FormTextField 
                    size="$5" 
                    {...register('yatri.address.district')}
                    control={control}
                    placeholder="District" 
                    helperText={errors?.yatri?.address?.district?.message || "Enter district of yatri coming from."}
                    error={!!errors?.yatri?.address?.district}
                />

                <FormTextField 
                    size="$5" 
                    {...register('yatri.address.state')}
                    control={control}
                    placeholder="State" 
                    helperText={errors?.yatri?.address?.state?.message || "Enter state of yatri coming from."}
                    error={!!errors?.yatri?.address?.state}                    
                />

                <FormTextField 
                    size="$5" 
                    {...register('yatri.address.policeStation')}
                    control={control}
                    placeholder="P.S" 
                    helperText={errors?.yatri?.address?.policeStation?.message || "Enter policeStation of yatri coming from."}
                    error={!!errors?.yatri?.address?.policeStation}                                        
                />

                <FormTextField 
                    keyboardType='numeric'                
                    size="$5" 
                    {...register('yatri.address.pincode')}
                    control={control}
                    placeholder="Pincode" 
                    helperText={errors?.yatri?.address?.pincode?.message || "Enter pincode of yatri coming from."}
                    error={!!errors?.yatri?.address?.pincode}                                                            
                />
            </YStack>

            <Separator height={1} borderColor={outlineOpacity16P}/>

            <YStack gap="$2">
                <Typography variant="overline">Yatri Ancestor Address</Typography>   
                <Checkbox size="$5" label="Same as address" onCheckedChange={handleCheckedChange} />                             
                {!isSameAddress &&
                    <>
                        <FormTextField 
                            size="$5" 
                            {...register('yatri.ancestorAddress.city')}
                            control={control}
                            placeholder="City" 
                            helperText={errors?.yatri?.ancestorAddress?.city?.message || "Enter yatri's ancestral city."}
                            error={!!errors?.yatri?.ancestorAddress?.city}                    
                        />

                        <FormTextField 
                            size="$5" 
                            {...register('yatri.ancestorAddress.district')}
                            control={control}
                            placeholder="District" 
                            helperText={errors?.yatri?.ancestorAddress?.district?.message || "Enter yatri's ancestral district."}
                            error={!!errors?.yatri?.ancestorAddress?.district}
                        />

                        <FormTextField 
                            size="$5" 
                            {...register('yatri.ancestorAddress.state')}
                            control={control}
                            placeholder="State" 
                            helperText={errors?.yatri?.ancestorAddress?.state?.message || "Enter yatri's ancestral state."}
                            error={!!errors?.yatri?.ancestorAddress?.state}                    
                        />

                        <FormTextField 
                            size="$5" 
                            {...register('yatri.ancestorAddress.policeStation')}
                            control={control}
                            placeholder="P.S" 
                            helperText={errors?.yatri?.ancestorAddress?.policeStation?.message || "Enter yatri's ancestral policeStation."}
                            error={!!errors?.yatri?.ancestorAddress?.policeStation}                                        
                        />

                        <FormTextField 
                            keyboardType='numeric'                
                            size="$5" 
                            {...register('yatri.ancestorAddress.pincode')}
                            control={control}
                            placeholder="Pincode" 
                            helperText={errors?.yatri?.ancestorAddress?.pincode?.message || "Enter yatri's ancestral pincode."}
                            error={!!errors?.yatri?.ancestorAddress?.pincode}                                                            
                        />
                    </>
                }
            </YStack>


            <XStack justifyContent="space-between" alignItems="center" paddingVertical="$4">
                <Button padding={20} backgroundColor={primary} color={'white'} children={'Prev'} onPress={onPrev}/>                
                <Button padding={20} backgroundColor={primary} color={'white'} children={'Next'} 
                    onPress={() => onNext && onNext([
                        'yatri.address.city', 
                        'yatri.address.district', 
                        'yatri.address.state', 
                        'yatri.address.policeStation', 
                        'yatri.address.pincode', 
                        'yatri.ancestorAddress.city', 
                        'yatri.ancestorAddress.district', 
                        'yatri.ancestorAddress.state', 
                        'yatri.ancestorAddress.policeStation', 
                        'yatri.ancestorAddress.pincode'])} />
            </XStack>            

        </YStack>


    )
}

function YatriReferrer({ onPrev, onSubmit, isLoading }: BookingStepsProps){
    const { register, handleSubmit, control, formState: { errors } } = useFormContext();
    const { primary, inverseOnSurface } = useThemeColorTokens();

    return (
        <YStack gap="$2">
            <Typography variant="overline">Yatri Referrer (Optional)</Typography>                
            <FormTextField
                size="$5" 
                {...register('attributor.name')}
                control={control}
                placeholder="Referrer Full Name"
                helperText={errors?.attributor?.name?.message || "Enter full name of referrer."}
                error={!!errors?.attributor?.name}
            />
                
            <FormPhoneField
                size="$5" 
                {...register('attributor.phone', {
                    onChange: (e) => {
                        const asYouType = new AsYouType();
                        asYouType.input(e.target.value);
                    }
                })}
                control={control}
                placeholder="Referrer Phone Number"
                helperText={errors?.attributor?.phone?.message || "Enter a valid phone number of referrer."}
                error={!!errors?.attributor?.phone}
            />

            <FormTextField 
                size="$5" 
                {...register('attributor.comments')}
                control={control}
                placeholder="Comments"
                helperText={errors?.attributor?.comments?.message || "Enter short comments."}
                error={!!errors?.attributor?.comments}                    
            />

            <XStack justifyContent="space-between" alignItems="center">
                <Button padding={20} backgroundColor={primary} color={'white'} children={'Prev'} onPress={onPrev}/>                
                <Button padding={20} backgroundColor={primary} color={'white'}
                    onPress={isLoading ? undefined : onSubmit && handleSubmit(onSubmit)}>
                    <Typography variant="body2" color={inverseOnSurface}>Submit</Typography>
                    {isLoading &&
                        <Spinner size="small" color={inverseOnSurface} />
                    }                        
                </Button>
            </XStack>
        </YStack>
    )
}

function BookingConfirmDialog(){
    const { orderedStepsLabels, lastCompletedStepIndex, methods } = useYatriBookingSteps();
    const {success: successClr, primary} = useThemeColorTokens()
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(()=>{
        DeviceEventEmitter.addListener(YatriBookingEvent.CONFIRM, () => setIsOpen(true))
    }, [])

    return (
        <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
            <YStack gap={"$4"} padding="$3" alignItems="center" minWidth={328}>
                <Icon size={64} color={successClr} icon={<CircleCheckBig />}/>       
                <YStack gap={"$1"} alignItems="center">
                    <Typography variant="subtitle1">Booking Received!</Typography>
                    <Typography variant="body1">Please login to see booking details.</Typography>                                    
                </YStack>
                <Link to={`/auth/phone`}>
                    <Button padding={14} backgroundColor={primary} color={'white'} children={'Login'} />
                </Link>                                              
            </YStack>
        </AlertDialog>
    )
}

function YatriOnboarding(){
    const { orderedStepsLabels, lastCompletedStepIndex, methods } = useYatriBookingSteps();
    const { primary, surfaceContainerHighest, surfaceContainerLowest, onSurface } = useThemeColorTokens();

    return (
        <Stack gap="$3">
            <Stack flexDirection="row" justifyContent="center">    
                {orderedStepsLabels.map((stepLabel, index) => {
                    const isLastStep = index === orderedStepsLabels.length - 1;
                    const isActiveIndex = index <= lastCompletedStepIndex ;
                    return (
                        <YStack gap="$2" paddingVertical="$2">                            
                            <XStack alignItems="center" justifyContent={!isLastStep ? "center" : 'flex-start'}>
                                <Circle size={32} backgroundColor={isActiveIndex ? primary : surfaceContainerHighest}>
                                    <Typography variant="body2" color={isActiveIndex ? surfaceContainerLowest : onSurface}>{index+1}</Typography>
                                </Circle>
                                {/* Step Connector (Render only if not the last step) */}
                                {!isLastStep && (
                                    <Square width={60} height={2} backgroundColor={isActiveIndex ? primary : surfaceContainerHighest} marginHorizontal="$2" />
                                )}
                            </XStack>                                
                            <Typography variant='caption'>{stepLabel.key}</Typography>
                        </YStack>
                    )
                    })
                }
            </Stack>
            <FormProvider {...methods}>
                {orderedStepsLabels[lastCompletedStepIndex].component}                
            </FormProvider>
            <BookingConfirmDialog />
        </Stack>
    )
}

export function YatriBookingScreen(){
    const media = useMedia();

    return (
        <Screen
            appBar={<HomeAppBar />}
            context={{ name: 'UsersScreen' }}
            preset="fixed"
            footer={<Footer />}
        >
            <CenteredLayout marginTop={"$10"} marginBottom={"$3"}>
                <BackAppBar title="Yatri Booking" separator={false} zIndex={0}/>
                <Grid xs={12} container row>
                    <Grid xs={12} alignItems='center'>
                        <Stack width={'100%'} padding={"$2"} maxWidth={media.gtSm ? 461 : 'auto'} gap="$2">
                            <YatriOnboarding />
                        </Stack>
                    </Grid>
                </Grid>
            </CenteredLayout>
        </Screen>
    );
}