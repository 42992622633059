//@ts-nocheck
import { useEffect, useRef } from 'react';
import { useLayoutEffect } from 'react';


export function useLockBodyScroll(locked) {
 const originalStyle = useRef();


 useEffect(() => {

     if (locked) {
       // Prevent scrolling on mount
       document.body.style.overflow = 'hidden';
     } else {
       document.body.style.overflow = '';
     }

 }, [locked]);


 useLayoutEffect(() => {

     // Get original body overflow
     originalStyle.current = window.getComputedStyle(document.body).overflow;
     // Re-enable scrolling when component unmounts
     return () => {
       document.body.style.overflow = '';//originalStyle.current;
     };

 }, []);
}





