import { useCallback, useEffect, useState } from "react";
import { getCookie } from "../../libs/cookies";
import { getAccessTokenCookie, PandaSevaCookies } from "../../services/api";
import { authActions, useAppDispatch } from "../../store";

export function useAppInitialization(){
    /**
     * add app initialiation business logic here
     */
    const dispatch = useAppDispatch();
    const saveAccessToken = useCallback((data) => dispatch(authActions.saveAccessToken(data)), [])    
    const saveSessionId = useCallback((data) => dispatch(authActions.saveSessionId(data)), [])        
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    useEffect(()=>{
        async function init(){
            initializeAccessToken();
        }
        init();
    }, [])

    async function initializeAccessToken(){
        const accessToken = await getAccessTokenCookie();
        const sessionId = await getCookie({ name: PandaSevaCookies.SESSION_ID });
        console.log("accessToken ::", accessToken);
        console.log("sessionId ::", sessionId);

        if(!!accessToken){
            saveAccessToken(accessToken);
        }
        if(!!sessionId){
            saveSessionId(sessionId);
        }
        setIsInitialized(true);
    }

    return {
        isInitialized: isInitialized,
        isError: false
    };
}