export const light_deeporange = {
primary: '#b02f00',
onPrimary: '#ffffff',
primaryContainer: '#ffdbd1',
onPrimaryContainer: '#3b0900',
primaryFixed: '#ffdbd1',
onPrimaryFixed: '#3b0900',
primaryFixedDim: '#ffb5a0',
onPrimaryFixedVariant: '#862200',
secondary: '#77574e',
onSecondary: '#ffffff',
secondaryContainer: '#ffdbd1',
onSecondaryContainer: '#2c150f',
secondaryFixed: '#ffdbd1',
onSecondaryFixed: '#2c150f',
secondaryFixedDim: '#e7bdb2',
onSecondaryFixedVariant: '#5d4037',
tertiary: '#6c5d2f',
onTertiary: '#ffffff',
tertiaryContainer: '#f5e1a7',
onTertiaryContainer: '#231b00',
tertiaryFixed: '#f5e1a7',
onTertiaryFixed: '#231b00',
tertiaryFixedDim: '#d8c58d',
onTertiaryFixedVariant: '#534619',
error: '#ba1a1a',
onError: '#ffffff',
errorContainer: '#ffdad6',
onErrorContainer: '#410002',
outline: '#85736e',
background2: '#f2f4f7',
background: '#fffbff',
onBackground: '#201a18',
surface: '#fff8f6',
onSurface: '#201a18',
surfaceVariant: '#f5ded8',
onSurfaceVariant: '#53433f',
inverseSurface: '#362f2d',
inverseOnSurface: '#fbeeeb',
inversePrimary: '#ffb5a0',
shadow: '#000000',
surfaceTint: '#b02f00',
outlineVariant: '#d8c2bc',
scrim: '#000000',
surfaceContainerHighest: '#ede0dc',
surfaceContainerHigh: '#f3e5e2',
surfaceContainer: '#f8ebe8',
surfaceContainerLow: '#fef1ed',
surfaceContainerLowest: '#ffffff',
surfaceBright: '#fff8f6',
surfaceDim: '#e4d7d4',
primaryOpacity8P: '#b02f0014',
primaryOpacity12P: '#b02f001f',
primaryOpacity16P: '#b02f0029',
onPrimaryOpacity8P: '#ffffff14',
onPrimaryOpacity12P: '#ffffff1f',
onPrimaryOpacity16P: '#ffffff29',
primaryContainerOpacity8P: '#ffdbd114',
primaryContainerOpacity12P: '#ffdbd11f',
primaryContainerOpacity16P: '#ffdbd129',
onPrimaryContainerOpacity8P: '#3b090014',
onPrimaryContainerOpacity12P: '#3b09001f',
onPrimaryContainerOpacity16P: '#3b090029',
primaryFixedOpacity8P: '#ffdbd114',
primaryFixedOpacity12P: '#ffdbd11f',
primaryFixedOpacity16P: '#ffdbd129',
onPrimaryFixedOpacity8P: '#3b090014',
onPrimaryFixedOpacity12P: '#3b09001f',
onPrimaryFixedOpacity16P: '#3b090029',
primaryFixedDimOpacity8P: '#ffb5a014',
primaryFixedDimOpacity12P: '#ffb5a01f',
primaryFixedDimOpacity16P: '#ffb5a029',
onPrimaryFixedVariantOpacity8P: '#86220014',
onPrimaryFixedVariantOpacity12P: '#8622001f',
onPrimaryFixedVariantOpacity16P: '#86220029',
secondaryOpacity8P: '#77574e14',
secondaryOpacity12P: '#77574e1f',
secondaryOpacity16P: '#77574e29',
onSecondaryOpacity8P: '#ffffff14',
onSecondaryOpacity12P: '#ffffff1f',
onSecondaryOpacity16P: '#ffffff29',
secondaryContainerOpacity8P: '#ffdbd114',
secondaryContainerOpacity12P: '#ffdbd11f',
secondaryContainerOpacity16P: '#ffdbd129',
onSecondaryContainerOpacity8P: '#2c150f14',
onSecondaryContainerOpacity12P: '#2c150f1f',
onSecondaryContainerOpacity16P: '#2c150f29',
secondaryFixedOpacity8P: '#ffdbd114',
secondaryFixedOpacity12P: '#ffdbd11f',
secondaryFixedOpacity16P: '#ffdbd129',
onSecondaryFixedOpacity8P: '#2c150f14',
onSecondaryFixedOpacity12P: '#2c150f1f',
onSecondaryFixedOpacity16P: '#2c150f29',
secondaryFixedDimOpacity8P: '#e7bdb214',
secondaryFixedDimOpacity12P: '#e7bdb21f',
secondaryFixedDimOpacity16P: '#e7bdb229',
onSecondaryFixedVariantOpacity8P: '#5d403714',
onSecondaryFixedVariantOpacity12P: '#5d40371f',
onSecondaryFixedVariantOpacity16P: '#5d403729',
tertiaryOpacity8P: '#6c5d2f14',
tertiaryOpacity12P: '#6c5d2f1f',
tertiaryOpacity16P: '#6c5d2f29',
onTertiaryOpacity8P: '#ffffff14',
onTertiaryOpacity12P: '#ffffff1f',
onTertiaryOpacity16P: '#ffffff29',
tertiaryContainerOpacity8P: '#f5e1a714',
tertiaryContainerOpacity12P: '#f5e1a71f',
tertiaryContainerOpacity16P: '#f5e1a729',
onTertiaryContainerOpacity8P: '#231b0014',
onTertiaryContainerOpacity12P: '#231b001f',
onTertiaryContainerOpacity16P: '#231b0029',
tertiaryFixedOpacity8P: '#f5e1a714',
tertiaryFixedOpacity12P: '#f5e1a71f',
tertiaryFixedOpacity16P: '#f5e1a729',
onTertiaryFixedOpacity8P: '#231b0014',
onTertiaryFixedOpacity12P: '#231b001f',
onTertiaryFixedOpacity16P: '#231b0029',
tertiaryFixedDimOpacity8P: '#d8c58d14',
tertiaryFixedDimOpacity12P: '#d8c58d1f',
tertiaryFixedDimOpacity16P: '#d8c58d29',
onTertiaryFixedVariantOpacity8P: '#53461914',
onTertiaryFixedVariantOpacity12P: '#5346191f',
onTertiaryFixedVariantOpacity16P: '#53461929',
errorOpacity8P: '#ba1a1a14',
errorOpacity12P: '#ba1a1a1f',
errorOpacity16P: '#ba1a1a29',
onErrorOpacity8P: '#ffffff14',
onErrorOpacity12P: '#ffffff1f',
onErrorOpacity16P: '#ffffff29',
errorContainerOpacity8P: '#ffdad614',
errorContainerOpacity12P: '#ffdad61f',
errorContainerOpacity16P: '#ffdad629',
onErrorContainerOpacity8P: '#41000214',
onErrorContainerOpacity12P: '#4100021f',
onErrorContainerOpacity16P: '#41000229',
outlineOpacity8P: '#85736e14',
outlineOpacity12P: '#85736e1f',
outlineOpacity16P: '#85736e29',
backgroundOpacity8P: '#fffbff14',
backgroundOpacity12P: '#fffbff1f',
backgroundOpacity16P: '#fffbff29',
onBackgroundOpacity8P: '#201a1814',
onBackgroundOpacity12P: '#201a181f',
onBackgroundOpacity16P: '#201a1829',
surfaceOpacity8P: '#fff8f614',
surfaceOpacity12P: '#fff8f61f',
surfaceOpacity16P: '#fff8f629',
onSurfaceOpacity8P: '#201a1814',
onSurfaceOpacity12P: '#201a181f',
onSurfaceOpacity16P: '#201a1829',
surfaceVariantOpacity8P: '#f5ded814',
surfaceVariantOpacity12P: '#f5ded81f',
surfaceVariantOpacity16P: '#f5ded829',
onSurfaceVariantOpacity8P: '#53433f14',
onSurfaceVariantOpacity12P: '#53433f1f',
onSurfaceVariantOpacity16P: '#53433f29',
inverseSurfaceOpacity8P: '#362f2d14',
inverseSurfaceOpacity12P: '#362f2d1f',
inverseSurfaceOpacity16P: '#362f2d29',
inverseOnSurfaceOpacity8P: '#fbeeeb14',
inverseOnSurfaceOpacity12P: '#fbeeeb1f',
inverseOnSurfaceOpacity16P: '#fbeeeb29',
inversePrimaryOpacity8P: '#ffb5a014',
inversePrimaryOpacity12P: '#ffb5a01f',
inversePrimaryOpacity16P: '#ffb5a029',
shadowOpacity8P: '#00000014',
shadowOpacity12P: '#0000001f',
shadowOpacity16P: '#00000029',
surfaceTintOpacity8P: '#b02f0014',
surfaceTintOpacity12P: '#b02f001f',
surfaceTintOpacity16P: '#b02f0029',
outlineVariantOpacity8P: '#d8c2bc14',
outlineVariantOpacity12P: '#d8c2bc1f',
outlineVariantOpacity16P: '#d8c2bc29',
scrimOpacity8P: '#00000014',
scrimOpacity12P: '#0000001f',
scrimOpacity16P: '#00000029',
surfaceContainerHighestOpacity8P: '#ede0dc14',
surfaceContainerHighestOpacity12P: '#ede0dc1f',
surfaceContainerHighestOpacity16P: '#ede0dc29',
surfaceContainerHighOpacity8P: '#f3e5e214',
surfaceContainerHighOpacity12P: '#f3e5e21f',
surfaceContainerHighOpacity16P: '#f3e5e229',
surfaceContainerOpacity8P: '#f8ebe814',
surfaceContainerOpacity12P: '#f8ebe81f',
surfaceContainerOpacity16P: '#f8ebe829',
surfaceContainerLowOpacity8P: '#fef1ed14',
surfaceContainerLowOpacity12P: '#fef1ed1f',
surfaceContainerLowOpacity16P: '#fef1ed29',
surfaceContainerLowestOpacity8P: '#ffffff14',
surfaceContainerLowestOpacity12P: '#ffffff1f',
surfaceContainerLowestOpacity16P: '#ffffff29',
surfaceBrightOpacity8P: '#fff8f614',
surfaceBrightOpacity12P: '#fff8f61f',
surfaceBrightOpacity16P: '#fff8f629',
surfaceDimOpacity8P: '#e4d7d414',
surfaceDimOpacity12P: '#e4d7d41f',
surfaceDimOpacity16P: '#e4d7d429'
};


export const dark_deeporange = {
primary: '#ffb5a0',
onPrimary: '#5f1500',
primaryContainer: '#862200',
onPrimaryContainer: '#ffdbd1',
primaryFixed: '#ffdbd1',
onPrimaryFixed: '#3b0900',
primaryFixedDim: '#ffb5a0',
onPrimaryFixedVariant: '#862200',
secondary: '#e7bdb2',
onSecondary: '#442a22',
secondaryContainer: '#5d4037',
onSecondaryContainer: '#ffdbd1',
secondaryFixed: '#ffdbd1',
onSecondaryFixed: '#2c150f',
secondaryFixedDim: '#e7bdb2',
onSecondaryFixedVariant: '#5d4037',
tertiary: '#d8c58d',
onTertiary: '#3b2f05',
tertiaryContainer: '#534619',
onTertiaryContainer: '#f5e1a7',
tertiaryFixed: '#f5e1a7',
onTertiaryFixed: '#231b00',
tertiaryFixedDim: '#d8c58d',
onTertiaryFixedVariant: '#534619',
error: '#ffb4ab',
onError: '#690005',
errorContainer: '#93000a',
onErrorContainer: '#ffdad6',
outline: '#a08c87',
background2: '#201a18',
background: '#201a18',
onBackground: '#ede0dc',
surface: '#181210',
onSurface: '#d0c4c1',
surfaceVariant: '#53433f',
onSurfaceVariant: '#d8c2bc',
inverseSurface: '#ede0dc',
inverseOnSurface: '#201a18',
inversePrimary: '#b02f00',
shadow: '#000000',
surfaceTint: '#ffb5a0',
outlineVariant: '#53433f',
scrim: '#000000',
surfaceContainerHighest: '#3b3331',
surfaceContainerHigh: '#2f2827',
surfaceContainer: '#251e1c',
surfaceContainerLow: '#201a18',
surfaceContainerLowest: '#120d0b',
surfaceBright: '#3f3735',
surfaceDim: '#181210',
primaryOpacity8P: '#ffb5a014',
primaryOpacity12P: '#ffb5a01f',
primaryOpacity16P: '#ffb5a029',
onPrimaryOpacity8P: '#5f150014',
onPrimaryOpacity12P: '#5f15001f',
onPrimaryOpacity16P: '#5f150029',
primaryContainerOpacity8P: '#86220014',
primaryContainerOpacity12P: '#8622001f',
primaryContainerOpacity16P: '#86220029',
onPrimaryContainerOpacity8P: '#ffdbd114',
onPrimaryContainerOpacity12P: '#ffdbd11f',
onPrimaryContainerOpacity16P: '#ffdbd129',
primaryFixedOpacity8P: '#ffdbd114',
primaryFixedOpacity12P: '#ffdbd11f',
primaryFixedOpacity16P: '#ffdbd129',
onPrimaryFixedOpacity8P: '#3b090014',
onPrimaryFixedOpacity12P: '#3b09001f',
onPrimaryFixedOpacity16P: '#3b090029',
primaryFixedDimOpacity8P: '#ffb5a014',
primaryFixedDimOpacity12P: '#ffb5a01f',
primaryFixedDimOpacity16P: '#ffb5a029',
onPrimaryFixedVariantOpacity8P: '#86220014',
onPrimaryFixedVariantOpacity12P: '#8622001f',
onPrimaryFixedVariantOpacity16P: '#86220029',
secondaryOpacity8P: '#e7bdb214',
secondaryOpacity12P: '#e7bdb21f',
secondaryOpacity16P: '#e7bdb229',
onSecondaryOpacity8P: '#442a2214',
onSecondaryOpacity12P: '#442a221f',
onSecondaryOpacity16P: '#442a2229',
secondaryContainerOpacity8P: '#5d403714',
secondaryContainerOpacity12P: '#5d40371f',
secondaryContainerOpacity16P: '#5d403729',
onSecondaryContainerOpacity8P: '#ffdbd114',
onSecondaryContainerOpacity12P: '#ffdbd11f',
onSecondaryContainerOpacity16P: '#ffdbd129',
secondaryFixedOpacity8P: '#ffdbd114',
secondaryFixedOpacity12P: '#ffdbd11f',
secondaryFixedOpacity16P: '#ffdbd129',
onSecondaryFixedOpacity8P: '#2c150f14',
onSecondaryFixedOpacity12P: '#2c150f1f',
onSecondaryFixedOpacity16P: '#2c150f29',
secondaryFixedDimOpacity8P: '#e7bdb214',
secondaryFixedDimOpacity12P: '#e7bdb21f',
secondaryFixedDimOpacity16P: '#e7bdb229',
onSecondaryFixedVariantOpacity8P: '#5d403714',
onSecondaryFixedVariantOpacity12P: '#5d40371f',
onSecondaryFixedVariantOpacity16P: '#5d403729',
tertiaryOpacity8P: '#d8c58d14',
tertiaryOpacity12P: '#d8c58d1f',
tertiaryOpacity16P: '#d8c58d29',
onTertiaryOpacity8P: '#3b2f0514',
onTertiaryOpacity12P: '#3b2f051f',
onTertiaryOpacity16P: '#3b2f0529',
tertiaryContainerOpacity8P: '#53461914',
tertiaryContainerOpacity12P: '#5346191f',
tertiaryContainerOpacity16P: '#53461929',
onTertiaryContainerOpacity8P: '#f5e1a714',
onTertiaryContainerOpacity12P: '#f5e1a71f',
onTertiaryContainerOpacity16P: '#f5e1a729',
tertiaryFixedOpacity8P: '#f5e1a714',
tertiaryFixedOpacity12P: '#f5e1a71f',
tertiaryFixedOpacity16P: '#f5e1a729',
onTertiaryFixedOpacity8P: '#231b0014',
onTertiaryFixedOpacity12P: '#231b001f',
onTertiaryFixedOpacity16P: '#231b0029',
tertiaryFixedDimOpacity8P: '#d8c58d14',
tertiaryFixedDimOpacity12P: '#d8c58d1f',
tertiaryFixedDimOpacity16P: '#d8c58d29',
onTertiaryFixedVariantOpacity8P: '#53461914',
onTertiaryFixedVariantOpacity12P: '#5346191f',
onTertiaryFixedVariantOpacity16P: '#53461929',
errorOpacity8P: '#ffb4ab14',
errorOpacity12P: '#ffb4ab1f',
errorOpacity16P: '#ffb4ab29',
onErrorOpacity8P: '#69000514',
onErrorOpacity12P: '#6900051f',
onErrorOpacity16P: '#69000529',
errorContainerOpacity8P: '#93000a14',
errorContainerOpacity12P: '#93000a1f',
errorContainerOpacity16P: '#93000a29',
onErrorContainerOpacity8P: '#ffdad614',
onErrorContainerOpacity12P: '#ffdad61f',
onErrorContainerOpacity16P: '#ffdad629',
outlineOpacity8P: '#a08c8714',
outlineOpacity12P: '#a08c871f',
outlineOpacity16P: '#a08c8729',
backgroundOpacity8P: '#201a1814',
backgroundOpacity12P: '#201a181f',
backgroundOpacity16P: '#201a1829',
onBackgroundOpacity8P: '#ede0dc14',
onBackgroundOpacity12P: '#ede0dc1f',
onBackgroundOpacity16P: '#ede0dc29',
surfaceOpacity8P: '#18121014',
surfaceOpacity12P: '#1812101f',
surfaceOpacity16P: '#18121029',
onSurfaceOpacity8P: '#d0c4c114',
onSurfaceOpacity12P: '#d0c4c11f',
onSurfaceOpacity16P: '#d0c4c129',
surfaceVariantOpacity8P: '#53433f14',
surfaceVariantOpacity12P: '#53433f1f',
surfaceVariantOpacity16P: '#53433f29',
onSurfaceVariantOpacity8P: '#d8c2bc14',
onSurfaceVariantOpacity12P: '#d8c2bc1f',
onSurfaceVariantOpacity16P: '#d8c2bc29',
inverseSurfaceOpacity8P: '#ede0dc14',
inverseSurfaceOpacity12P: '#ede0dc1f',
inverseSurfaceOpacity16P: '#ede0dc29',
inverseOnSurfaceOpacity8P: '#201a1814',
inverseOnSurfaceOpacity12P: '#201a181f',
inverseOnSurfaceOpacity16P: '#201a1829',
inversePrimaryOpacity8P: '#b02f0014',
inversePrimaryOpacity12P: '#b02f001f',
inversePrimaryOpacity16P: '#b02f0029',
shadowOpacity8P: '#00000014',
shadowOpacity12P: '#0000001f',
shadowOpacity16P: '#00000029',
surfaceTintOpacity8P: '#ffb5a014',
surfaceTintOpacity12P: '#ffb5a01f',
surfaceTintOpacity16P: '#ffb5a029',
outlineVariantOpacity8P: '#53433f14',
outlineVariantOpacity12P: '#53433f1f',
outlineVariantOpacity16P: '#53433f29',
scrimOpacity8P: '#00000014',
scrimOpacity12P: '#0000001f',
scrimOpacity16P: '#00000029',
surfaceContainerHighestOpacity8P: '#3b333114',
surfaceContainerHighestOpacity12P: '#3b33311f',
surfaceContainerHighestOpacity16P: '#3b333129',
surfaceContainerHighOpacity8P: '#2f282714',
surfaceContainerHighOpacity12P: '#2f28271f',
surfaceContainerHighOpacity16P: '#2f282729',
surfaceContainerOpacity8P: '#251e1c14',
surfaceContainerOpacity12P: '#251e1c1f',
surfaceContainerOpacity16P: '#251e1c29',
surfaceContainerLowOpacity8P: '#201a1814',
surfaceContainerLowOpacity12P: '#201a181f',
surfaceContainerLowOpacity16P: '#201a1829',
surfaceContainerLowestOpacity8P: '#120d0b14',
surfaceContainerLowestOpacity12P: '#120d0b1f',
surfaceContainerLowestOpacity16P: '#120d0b29',
surfaceBrightOpacity8P: '#3f373514',
surfaceBrightOpacity12P: '#3f37351f',
surfaceBrightOpacity16P: '#3f373529',
surfaceDimOpacity8P: '#18121014',
surfaceDimOpacity12P: '#1812101f',
surfaceDimOpacity16P: '#18121029'
};
