import AsyncStorage from '@react-native-async-storage/async-storage';
import { rootMiddleware, rootReducer } from './root-reducer';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import createCompressor from 'redux-persist-transform-compress';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { listenerMiddleware } from './listener-middleware';
import { setupListeners } from '@reduxjs/toolkit/query';
// import { addAuthListeners } from '.';

console.log('Initializing store...');

const compressor = createCompressor ({
  whitelist: []
})

const persistConfig = {
  key: 'root', // key is required
  storage: AsyncStorage,
  version: 1,
  whitelist: [],
  transforms: [compressor],
  stateReconciler: autoMergeLevel2
}

const persistedReducer = persistReducer(persistConfig, combineReducers(rootReducer))


const loggingMiddleware = (store) => (next) => (action) => {
  console.log("Dispatching action:", action);
  console.log("State before:", store.getState());
  const result = next(action);
  console.log("State after:", store.getState());
  return result;
};

// Configure and create the Redux store
export const store = configureStore({
  reducer: persistedReducer,
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })
  //     .concat(rootMiddleware, listenerMiddleware.middleware)
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })
      // .prepend(listenerMiddleware.middleware)
      .concat(rootMiddleware)
      .concat(loggingMiddleware),
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger).concat(rootMiddleware)
});

const reduxState = store.getState();
console.log("store config - Redux State Tree:", reduxState);

console.log("store config - authSlice:", reduxState.authSlice);
console.log("store config - ayanshalaBackend:", reduxState.ayanshalaBackendApi);


// addAuthListeners(listenerMiddleware.startListening)
setupListeners(store.dispatch);

export const persistor = persistStore(store);

