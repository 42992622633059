import { ArrowLeft } from '@tamagui/lucide-icons';

import { Stack, Typography, XStack } from '../../elements';

import React, { ReactNode } from 'react';
import AppBar, { AppBarProps } from './app-bar';
import { useNavigate } from 'react-router-dom';


export type BackAppBarProps = {
 title?: string;
 backIconColor?: string;
 titleColor?: string;
 rightIcon?: ReactNode;
 rightIconPress?: () => void;
 goBack?: () => void;
 onBack?: () => void;
} & AppBarProps;


export function BackAppBar({
 title,
 rightIcon,
 rightIconPress,
 children,
 theme,
 backIconColor = '$primary',
 titleColor,
 goBack: goBackProp,
 onBack: onBackProp,
 ...props
}: BackAppBarProps) {
//  const goBack = useGoBack();
const navigate = useNavigate();

  function onBack() {
    navigate(-1);
    onBackProp?.();
  }


 return (
   <AppBar {...props} theme={theme} {...(theme && { statusBarProps: { themeMode: 'dark' } })}>
     <AppBar.Content>
       <AppBar.Icon icon={<ArrowLeft/>} onPress={goBackProp || onBack} color={backIconColor} />
       <XStack alignItems="center" flex={1} marginLeft="$2">
         <Stack flex={1}>
           <Typography
             flexShrink={1}
             color={titleColor}
             variant="h5"
             numberOfLines={1}
             ellipsizeMode="tail">
             {title}
           </Typography>
         </Stack>
         {rightIcon && <AppBar.Icon icon={rightIcon} onPress={rightIconPress} />}
       </XStack>
     </AppBar.Content>
     {children && <AppBar.Content>{children}</AppBar.Content>}
   </AppBar>
 );
}



