import { ReactNode } from "react";
import { useThemeColorTokens } from "../../elements";
import { Separator, Stack, StackProps, XStack, XStackProps } from "tamagui";
import { Icon, IconButton, IconProps } from "../ui";

export type AppBarProps = {
    children?: ReactNode;
    separator?: boolean;
} & StackProps;

// function AppBarIcon(){

// }

export type AppBarIconProps = IconProps

export function AppBarIcon({ color = '$onSurfaceVariant', onPress, ...props }: AppBarIconProps) {
 // eslint-disable-next-line @typescript-eslint/ban-ts-comment
 //@ts-ignore
 return <IconButton size="lg" variant="text" color={color} {...props} onPress={onPress} />;
}


export type AppBarContentProps = XStackProps;
function AppBarContent({children, ...props}: AppBarContentProps){
    return (
        <XStack minHeight={"$8"} paddingHorizontal="$2" paddingVertical={"$1"} alignItems="center" {...props}>
            {children}
        </XStack>
    )
}

function AppBarSeparator(props){
    const { outlineOpacity16P } = useThemeColorTokens();        
    return (
        <Separator backgroundColor={outlineOpacity16P} borderColor={outlineOpacity16P} height={1} />
    )
}

function AppBar({children, separator = true, ...props}){

    return (
        <Stack flex={1} zIndex={1000} {...props}> 
            {children}
            {separator && <AppBarSeparator />}
        </Stack>
    )
}


AppBar.Content = AppBarContent;
AppBar.Icon = AppBarIcon;
AppBar.Separator = AppBarSeparator;



export default AppBar;


// {/* <div
// style={{
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     right: 0,
//     zIndex: 1000, // Ensure it stays above other content
//     backgroundColor: 'white', // Match background for seamless integration
//     boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Optional shadow for visual separation
// }}
// >
//     <HomeAppBar />
// </div> */}