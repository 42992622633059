import { useCallback, useEffect, useMemo, useState } from "react";
import { GLThemeStorageKey } from "../components";
import { themeTokens } from "../theme/tokens";
import { loadString } from "../utils/storage";
import { AyanshalaTheme } from "../theme/themes";


const orange_light = themeTokens.light_orange;
type BaseTheme = typeof orange_light;

export function useThemeColorTokens(theme?: AyanshalaTheme) {
    const [themeColorTokens, setThemeColorTokens] = useState<BaseTheme>(undefined);    
    let themeVal = theme;

    const loadTheme = useCallback(async () => {
        const storedTheme = await loadString(GLThemeStorageKey);
        return storedTheme;
    }, []);

    useEffect(() => {
        (async function () {
            if (!theme) {
                themeVal = await loadTheme() as AyanshalaTheme;
            }
            
            const _themeTokens = themeVal.split("_").reverse().join("_");
            setThemeColorTokens(themeTokens[_themeTokens]);
        })();
    }, [theme, loadTheme]); // Add 'theme' and 'loadTheme' to dependencies


    return {
        ...themeColorTokens
    };
}
