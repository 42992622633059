import { Stack, useMedia, XStack } from "tamagui";
import AppBar, { AppBarProps } from "./app-bar";
import { Typography, useThemeColorTokens } from "../../elements";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Icon } from "../ui";
import { AlignJustify, Home, Library, LogIn, LogOut, Notebook } from "@tamagui/lucide-icons";
import { useCurrentRouteName } from "../../hooks";
import { HomeDrawer } from "../drawers/home-drawer";
import { AppLogo } from "../app-logo";
import { authActions, selectIsLoggedIn, useAppDispatch, useAppSelector } from "../../store";

// export function HomeAppBar1() {
//   const { surfaceContainerLowest } = useThemeColorTokens();

//     return (
//         <AppBar>
//           <XStack padding={"$4"} backgroundColor={surfaceContainerLowest}>
//           </XStack>
//         </AppBar>
//     )
// }

type HomeAppBarProps = AppBarProps;

type Menu = {
  title: string;
  url?: string;
  icon: React.ComponentType<any> | React.ReactNode;
  active?: boolean;
  onPress?: () => void;
  badge?: number;
  target?: '_self' | '_blank';
 };

interface MenuItemProps {
  menu: Menu;
 }
 
 
 function MenuItem({ menu }: MenuItemProps) {
  const { surfaceContainerLowest, primary, onSurface } = useThemeColorTokens();  
  function handleMenuPress(menu: Menu) {
    menu.onPress?.();
  } 

  const buttonProps = {
    backgroundColor: menu.active ? surfaceContainerLowest : 'transparent',
    startIcon: menu.icon,
    textColor: menu.active ? primary : onSurface,
    variant: 'text',
    size: 'md',
    onPress: () => handleMenuPress(menu),
    children: menu.title // Ensure children is included in buttonProps
  };
 
  return <Button {...buttonProps} />;;
 }
  
function MenuItemWithLink({ menu }: { menu: Menu }) {
  return menu.url ? (
    <Link to={menu.url} target={menu.target || '_self'}>
      <MenuItem menu={menu} />
    </Link>
  ) : (
    <MenuItem menu={menu} />
  );
}

function AuthCta(){
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const dispatch = useAppDispatch();

  const logout = useCallback(() => dispatch(authActions.clearAuth()), [])      
  const { primary } = useThemeColorTokens();   


  function onLogoutPress(){
    logout();
    window.location.reload();
  }
  
  return (
    <>
          {!isLoggedIn &&
            <Link to={`/auth/phone`}>
              <Button backgroundColor="transparent" startIcon={<LogIn />} textColor={primary} size="md" children={'Login'} />            
            </Link>                      
          }    
          {isLoggedIn &&
            <Button backgroundColor="transparent" startIcon={<LogOut />} textColor={primary} size="md" children={'Logout'} onPress={onLogoutPress} />
          }              
    </>
  )

}

export function HomeAppBar({ children, ...props }: HomeAppBarProps) {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);


  const screenName = useCurrentRouteName();
  const media = useMedia();
  const { surfaceContainerLowest, primary } = useThemeColorTokens();  

  const [showDrawer, toggleShowDrawer] = useState<boolean>(false);

  useEffect(() => {
    if (media.gtXl) {
      toggleShowDrawer(false);
    }
  }, [media.gtXl]);
 

  const { menus }: { menus: Menu[]; moreMenu: Menu[] } = useMemo(() => {
    const menuLocal = [
      {
        title: 'Home',
        url: '/',
        icon: Home,
        active: ['HomeScreen', 'DashboardScreen'].includes(screenName)
      }
      // {
      //   title: 'Browse Courses',
      //   url: '',
      //   icon: Library,
      //   target: '_blank'
      // }
  ];    
  if(isLoggedIn){
    menuLocal.push(
      {
        title: 'Bookings',
        url: '/bookings',
        icon: Notebook,
        active: ['BookingsScreen'].includes(screenName)
      }      
    )
  }
    return {
      menus: menuLocal,
      moreMenu: []
      // menus: menuLocal.slice(0, menuCount) || [],
      // moreMenu: menuLocal.slice(menuCount) || []
    };
  }, [screenName]);
  
 
  return (
    <>
      <AppBar top={0} left={0} style={{position: "fixed"}} width={"100%"} backgroundColor={surfaceContainerLowest} {...props} key={'home-app-bar'}>
        <AppBar.Content gap="xs">
          <XStack gap="$2" flex={1} alignItems="center">
            <AppBar.Icon
              $gtLg={{ display: 'none' }}
              icon={<AlignJustify />}
              onPress={() => toggleShowDrawer(!showDrawer)}
            /> 
            <Stack cursor={'pointer'} onPress={() => console.log("nav to home")}>
              <Link to={`/`}>
                <AppLogo cache />
              </Link>
              
              {/* <AppLogo cache /> */}
            </Stack> 
            <XStack 
            $lg={{ display: 'none' }} 
            gap={'$1'} alignItems="center">
              {menus.map((menu) => {
                return <MenuItemWithLink key={menu.title} menu={menu} />
              })}
            </XStack>
          </XStack>
          <AuthCta />
        </AppBar.Content>
        {children}
      </AppBar>
      <HomeDrawer open={showDrawer} onClose={() => toggleShowDrawer(false)} />
    </>
  );
 }
 
 
 
 



// {/* <div
// style={{
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     right: 0,
//     zIndex: 1000, // Ensure it stays above other content
//     backgroundColor: 'white', // Match background for seamless integration
//     boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Optional shadow for visual separation
// }}
// >
//     <HomeAppBar />
// </div> */}