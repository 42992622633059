import { Grid } from "../../elements";
import { Image, YStack } from "tamagui";
import { useGetUploadedImagesQuery } from "../../services/api";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useGridSpacing } from "../../hooks";


export function PandaListingsSection() {
    const { user_id } = useParams();
    const gridSpacing = useGridSpacing();
    const { data: listings } = useGetUploadedImagesQuery({
      panda_id: user_id,
    });
  
    const imageUrls = useMemo(() => {
      return (listings || [])
        .filter((data) => !data.deleted)
        .slice(0, 5)
        .map((data) => data.imageUrl);
    }, [listings]);
  
    return (
        <Grid row="sm" spacing={gridSpacing}>
            <Grid sm={6}>
                <Image
                borderRadius={8}
                source={{ uri: imageUrls[0] }}
                $gtSm={{ width: "100%", height: "100%" }}
                $sm={{ width: "100%", height: 250 }}
                />
            </Grid>
            <Grid sm={3}>
          {/* Left Column */}
            <YStack gap="$4" flex={1}>
                {imageUrls.slice(1, 3).map((url, index) => (
                <Image
                    key={index}
                    borderRadius={8}
                    source={{ uri: url }}
                    $gtSm={{ width: "100%", height: 150 }}
                    $sm={{ width: "100%", height: 120 }}
                />
                ))}
            </YStack>
            </Grid>
            <Grid sm={3}>
                <YStack gap="$4" flex={1}>
                    {imageUrls.slice(3, 5).map((url, index) => (
                    <Image
                        key={index}
                        borderRadius={8}
                        source={{ uri: url }}
                        $gtSm={{ width: "100%", height: 150 }}
                        $sm={{ width: "100%", height: 120 }}
                    />
                    ))}
                </YStack>
            </Grid>                        
        </Grid>
    );
}