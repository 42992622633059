import { useReducer } from 'react';

export function useStateReducer<T>(initialState: T) {
 return useReducer(
   (prevState: T, newState: Partial<T>) => ({
     ...prevState,
     ...newState
   }),
   initialState
 );
}



