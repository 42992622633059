import { useThemeColorTokens } from "../../elements";
import { CenteredLayout, Footer, HomeAppBar, PandaAboutSection, PandaIdentitySection, PandaListingsSection, PandaReviewSection, PandaStatsSection, Screen } from "../../components";
import { Button, Separator, Stack, YStack } from "tamagui";
import { useGetPublicPandaProfileQuery } from "../../services/api";
import { Link, useParams } from "react-router-dom";

/**
 * Key Changes:
Left Panel (PandaStatsSection and Identity):

Used position: sticky to make the Left Panel move with the page scroll.
Applied top: 0 to ensure it stays fixed relative to the viewport.
Right Panel:

Wrapped the content inside a div with overflowY: auto to allow it to scroll independently within the page-level scrollbar.
The YStack wrapping the div is set to overflow: hidden to disable its independent scrollbar.
Single Scrollbar:

Both the Left and Right Panels use the same scrollbar at the page's right-most edge.
Padding Adjustment:

Added padding to the Right Panel’s div to ensure content doesn't overlap with the scrollbar.

* Behavior:
Left Panel: Stays fixed relative to the viewport and moves with the page scroll.
Right Panel: Uses the page-level scrollbar for visual continuity. It scrolls independently until its content ends, after which the page scroll resumes.
Scrollbar: A single scrollbar at the right-most edge provides visual continuity for both panels.
 */

// sample user_id for testing: b2dd6be7-8aeb-4f72-9454-f2d54f4a9a70
export function UsersScreen() {
    const { primary, onSurface } = useThemeColorTokens();
    const {user_id} = useParams();

    // api hook
    const {data: pandaProfileData} = useGetPublicPandaProfileQuery({
        panda_id: user_id
    });

    return (
        <Screen
            appBar={<HomeAppBar />}
            context={{ name: 'UsersScreen' }}
            preset="fixed"
            footer={<Footer />}
        >
            <CenteredLayout marginTop={"$10"} marginBottom={"$3"} gap={"$4"}>
                <Stack
                    gap="$10"
                    height="100%"
                    $gtTablet={{flexDirection: 'row'}}
                >
                    {/* Left Panel */}
                    <YStack
                        gap="$3"
                        $gtTablet={{position: 'sticky', top: 100, alignSelf: 'flex-start'}}
                    >
                        <Stack gap="$3" $gtMd={{flexDirection: 'row'}} $gtTablet={{flexDirection: 'column'}}>
                            <PandaStatsSection />
                            <PandaIdentitySection />
                        </Stack>
                        <Link to={`/book/users/${user_id}`}>
                            <Button width={'100%'} padding={20} backgroundColor={primary} color={'white'} children={'Book Now'}/>
                        </Link>
                    </YStack>

                    {/* Right Panel */}
                    <YStack
                        gap="$5"
                        flex={1}
                        style={{
                            overflow: 'hidden', // Disable independent scroll for YStack
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                overflowY: 'auto', // Enable independent scrolling for the RightPanel
                                overflowX: 'hidden',
                                paddingRight: '1rem', // Add space to avoid content hiding behind scrollbar
                            }}
                        >
                            <PandaAboutSection />
                            <Separator backgroundColor={primary} borderColor={primary} height={1} />
                            <PandaReviewSection />
                        </div>
                    </YStack>
                </Stack>
                <PandaListingsSection />
            </CenteredLayout>
        </Screen>
    );
}
