import { themeTokens } from './tokens';

const orange_light = themeTokens.light_orange;
type BaseTheme = typeof orange_light;
const orange_dark: BaseTheme = themeTokens.dark_orange;

const rose_light: BaseTheme = themeTokens.light_rose;
const rose_dark: BaseTheme = themeTokens.dark_rose;

const deeporange_light: BaseTheme = themeTokens.light_deeporange;
const deeporange_dark: BaseTheme = themeTokens.dark_deeporange;

const AppThemes = {
 orange_light,
 orange_dark,
 rose_light,
 rose_dark,
 deeporange_light,
 deeporange_dark
};

export type AyanshalaTheme = keyof typeof AppThemes;

type Themes = {
 [key in AyanshalaTheme]: BaseTheme;
};


export const themes: Themes = AppThemes;





