import { ReactNode } from 'react';
import { Typography, useThemeColorTokens } from '../../../elements';
import { AlertDialog as TMAlertDialog, Button, XStack, YStack, Separator } from 'tamagui'

type AlertDialogProps = {
    open: boolean;
    onOpenChange: (bool: boolean) => void;
    children: ReactNode;
    title?: string;
    actionLabel?: string;
    onActionPress?: () => void;
    cancelLabel?: () => void;
}

export function AlertDialog({open, onOpenChange, children, title, actionLabel, onActionPress, cancelLabel}: AlertDialogProps) {
    const { onSurfaceOpacity16P, surfaceContainerLowest, primary, outlineOpacity16P } = useThemeColorTokens();
  return (
    <TMAlertDialog native open={open} onOpenChange={onOpenChange}>
      {/* <TMAlertDialog.Trigger asChild>
        <Button>Show Alert</Button>
      </TMAlertDialog.Trigger> */}

      <TMAlertDialog.Portal>
        <TMAlertDialog.Overlay
          key="overlay"
          backgroundColor={onSurfaceOpacity16P}
          animation="quick"
          opacity={0.5}
          enterStyle={{ opacity: 0 }}
          exitStyle={{ opacity: 0 }}
        />
        <TMAlertDialog.Content
          bordered
          elevate
          key="content"
          animation={[
            'quick',
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}
          enterStyle={{ x: 0, y: -20, opacity: 0, scale: 0.9 }}
          exitStyle={{ x: 0, y: 10, opacity: 0, scale: 0.95 }}
          x={0}
          scale={1}
          opacity={1}
          backgroundColor={surfaceContainerLowest}
          y={0}
        >
            <YStack gap="$4">
                {!!title && 
                  // <TMAlertDialog.Title>{title}</TMAlertDialog.Title>
                  <YStack gap={"$1"}>
                  <Typography variant="subtitle2">{title}</Typography>                
                    <Separator backgroundColor={outlineOpacity16P} borderColor={outlineOpacity16P} height={1} />
                  </YStack>
                }
              {children}
              {/* <TMAlertDialog.Description>
                By pressing yes, you accept our terms and conditions.
              </TMAlertDialog.Description> */}

              {!!actionLabel || !!cancelLabel && 
                <XStack gap="$3" justifyContent="flex-end" padding={"$1"}>
                {!!cancelLabel && 
                  <TMAlertDialog.Cancel asChild>
                    <Button>Cancel</Button>
                  </TMAlertDialog.Cancel>                
                }
                {!!actionLabel &&
                  <TMAlertDialog.Action asChild>
                    <Button padding={14} backgroundColor={primary} color={'white'} children={actionLabel} onPress={onActionPress}/>
                  </TMAlertDialog.Action>
                }
              </XStack>              
              }
            </YStack>

        </TMAlertDialog.Content>
      </TMAlertDialog.Portal>
    </TMAlertDialog>
  )
}