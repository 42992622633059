import { Typography } from "../../elements";
import { Icon } from "../../components";
import { Avatar, Card, XStack, YStack } from "tamagui";
import { Star, StarFull } from "@tamagui/lucide-icons";
import { useGetPublicPandaProfileQuery } from "../../services/api";
import { useMemo } from "react";
import { getFirstName } from "../../utils/app";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";

function StarRating({scale, rating}: {scale: number; rating: number}){
    return (
        <XStack>
            {Array.from({ length: scale }, (_, index) => (
                <Icon
                key={index}
                size={16}
                color={'$yellowContainer'}
                icon={index < rating ? <StarFull /> : <Star />}
                />
            ))}
        </XStack>
    )
}

export function PandaReviewSection(){
    const {user_id} = useParams();     
    const {data: pandaProfileData} = useGetPublicPandaProfileQuery({
        panda_id: user_id
    });

    const {fName} = useMemo(()=>{
        const fName = getFirstName(pandaProfileData?.name);        
        return {
            fName: fName
        }
    }, [pandaProfileData?.name])

    return (
        <YStack gap={"$2"} paddingVertical="$3">
            <Typography variant="h5">{`${fName}'s reviews`} </Typography>              
            <Card elevate size="$2" bordered        
            width={342}
            overflow="hidden"
            paddingHorizontal={"$3"}
            paddingVertical={"$2"}
            >
                <YStack gap="$3">
                    <YStack flex={1}>
                        <Typography f={1} numberOfLines={3} variant="body2">{pandaProfileData?.feedback?.comments}</Typography>
                    </YStack>

                    <XStack alignItems="center" justifyContent="space-between">
                        <XStack gap={"$1"}>
                            <Avatar circular size="$3">
                                <Avatar.Image
                                    accessibilityLabel="Profile Picture"
                                    src="https://avatar.iran.liara.run/public/boy?username=Ash"
                                />
                                <Avatar.Fallback backgroundColor="$background" />
                            </Avatar>
                            <YStack flex={1}>
                                <Typography variant="subtitle2">Anonymous</Typography>                              
                                <Typography variant="body2">{dayjs(pandaProfileData?.feedback?.createdAt).fromNow()}</Typography>                                                          
                            </YStack>

                        </XStack>

                        <XStack>
                            <YStack justifyContent="center">
                                <XStack gap="$1" justifyContent="space-between">
                                    <Typography variant="caption">Your Rating</Typography>  
                                    <StarRating scale={3} rating={Number(pandaProfileData?.feedback?.pandaRating)} />
                                </XStack>
                                <XStack gap="$1" justifyContent="space-between">
                                    <Typography variant="caption">Guide Rating</Typography>                                                                  
                                    <StarRating scale={3} rating={Number(pandaProfileData?.feedback?.guideRating)} />
                                </XStack>
                            </YStack>
                        </XStack>
                    </XStack>              
                </YStack>
            </Card>            
        </YStack>        
    )
}
