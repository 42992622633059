import { Typography } from "../../elements";
import { Avatar, Card, Separator, XStack, YStack } from "tamagui";
import { useGetPublicPandaProfileQuery } from "../../services/api";
import { useParams } from "react-router-dom";


export function PandaStatsSection(){
    const {user_id} = useParams();    
    const {data: pandaProfileData} = useGetPublicPandaProfileQuery({
        panda_id: user_id
    });

    return (
            <Card elevate size="$2" bordered   
            $gtTablet={{width: 342, paddingHorizontal: "$3", paddingVertical: "$4" }}     
            // scale={0.9}
            // hoverStyle={{ scale: 0.925 }}
            // pressStyle={{ scale: 0.875 }}    
            overflow="hidden"
            paddingHorizontal={"$2"}
            paddingVertical={"$2"}
            flex={1}
            >
                <XStack>
                    {/* Left Section - Avatar and Name */}
                    <YStack flex={1} paddingHorizontal="$4" gap="$4" justifyContent="center" alignItems="center">
                        {/* Avatar Wrapper */}
                        <YStack position="relative" size="$20" justifyContent="center" alignItems="center">
                            <Avatar circular size="$20">
                                <Avatar.Image
                                    accessibilityLabel="Profile Picture"
                                    src={pandaProfileData?.profileImageUrl}
                                />
                                <Avatar.Fallback backgroundColor="$background" />
                            </Avatar>

                        </YStack>
                        <Typography variant="h1" textTransform="capitalize">{pandaProfileData?.name}</Typography>
                    </YStack>

                    {/* Right Section - Stats */}
                    <YStack gap="$3" flex={1} justifyContent="center">
                        {/* Reviews */}
                        <YStack gap="$2">
                            <Typography variant="h4">{pandaProfileData?.feedbackCount}</Typography>
                            <Typography variant="subtitle2">Review</Typography>
                        </YStack>

                        {/* Separator */}
                        <Separator
                            backgroundColor="$outlineOpacity16P"
                            borderColor="$outlineOpacity16P"
                            height={1}
                        />

                        {/* Experience */}
                        <YStack gap="$2">
                            <Typography variant="h4">{pandaProfileData?.experience}</Typography>
                            <Typography variant="subtitle2">Years of Pooja</Typography>
                        </YStack> 
                    </YStack>
                </XStack>
            </Card>
    )
}
