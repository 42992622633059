import { createFont } from 'tamagui';
import { Platform } from 'react-native';

/**
 * Create a font:
 * To work with the tamagui UI kit styled components (which is optional)
   you'd want the keys used for `size`, `lineHeight`, `weight` and
   `letterSpacing` to be consistent. The `createFont` function
   will fill-in any missing values if `lineHeight`, `weight` or
   `letterSpacing` are subsets of `size`.
 */
const heading = createFont({
 family: Platform.select({ web: 'Inter', native: 'Inter-SemiBold' }),
 size: {
   h1: 26,
   h2: 24,
   h3: 22,
   h4: 20,
   h5: 18
 },
 weight: {
   h1: '600',
   h2: '600',
   h3: '600',
   h4: '600',
   h5: '600'
 },
 color: {
   h1: '$onSurface',
   h2: '$onSurface',
   h3: '$onSurface',
   h4: '$onSurface',
   h5: '$onSurface'
 },
 letterSpacing: {
   h1: -0.4,
   h2: -0.4,
   h3: -0.4,
   h4: -0.4,
   h5: -0.4
 },
 lineHeight: {
   h1: 32,
   h2: 28,
   h3: 24,
   h4: 24,
   h5: 24
 },
 ...Platform.select({
   web: {
     face: {
       600: { normal: 'Inter' }
     }
   }
 })
});

const body = createFont({
 family: Platform.select({ web: 'Inter', native: 'Inter-Regular' }),
 size: {
   body1: 16,
   body2: 14
 },
 weight: {
   body1: '400',
   body2: '400'
 },
 color: {
   body1: '$onSurfaceVariant',
   body2: '$onSurfaceVariant'
 },
 letterSpacing: {
   body1: 0,
   body2: 0
 },
 lineHeight: {
   body1: 20,
   body2: 20
 },
 ...Platform.select({
   web: {
     face: {
       400: { normal: 'Inter' }
     }
   }
 })
});

const subtitle = createFont({
 family: Platform.select({ web: 'Inter', native: 'Inter-Medium' }),
 size: {
   subtitle1: 16,
   subtitle2: 14
 },
 weight: {
   subtitle1: '500',
   subtitle2: '500'
 },
 color: {
   subtitle1: '$onSurface',
   subtitle2: '$onSurface'
 },
 letterSpacing: {
   subtitle1: 0,
   subtitle2: 0
 },
 lineHeight: {
   subtitle1: 24,
   subtitle2: 20
 },
 ...Platform.select({
   web: {
     face: {
       500: { normal: 'Inter' }
     }
   }
 })
});

const caption = createFont({
 family: Platform.select({ web: 'Inter', native: 'Inter-Regular' }),
 size: {
   caption: 10
 },
 weight: {
   caption: '400'
 },
 color: {
   caption: '$onSurfaceVariant'
 },
 letterSpacing: {
   caption: 0.4
 },
 lineHeight: {
   caption: 16
 },
 ...Platform.select({
   web: {
     face: {
       400: { normal: 'Inter' }
     }
   }
 })
});

const overline = createFont({
 family: Platform.select({ web: 'Inter', native: 'Inter-SemiBold' }),
 size: {
   overline: 10
 },
 transform: {
   overline: 'uppercase'
 },
 weight: {
   overline: '600'
 },
 color: {
   overline: '$onSurface'
 },
 letterSpacing: {
   overline: 1.2
 },
 lineHeight: {
   overline: 16
 },
 ...Platform.select({
   web: {
     face: {
       600: { normal: 'Inter' }
     }
   }
 })
});

const button = createFont({
 family: Platform.select({ web: 'Inter', native: 'Inter-Medium' }),
 size: {
   sm: 12,
   md: 14,
   lg: 16,
   xl: 16
 },
 weight: {
   sm: '500',
   md: '500',
   lg: '500',
   xl: '500'
 },
 letterSpacing: {
   sm: 0.4,
   md: 0.4,
   lg: 0.4,
   xl: 0.4
 },
 lineHeight: {
   sm: 16,
   md: 20,
   lg: 24,
   xl: 24
 },
 ...Platform.select({
   web: {
     face: {
       500: { normal: 'Inter' }
     }
   }
 })
});

export const fonts = {
 heading,
 body,
 subtitle,
 caption,
 overline,
 button,
};





