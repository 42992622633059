import { Typography, useThemeColorTokens } from "../../elements";
import { CenteredLayout, Footer, HomeAppBar, Icon, Screen } from "../../components";
import { Avatar, Button, Card, Image, ScrollView, Separator, Stack, Text, XStack, YStack, H2, Paragraph } from "tamagui";
import type { CardProps } from 'tamagui'
import { Briefcase, Check, Earth, Globe, MapPin, MessagesSquare, Network, ShieldCheck, Star, StarFull, UserSquare, X } from "@tamagui/lucide-icons";
import { useGetPublicPandaProfileQuery } from "../../services/api";
import { useMemo } from "react";
import { getFirstName } from "../../utils/app";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";


function ProfileStats(){
    const {user_id} = useParams();    
    const {data: pandaProfileData} = useGetPublicPandaProfileQuery({
        panda_id: user_id
    });

    return (
            <Card elevate size="$2" bordered        
            width={342}
            // scale={0.9}
            // hoverStyle={{ scale: 0.925 }}
            // pressStyle={{ scale: 0.875 }}    
            overflow="hidden"
            paddingHorizontal={"$3"}
            paddingVertical={"$4"}
            >
                <XStack>
                    {/* Left Section - Avatar and Name */}
                    <YStack flex={1} paddingHorizontal="$4" gap="$4" justifyContent="center" alignItems="center">
                        {/* Avatar Wrapper */}
                        <YStack position="relative" size="$20" justifyContent="center" alignItems="center">
                            <Avatar circular size="$20">
                                <Avatar.Image
                                    accessibilityLabel="Profile Picture"
                                    src={pandaProfileData?.profileImageUrl}
                                />
                                <Avatar.Fallback backgroundColor="$background" />
                            </Avatar>

                        </YStack>
                        <Typography variant="h1" textTransform="capitalize">{pandaProfileData?.name}</Typography>
                    </YStack>

                    {/* Right Section - Stats */}
                    <YStack gap="$3" flex={1} justifyContent="center">
                        {/* Reviews */}
                        <YStack gap="$2">
                            <Typography variant="h4">{pandaProfileData?.feedbackCount}</Typography>
                            <Typography variant="subtitle2">Review</Typography>
                        </YStack>

                        {/* Separator */}
                        <Separator
                            backgroundColor="$outlineOpacity16P"
                            borderColor="$outlineOpacity16P"
                            height={1}
                        />

                        {/* Experience */}
                        <YStack gap="$2">
                            <Typography variant="h4">{pandaProfileData?.experience}</Typography>
                            <Typography variant="subtitle2">Years of Pooja</Typography>
                        </YStack> 
                    </YStack>
                </XStack>
            </Card>
    )
}

function Identity(){
    const {user_id} = useParams();     
    const {data: pandaProfileData} = useGetPublicPandaProfileQuery({
        panda_id: user_id
    });

    const {fName, hasAadhaar, hasProfilePic, hasFamilyLineage} = useMemo(()=>{
        const fName = getFirstName(pandaProfileData?.name);        
        return {
            fName: fName,
            hasAadhaar: !!pandaProfileData?.aadhaarImageUrl,
            hasProfilePic: !!pandaProfileData?.profileImageUrl,
            hasFamilyLineage: !!pandaProfileData?.fatherName && !!pandaProfileData?.gfatherName && !!pandaProfileData?.ggfatherName
        }
    }, [pandaProfileData?.name, pandaProfileData?.aadhaarImageUrl, pandaProfileData?.profileImageUrl, pandaProfileData?.fatherName, pandaProfileData?.gfatherName, pandaProfileData?.ggfatherName])

    return (
            <Card elevate size="$2" bordered        
            width={342}
            overflow="hidden"
            paddingHorizontal={"$3"}
            paddingVertical={"$4"}
            >
                <YStack gap="$4">
                        <Typography variant="h5">{`${fName}'s confirmed information`}</Typography>

                        <YStack gap="$2">
                            <XStack gap="$3">
                                <Icon size={20} color={hasAadhaar ? '$success' : '$error'} icon={hasAadhaar ? <Check /> : <X />}/>                         
                                <Typography variant="body1">Upload aadhaar</Typography>
                            </XStack>
                            <XStack gap="$3">
                            <Icon size={20} color={hasFamilyLineage ? '$success' : '$error'} icon={hasFamilyLineage ? <Check /> : <X />}/>                         
                                <Typography variant="body1">Family roots</Typography>
                            </XStack>
                            <XStack gap="$3">
                            <Icon size={20} color={hasProfilePic ? '$success' : '$error'} icon={hasProfilePic ? <Check /> : <X />}/>                         
                                <Typography variant="body1">Upload profile photo</Typography>
                            </XStack>
                        </YStack>
                </YStack>
            </Card>
    )
}

function AboutSection(){
    // const { outlineOpacity16P, outlineOpacity16P } = useThemeColorTokens();
    const {primary} = useThemeColorTokens();    
    const {user_id} = useParams();     
    const {data: pandaProfileData} = useGetPublicPandaProfileQuery({
        panda_id: user_id
    });

    const {fName, lingual, pilgrimsRegions, poojaServices} = useMemo(()=>{
        const fName = getFirstName(pandaProfileData?.name);        
        return {
            fName: fName,
            lingual: pandaProfileData?.lingual.join(','),
            pilgrimsRegions: pandaProfileData?.region.join(','),
            poojaServices: pandaProfileData?.poojaType.join(',')
        }
    }, [pandaProfileData?.name, pandaProfileData?.lingual, pandaProfileData?.region, pandaProfileData?.poojaType])


    return (
            <YStack gap={"$3"} paddingBottom="$3">
                <Typography variant="h2">About {fName}</Typography>

                <YStack gap={"$4"}>
                    <XStack gap={"$3"}>
                        <YStack gap={"$2"} flex={1}>
                            <XStack gap={"$1.5"}>
                                <Icon size={20} color={'$onSurface'} icon={<Earth />}/>                                
                                <Typography variant="body2" textTransform='capitalize'>Service Providing Region: {pandaProfileData?.address}</Typography>  
                            </XStack>
                            <XStack gap={"$1.5"}>
                                <Icon size={20} color={'$onSurface'} icon={<MessagesSquare />}/>                                                     
                                <Typography variant="body2" textTransform='capitalize'>Speaks {lingual}</Typography>                            
                            </XStack>                        
                            <XStack gap={"$1.5"}>
                                <Icon size={20} color={'$onSurface'} icon={<Globe />}/>                                                     
                                <Typography variant="body2" textTransform='capitalize'>Regions of pilgrim origin: {pilgrimsRegions}</Typography>                            
                            </XStack>
                        </YStack>
                        <YStack gap={"$2"} flex={1}>
                            <XStack gap={"$1.5"}>
                                <Icon size={20} color={'$onSurface'} icon={<Network />}/>                                                     
                                <Typography variant="body2" textTransform='capitalize'>Gotra: {pandaProfileData?.gotra}</Typography>                            
                            </XStack>
                            <XStack gap={"$1.5"}>
                                <Icon size={20} color={'$onSurface'} icon={<Briefcase />}/>
                                <Typography variant="body2" textTransform='capitalize'>Pooja services: {poojaServices}</Typography>                            
                            </XStack>
                            {/* <XStack gap={"$1.5"}>
                                <Icon size={20} color={'$success'} icon={<Check />}/>                                                     
                                <Typography variant="body2">About Kumar</Typography>                            
                            </XStack> */}
                        </YStack>
                    </XStack>                

                    <Typography variant={"body2"}>
                        Namaste! As an experienced Panda in Gaya, I provide pinddaan pooja services with devotion and professionalism, ensuring every ritual is performed authentically and with care, offering yatris a respectful and fulfilling spiritual experience.                    
                    </Typography>
                </YStack>
            </YStack>        
    )
}

function StarRating({scale, rating}: {scale: number; rating: number}){
    return (
        <XStack>
            {Array.from({ length: scale }, (_, index) => (
                <Icon
                key={index}
                size={16}
                color={'$yellowContainer'}
                icon={index < rating ? <StarFull /> : <Star />}
                />
            ))}
        </XStack>
    )
}

function ReviewSection(){
    const {user_id} = useParams();     
    const {data: pandaProfileData} = useGetPublicPandaProfileQuery({
        panda_id: user_id
    });

    const {fName} = useMemo(()=>{
        const fName = getFirstName(pandaProfileData?.name);        
        return {
            fName: fName
        }
    }, [pandaProfileData?.name])

    return (
        <YStack gap={"$2"} paddingVertical="$3">
            <Typography variant="h5">{`${fName}'s reviews`} </Typography>              
            <Card elevate size="$2" bordered        
            width={342}
            overflow="hidden"
            paddingHorizontal={"$3"}
            paddingVertical={"$2"}
            >
                <YStack gap="$3">
                    <YStack flex={1}>
                        <Typography f={1} numberOfLines={3} variant="body2">{pandaProfileData?.feedback?.comments}</Typography>
                    </YStack>

                    <XStack alignItems="center" justifyContent="space-between">
                        <XStack gap={"$1"}>
                            <Avatar circular size="$3">
                                <Avatar.Image
                                    accessibilityLabel="Profile Picture"
                                    src="https://avatar.iran.liara.run/public/boy?username=Ash"
                                />
                                <Avatar.Fallback backgroundColor="$background" />
                            </Avatar>
                            <YStack flex={1}>
                                <Typography variant="subtitle2">Anonymous</Typography>                              
                                <Typography variant="body2">{dayjs(pandaProfileData?.feedback?.createdAt).fromNow()}</Typography>                                                          
                            </YStack>

                        </XStack>

                        <XStack>
                            <YStack justifyContent="center">
                                <XStack gap="$1" justifyContent="space-between">
                                    <Typography variant="caption">Your Rating</Typography>  
                                    <StarRating scale={3} rating={Number(pandaProfileData?.feedback?.pandaRating)} />
                                </XStack>
                                <XStack gap="$1" justifyContent="space-between">
                                    <Typography variant="caption">Guide Rating</Typography>                                                                  
                                    <StarRating scale={3} rating={Number(pandaProfileData?.feedback?.guideRating)} />
                                </XStack>
                            </YStack>
                        </XStack>
                    </XStack>              
                </YStack>
            </Card>            
        </YStack>        
    )
}

function Listings(){
    const {user_id} = useParams();     
    const {data: pandaProfileData} = useGetPublicPandaProfileQuery({
        panda_id: user_id
    });

    return (
        <YStack gap={"$4"}>
            <Typography variant="h2">Kumar's Review</Typography>      
            <Card elevate size="$2" bordered        
            width={342}
            overflow="hidden"
            paddingHorizontal={"$3"}
            paddingVertical={"$4"}
            >
                <YStack gap="$4">
                        <Typography variant="h5">Kumar's</Typography>

                        <YStack gap="$2">
                            <XStack gap="$3">
                                <Icon size={20} color={'$success'} icon={<Check />}/>                         
                                <Typography variant="body1">Upload aadhaar</Typography>
                            </XStack>
                            <XStack gap="$3">
                                <Icon size={20} color={'$success'} icon={<Check />}/>                                                    
                                <Typography variant="body1">Family roots</Typography>
                            </XStack>
                            <XStack gap="$3">
                                <Icon size={20} color={'$success'} icon={<Check />}/>                         
                                <Typography variant="body1">Upload profile photo</Typography>
                            </XStack>
                        </YStack>
                </YStack>
            </Card>            
        </YStack>        
    )
}

// export function AboutScreen(){
//     const {primary} = useThemeColorTokens();
    
//     return (
//         <Screen
//             appBar={<HomeAppBar />}        
//             context={{name: 'AboutScreen'}}
//             preset="scroll"
//             footer={<HomeAppBar />}
//             >
//                 <CenteredLayout>
//                     {/* <XStack flex={1} gap={"$10"} borderWidth={1} borderColor={'red'} height={'100%'}> */}
//                     {/* <XStack gap={"$10"}>                     */}
//                     <XStack gap={"$10"} borderWidth={1} borderColor={'red'} paddingVertical={"$4"}>            
//                         <YStack gap={"$3"} paddingHorizontal="$4" flexShrink={0}>
//                             <ProfileStats />
//                             <Identity />
//                         </YStack>
//                         <YStack gap={"$5"} 
//                         flex={1} 
//                         // height={'calc(100vh - 80px)'} 
//                         overflow="scroll"     
//                         style={{
//                             overflowY: 'hidden',
//                             overflowX: 'hidden',
//                             height: '100%', // Allow full-height scrolling within the flex container                            
//                         }}>
//                             <AboutSection />
//                             <Separator backgroundColor={primary} borderColor={primary} height={1} />
//                             <ReviewSection />                            
//                             <Separator backgroundColor={primary} borderColor={primary} height={1} />
//                             <Listings />
//                         </YStack>
//                     </XStack>
 
//                 </CenteredLayout>
//         </Screen>        
//     )
// }


/**
 * Key Changes:
Left Panel (ProfileStats and Identity):

Used position: sticky to make the Left Panel move with the page scroll.
Applied top: 0 to ensure it stays fixed relative to the viewport.
Right Panel:

Wrapped the content inside a div with overflowY: auto to allow it to scroll independently within the page-level scrollbar.
The YStack wrapping the div is set to overflow: hidden to disable its independent scrollbar.
Single Scrollbar:

Both the Left and Right Panels use the same scrollbar at the page's right-most edge.
Padding Adjustment:

Added padding to the Right Panel’s div to ensure content doesn't overlap with the scrollbar.

* Behavior:
Left Panel: Stays fixed relative to the viewport and moves with the page scroll.
Right Panel: Uses the page-level scrollbar for visual continuity. It scrolls independently until its content ends, after which the page scroll resumes.
Scrollbar: A single scrollbar at the right-most edge provides visual continuity for both panels.
 */


// export function AboutScreen() {
//     const { primary } = useThemeColorTokens();
//     const {user_id} = useParams();

//     const {data: pandaProfileData} = useGetPublicPandaProfileQuery({
//         panda_id: user_id
//     });

//     return (
//         <Screen
//             appBar={<HomeAppBar />}
//             context={{ name: 'AboutScreen' }}
//             preset="fixed"
//             footer={<Footer />}
//         >
//             <CenteredLayout marginTop={"$10"} marginBottom={"$3"}>
//                 <XStack
//                     gap="$10"
//                     // borderWidth={1}
//                     // borderColor="red"
//                     // paddingVertical="$4"
//                     height="100%"
//                 >
//                     {/* Left Panel */}
//                     <YStack
//                         gap="$3"
//                         paddingHorizontal="$4"
//                         // flexShrink={0}
//                         style={{
//                             position: 'sticky', // Keep it fixed relative to the viewport
//                             top: 0, // Align with the page scroll
//                             alignSelf: 'flex-start',
//                         }}
//                     >
//                         <ProfileStats />
//                         <Identity />
//                     </YStack>

//                     {/* Right Panel */}
//                     <YStack
//                         gap="$5"
//                         flex={1}
//                         style={{
//                             overflow: 'hidden', // Disable independent scroll for YStack
//                         }}
//                     >
//                         <div
//                             style={{
//                                 height: '100%',
//                                 overflowY: 'auto', // Enable independent scrolling for the RightPanel
//                                 overflowX: 'hidden',
//                                 paddingRight: '1rem', // Add space to avoid content hiding behind scrollbar
//                             }}
//                         >
//                             <AboutSection />
//                             <Separator backgroundColor={primary} borderColor={primary} height={1} />
//                             <ReviewSection />
//                             <Separator backgroundColor={primary} borderColor={primary} height={1} />
//                             <ReviewSection />                            
//                             {/* <Listings /> */}

//                             {/* <Separator backgroundColor={primary} borderColor={primary} height={1} />
//                             <ReviewSection />
//                             <Separator backgroundColor={primary} borderColor={primary} height={1} />
//                             <Listings />           

//                             <Separator backgroundColor={primary} borderColor={primary} height={1} />
//                             <ReviewSection />
//                             <Separator backgroundColor={primary} borderColor={primary} height={1} />
//                             <Listings />                                              */}
//                         </div>
//                     </YStack>
//                 </XStack>
//             </CenteredLayout>
//         </Screen>
//     );
// }


export function AboutScreen() {
    const { primary } = useThemeColorTokens();

    return (
        <Screen
            appBar={<HomeAppBar />}
            context={{ name: 'AboutScreen' }}
            preset="fixed"
            footer={<Footer />}
        >
            <CenteredLayout marginTop={"$10"} marginBottom={"$3"}>
                <Typography variant="h4">This is about screen</Typography>                
            </CenteredLayout>
        </Screen>
    );
}

// export function AboutScreen() {
//     const { primary } = useThemeColorTokens();

//     return (
//         <Screen
//             appBar={<HomeAppBar />}
//             context={{ name: 'AboutScreen' }}
//             preset="scroll"
//             footer={<HomeAppBar />}
//         >
//             <CenteredLayout>
//                 <XStack
//                     gap="$10"
//                     borderWidth={1}
//                     borderColor="red"
//                     paddingVertical="$4"
//                     height="100vh"
//                 >
//                     {/* Left Section */}
//                     <YStack gap="$3" paddingHorizontal="$4" flexShrink={0}>
//                         <ProfileStats />
//                         <Identity />
//                     </YStack>

//                     {/* Right Section */}
//                     <YStack
//                         gap="$5"
//                         flex={1}
//                         style={{
//                             overflowY: 'auto', // Allow internal scrolling
//                             overflowX: 'hidden',
//                             maxHeight: 'calc(100vh - 80px)', // Adjust based on header/footer height
//                         }}
//                     >
//                         <AboutSection />
//                         <Separator backgroundColor={primary} borderColor={primary} height={1} />
//                         <ReviewSection />
//                         <Separator backgroundColor={primary} borderColor={primary} height={1} />
//                         <Listings />
//                     </YStack>
//                 </XStack>
//             </CenteredLayout>
//         </Screen>
//     );
// }



// function RightPanel(){

//     return (
//             <YStack flex={1} height={'calc(100vh - 168px)'}>
//                 <ScrollView>
//                     <Typography variant="h5">About Panda</Typography>
//                     {[...Array(50)].map((_, i) => (
//                         <Typography key={i} variant="h1" textTransform="capitalize">
//                             About Panda {i + 1}
//                         </Typography>
//                     ))}
//                 </ScrollView>
//             </YStack>        
//     )
// }



/**
 * video generation / image generation
 * diffusion models
 * llama stack
 * langchain
 */