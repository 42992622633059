// import { useColorValue, useTokenValue } from '../hooks';
import React, { ReactNode } from 'react';

import { useGetThemedIcon } from 'tamagui';
import { StackProps, Stack } from '@tamagui/core';
import { useColorValue, useTokenValue } from '../../elements/hooks';


export type IconProps = {
 color?: string;
 size?: number | string;
 icon?: ReactNode;
} & StackProps;


//Please use Lucide icons, we have kept support for material icons for backward compatibility
export const Icon = ({ color = '$onSurface', icon, size = '$3', ...props }: IconProps) => {

 const colorValue = useColorValue(color);
 // eslint-disable-next-line @typescript-eslint/ban-ts-comment
 //@ts-ignore
 let sizeValue = useTokenValue('size', size) || size;
 sizeValue = (typeof size === 'number' ? size : sizeValue) - 2;
 const getThemedIcon = useGetThemedIcon({ size: sizeValue, color: colorValue });
 return (
   <Stack {...props}>
     <Stack padding={2}>
       {getThemedIcon(icon)}
     </Stack>
   </Stack>
 );
};

{/* {isMaterialCommunity && <MCRNIcon color={colorValue} name={icon} />}
{!isMaterial && !isMaterialCommunity && getThemedIcon(icon)}
{isMaterial && !isMaterialCommunity && (
  <MRNIcon size={sizeValue} color={colorValue} name={icon} />
)} */}
