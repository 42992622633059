import { useSearchParams } from "react-router-dom";
import { CenteredLayout, Footer, HomeAppBar, Screen } from "../../components";
import { useGetBookingDetailsQuery } from "../../services/api";
import { isNilOrEmpty } from "../../utils/app";


export function BookingDetailScreen(){
    const [searchParams] = useSearchParams(); // Get the query parameters
    const bookingId = searchParams.get("bookingId"); // Extract 'bookingId'

    const {data: bookingInfo, isLoading, isFetching} = useGetBookingDetailsQuery({
        bookingId: Number(bookingId),
    }, 
    {skip: isNilOrEmpty(bookingId)});

    return (
        <Screen
            appBar={<HomeAppBar />}
            context={{ name: 'BookingDetailScreen' }}
            preset="fixed"
            footer={<Footer />}
        >
            <CenteredLayout marginTop={"$10"} marginBottom={"$3"} gap={"$4"}>

            </CenteredLayout>
        </Screen>
    );
}