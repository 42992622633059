import { FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { Typography } from '../../../elements';
import { YStack } from 'tamagui';
import { DateTimePicker } from '../../../libs/date-time-picker';

type TextFieldProps = {
  size?: string; 
  placeholder?: string; 
  helperText?: string;
  error?: boolean;
}

type FormTextFieldProps<T extends FieldValues> = UseControllerProps<T> & TextFieldProps 

export function FormDateTimePicker<T extends FieldValues>({
  name, 
  control,
  defaultValue,    
  error,    
  size, 
  placeholder, 
  helperText,
  ...props
}: FormTextFieldProps<T>){
  const {field, fieldState} = useController({control, name, defaultValue});

  return (
      <YStack width={"100%"}>
          <DateTimePicker type="date" onConfirm={field.onChange}/>
          {(error || !!helperText) &&
              <Typography variant="caption" color={error ? '$error' : '$onSurface'}>{helperText}</Typography>
          }
      </YStack>
  )
}

