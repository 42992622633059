import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AsYouType } from 'libphonenumber-js'
import { Alert, BackAppBar, CenteredLayout, Footer, FormPhoneField, FormTextField, HomeAppBar, Screen } from "../../components";
import { useForm } from 'react-hook-form';
import { Button, Spinner, Stack, useMedia } from 'tamagui';
import { Grid, Typography, useThemeColorTokens } from 'src/elements';
import { useNavigate } from 'react-router-dom';
import { PandaSevaCookies, setAccessTokenCookie, UserRole, useSendOtpMutation, useVerifyOtpMutation } from '../../services/api';
import uuid from 'react-native-uuid';
import { useStateReducer } from '../../hooks';
import { authActions, useAppDispatch } from '../../store';
import { useCallback } from 'react';
import { setCookie } from '../../libs/cookies';

// const schema = yup.object().shape({
//     phone: yup
//         .string()
//         .required('Phone number is required')
//         .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits'),
//     otp: yup
//         .string()
//         .required('Otp is required')
//         .matches(/^[0-9]{6}$/, 'Otp must be 6 digits')    
//   });
//   schema

function getSchema(sessionIdExists: boolean){
    return yup.object().shape({
        phone: yup
            .string()
            .required('Phone number is required')
            .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits'),
        otp: yup.string().when([], {
            is: () => sessionIdExists,
            then: (s) => s.required('Otp is required')
                            .matches(/^[0-9]{6}$/, 'Otp must be 6 digits'),
            otherwise: (s) => s.nullable()
        })
      });
}
export function AuthPhoneScreen(){
    const dispatch = useAppDispatch();
    const saveAccessToken = useCallback((data) => dispatch(authActions.saveAccessToken(data)), [])
    
    const media = useMedia();
    const navigate = useNavigate();    
    const { primary, inverseOnSurface } = useThemeColorTokens();
    const [sendOtpMutation, {isLoading: isSendOtpLoading}] = useSendOtpMutation();
    const [verifyOtpMutation, {isLoading: isVerifyOtpLoading}] = useVerifyOtpMutation();    

    // local var
    const isLoading = isSendOtpLoading || isVerifyOtpLoading;
    const [state, setState] = useStateReducer({
        alert: null,
        sessionId: null,
        uniqueId: uuid.v4() || '',
        role: UserRole.CLIENT //PANDA //"CLIENT"
    });
    const { register, reset, handleSubmit, control, formState: { errors }, watch } = useForm({
        defaultValues:{
          phone: '',
          otp: ''          
        },
        resolver: yupResolver(getSchema(!!state.sessionId))       
    });


    async function onContinuePress(){
        console.log("onContinuePress called");
        try{
            const response = await sendOtpMutation({
                "mobileNumber": watch("phone"),
                "imeiNumber": state.uniqueId,
                "role": state.role
            }).unwrap();
            console.log("response ::", response);
            if(response.status === "Success"){
                setState({
                    sessionId: response.sessionId,
                    alert: {
                        severity: 'success',
                        content: `Otp is sent on mobile number ${watch("phone")}`
                    }
                })    
            }            
            if(response.status === "Error"){
                throw new Error()
            }            

            // if(response.status === "Success"){
            //     navigate(`/auth/verifyOtp/${watch("phone")}`);
            // }
        } catch(err){
            console.log("err ::", err);
            if(err.status === 400 && err.data.includes("User already registered with mobile number")){
                setState({alert: {
                    severity: 'error',
                    content: "User already registered."
                }})
            } else {
                setState({alert: {
                    severity: 'error',
                    content: "Something went wrong."
                }})
            }
        }
    }

    async function onVerifyPress(data){
        console.log("onVerify data ::", data);
        try{
            const response = await verifyOtpMutation({
                "otp": watch("otp"),
                "sessionId": state.sessionId,
                "mobileNumber": watch("phone"),
                "imeiNumber": state.uniqueId,
                "role": state.role
            }).unwrap();
            console.log("onVerify response ::", response);    
            if(response.token){
                saveAccessToken(response.token);
                // setAccessTokenCookie(response.token);
                setCookie({ cookie: { name: PandaSevaCookies.SESSION_ID, value: state.sessionId }});                
                setCookie({ cookie: { name: PandaSevaCookies.ACCESS_TOKEN, value: response.token }});                               
                navigate("/bookings");
            }
        }catch(error){
            setState({alert: {
                severity: 'error',
                content: "Something went wrong."
            }})
        }
    }    

    return (
        <Screen
            appBar={<HomeAppBar />}
            context={{ name: 'AuthPhoneScreen' }}
            preset="fixed"
            footer={<Footer />}
        >
            <CenteredLayout marginTop={"$10"} marginBottom={"$3"}>
                <BackAppBar title="Login" separator={false}/>  
                <Grid xs={12} container row>
                    <Grid xs={12} alignItems='center'>
                        <Stack width={'100%'} padding={"$2"} maxWidth={media.gtSm ? 461 : 'auto'} gap="$2">
                            {!!state.alert &&
                                <Alert severity={state.alert.severity} content={state.alert.content} />
                            }
                            <FormPhoneField
                                disabled={!!state.sessionId}
                                size="$5" 
                                {...register('phone', {
                                    onChange: (e) => {
                                        const asYouType = new AsYouType();
                                        asYouType.input(e.target.value);
                                    }
                                })}
                                control={control}
                                placeholder="Phone Number"
                                helperText={errors?.phone?.message || "Enter a valid phone number"}
                                error={!!errors?.phone}
                            />

                            {!!state.sessionId &&
                                <FormTextField
                                    // keyboardType='numeric'                
                                    size="$5" 
                                    {...register('otp')}
                                    control={control}
                                    placeholder="Otp"
                                    helperText={errors?.otp?.message || "Enter otp"}
                                    error={!!errors?.otp}
                                />                            
                            }
                            
                            <Button disabled={isLoading} width={'100%'} padding={20} backgroundColor={primary} 
                            color={'white'} 
                            onPress={isLoading ? undefined : state.sessionId ? handleSubmit(onVerifyPress) : handleSubmit(onContinuePress)}>
                                <Typography variant="body2" color={inverseOnSurface}>{state.sessionId ? 'Verify' : 'Continue'}</Typography>
                                {isLoading &&
                                    <Spinner size="small" color={inverseOnSurface} />
                                }
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>                
            </CenteredLayout>
        </Screen>
    );
}