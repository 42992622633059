import { GetProps, TextProps, Text, styled } from "tamagui";
// import { TextWrapper } from "./text-wrapper";
// import { useFontByBreakpoint, useFontVariantMapping } from "../hooks";

function mapVariantToFont(variant) {
  let font = '$body';

  switch (variant) {
    case 'h1':
    case 'h2':
    case 'h3':
    case 'h4':
    case 'h5':
      font = '$heading';
      break;
    case 'subtitle1':
    case 'subtitle2':
      font = '$subtitle';
      break;
    case 'body1':
    case 'body2':
      font = '$body';
      break;
    case 'caption':
      font = '$caption';
      break;
    case 'overline':
      font = '$overline';
      break;
    default:
      font = '$body';
  }

  // return useFontByBreakpoint(font);
  // console.log("mapVariantToFont - font::", font);
  return font;
}

function getVariantStyle(variant, { props, fonts }) {
  try{
    // console.log("getVariantStyle - variant ::", variant);
    const size = `$${variant}`;
    // console.log("getVariantStyle - size ::", size);    
    // console.log("getVariantStyle - fonts ::", fonts);    
    const font = fonts[mapVariantToFont(variant)];
    // console.log("getVariantStyle - font style - fonts[mapVariantToFont(variant) ::", font);  
    const fontFamily = font.family;
    // console.log("getVariantStyle - fontFamily ::", fontFamily);      
    const fontSize = props.fontSize || font.size[size];
    // console.log("getVariantStyle - font.size ::", fontSize);          
    const lineHeight = props.lineHeight || font.lineHeight[size];
    // console.log("getVariantStyle - font.lineHeight ::", lineHeight);              
    const fontWeight = props.fontWeight || font.weight[size];
    // console.log("getVariantStyle - font.weight ::", fontWeight);                  
    const letterSpacing = props.letterSpacing || font.letterSpacing[size];
    // console.log("getVariantStyle - font.letterSpacing ::", letterSpacing);                      
    const textTransform = props.textTransform || font.transform?.[size];
    // console.log("getVariantStyle - font.textTransform ::", textTransform);                          
    const color = props.color || props.textColor || font.color[size];
    // console.log("getVariantStyle - font.color ::", color);                              
    return {
      textTransform,
      fontFamily,
      fontWeight,
      letterSpacing,
      fontSize,
      lineHeight,
      color
    };
  } catch(error){
    console.log("error ::", error);
  }
}

export const Typography = styled(Text, {
  name: 'Typography',
  variants: {
    variant: {
      h1: getVariantStyle,
      h2: getVariantStyle,
      h3: getVariantStyle,
      h4: getVariantStyle,
      h5: getVariantStyle,
      subtitle1: getVariantStyle,
      subtitle2: getVariantStyle,
      body1: getVariantStyle,
      body2: getVariantStyle,
      caption: getVariantStyle,
      overline: getVariantStyle
    }
  } as const,

  defaultVariants: {
    variant: 'body1'
  }
});

export type TypographyProps = TextProps & GetProps<typeof Typography>;




// export type TypographyProps = TextProps & GetProps<typeof TextWrapper>;

// function useVariantStyle(variant, { props, fonts }) {
//   const fontVariant = useFontVariantMapping(variant);
//   const font = fonts[fontVariant];
//   const size = `$${variant}`;

//   const fontFamily = font.family;
//   const fontSize = props.fontSize || font.size[size];
//   const lineHeight = props.lineHeight || font.lineHeight[size];
//   const fontWeight = props.fontWeight || font.weight[size];
//   const letterSpacing = props.letterSpacing || font.letterSpacing[size];
//   const textTransform = props.textTransform || font.transform?.[size];
//   const color = props.color || props.textColor || font.color[size];

//   return {
//     textTransform,
//     fontFamily,
//     fontWeight,
//     letterSpacing,
//     fontSize,
//     lineHeight,
//     color,
//   };
// }

// export function Typography({ variant, ...props }) {
//   const variantStyle = useVariantStyle(variant); // Use the hook here
  
//   return <TextWrapper variant={variant} {...variantStyle} {...props} />;
// }