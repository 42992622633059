import { config } from '../theme/tamagui.config';
import { AyanshalaTheme } from '../theme/themes';
import { loadString, saveString } from '../utils/storage';
import { TamaguiProvider, TamaguiProviderProps, PortalProvider, XStack } from 'tamagui';
import React, { Context, createContext, useCallback, useContext, useEffect, useState } from 'react';


let ThemeContext: Context<{ theme: AyanshalaTheme | null; changeTheme: (theme: AyanshalaTheme) => void }>;
export const GLThemeStorageKey = '@ayanshala-theme';


export function useAppTheme(): [AyanshalaTheme, (theme: AyanshalaTheme) => void] {
 const { theme, changeTheme } = useContext(ThemeContext);
 // eslint-disable-next-line @typescript-eslint/ban-ts-comment
 //@ts-ignore
 return [theme, changeTheme];
}


export function Provider({ children, ...rest }: Omit<TamaguiProviderProps, 'config'>) {
  const [theme, setTheme] = useState<AyanshalaTheme | null>(null);
  const changeTheme = useCallback((t: AyanshalaTheme) => {
    (async function () {
      saveString(GLThemeStorageKey, t);
    })();
    setTheme(t);
  }, []);
  ThemeContext = createContext({ theme, changeTheme });


  useEffect(() => {
    (async function () {
      const storedTheme = await loadString(GLThemeStorageKey);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      changeTheme(storedTheme || 'deeporange_light');
      // changeTheme(storedTheme || scheme || 'light');
    })();
  }, []);

  return (
    theme && (
    <ThemeContext.Provider value={{ theme, changeTheme }}>
      <TamaguiProvider config={config} defaultTheme={theme?.toString()} {...rest}>
        <PortalProvider shouldAddRootHost>
          {children}
        </PortalProvider>
      </TamaguiProvider>
    </ThemeContext.Provider>
    )
  );
}


//  return (
//     <TamaguiProvider config={config} {...rest}>
//       <PortalProvider shouldAddRootHost>{children}</PortalProvider>
//     </TamaguiProvider>
//  );