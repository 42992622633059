interface PandaFeedback {
    id: number;
    userId: string;
    contactNumber: string;
    pandaRating: string;
    guideRating: string;
    comments: string;
    createdAt: number;
}

export type GetPublicPandaProfileRequest = {
    panda_id: string
}

export type GetPublicPandaProfileResponse = {
    pandaId: string;
    name: string;
    aadhaarImageUrl: string;
    aadharNumber: string,
    lingual: string[];
    poojaType: string[],
    profileImageUrl: string;
    gotra: string;
    region: string[];
    address: string;
    experience: string;
    fatherName: string;
    gfatherName: string;
    ggfatherName: string;
    dob: number;
    feedback: PandaFeedback;
    feedbackCount: number;
}

export type GetPublicFeedbacksRequest = {
    panda_id: string
}

export type GetPublicFeedbacksResponse = PandaFeedback[]

export type GetBookingDetailsRequest = {
    bookingId: number;
  }

  export enum CaptainBookingStatus {
    CREATED = "CREATED",  
    PICKUP = "PICKUP",
    DROP_AT_HOTEL = 'DROP_AT_HOTEL',
    DROP_AT_PANDA = 'DROP_AT_PANDA',
    COMPLETED = "COMPLETED",
    CANCELLED_BY_PANDA = "CANCELLED_BY_PANDA",
    CANCELLED_BY_USER = "CANCELLED_BY_USER"
  }
  
  export enum PandaBookingStatus {
    CREATED = "CREATED",  
    ACCEPTED_BY_PANDA = "ACCEPTED_BY_PANDA",    
    FORWARDED_TO_CAPTAIN = "FORWARDED_To_CAPTAIN",
    DIRECT_ONBOARD_BY_PANDA = "DIRECT_ONBOARD_BY_PANDA",
    ACCEPTED_BY_USER = "ACCEPTED_BY_USER",
    CANCELLED_BY_PANDA = "CANCELLED_BY_PANDA",
    CANCELLED_BY_USER = "CANCELLED_BY_USER",
    COMPLETED = "COMPLETED",  
  }
  
  export enum MediatorBookingStatus {
    CREATED = "CREATED",  
    FORWARDED_TO_PANDA = "FORWARDED_To_PANDA",
    ACCEPTED_BY_USER = "ACCEPTED_BY_USER",  
    ACCEPTED_BY_PANDA = "ACCEPTED_BY_PANDA",    
    CANCELLED_BY_PANDA = "CANCELLED_BY_PANDA",
    CANCELLED_BY_USER = "CANCELLED_BY_USER",
    CANCELLED_BY_MEDIATOR = "CANCELLED_BY_MEDIATOR"
  }
  
  export type BookingStatus = PandaBookingStatus | MediatorBookingStatus | CaptainBookingStatus;  

  export type BookingAddress = {
    city: string;
    district: string;
    pincode: number,
    policeStation: string;
    state: string;
  };
    
type BookingDetails = {
  id: number;
  fullName: string;
  bookingDate: string;
  lastUpdated: string;
  createdAt: string;
  bookingStatus: string;
  clientId: string;
  pandaPothiConfirmationImage: string;
  hotelBookingId: number;
  vehicleBookingId: number;
  captainMobileNumber?: string;
  residentialAddress?: BookingAddress;
  ancestralAddress: BookingAddress;
}

type PandaDetails = {
    pandaId: string;
    name: string;
    aadhaarImageUrl: string;
    aadharNumber: string
    lingual: string[];
    poojaType: string[];
    profileImageUrl: string;
    gotra: string;
    region: string[];
    address: string;
    experience: string;
    fatherName: string;
    gfatherName: string;
    ggfatherName: string;
    dob: string;
    createdAt: string;
    updatedAt: string;
  }
  
  type CaptainDetails = {
    captainId: string;
    name: string;
    profileImageUrl: string;
    mobileNumber: string;  
  }
  
  type MediatorDetails = {    
    mediatorId: string;
    name: string;
    mobileNumber: string;
    profileImageUrl: string;
  }
  
  type VehicleDetails = {
    vehicleId: number;
    vehicleBookingStatus: string; // TODO: should be enum
    startDate: string;
    endDate: string;
    bookingId: number;
    vehicleNumber: string;
    vehicleOwnerName: string;
    vehicleOwnerContactNumber: string;
    vehicleType: string;
    vehicleName: string;
  }
  
  type HotelDetails = {
    bookingId: number;
    startDate: string;
    endDate: string;
    hotelBookingStatus: string; // should be enum
    hotelId: number;
    roomId: number;
    roomNumber: string;
    roomType: string;
    pricePerNight: number;
    hotelName: string;
    hotelLocation: string;
    hotelContactNumber: string;
  }
  
  type ExtraBookingInfo = {
    attributorName: string;
    attributorMobileNumber: string;
    comments: string;
    numberOfYatri: number;
    numberOfPoojaDays: number;
  }


  export type GetBookingDetailsResponse = {
    bookingDetails: BookingDetails | null;
    pandaDetails: PandaDetails;
    captainDetails: CaptainDetails | null;
    mediatorDetails: MediatorDetails | null;
    vehicleDetails: VehicleDetails | null;
    hotelDetails: HotelDetails | null;
    pandaBookingStatus: PandaBookingStatus; // should be enum
    mediatorBookingStatus: MediatorBookingStatus; // should be enum
    captainBookingStatus: CaptainBookingStatus; // should be enum
    extraBookingInfo: ExtraBookingInfo
  }
