// import { AppLogo } from '../app-logo/app-logo';
// import { More } from '../more';
// import { X } from '@gl/elements/icons';
// import { AppBar, AppBarIcon, ScrollView, Drawer, DrawerPops } from '@gl/elements';
import { X } from '@tamagui/lucide-icons';
import { More } from '../more';
import AppBar from '../app-bars/app-bar';
import { ScrollView } from 'tamagui';
import { Drawer, DrawerPops, Typography, useThemeColorTokens } from '../../elements';
import { AppLogo } from '../app-logo';


export type HomeDrawerProps = DrawerPops;


export function HomeDrawer(props: HomeDrawerProps) {
    const { surfaceContainerLowest, onSurfaceVariant } = useThemeColorTokens();                
 return (
   <Drawer {...props}>
     <AppBar backgroundColor={surfaceContainerLowest} position="relative" separator={false}>
       <AppBar.Content justifyContent="space-between">
         <AppLogo />
         <AppBar.Icon icon={<X />} onPress={props.onClose} color={onSurfaceVariant} />
       </AppBar.Content>
     </AppBar>
     <ScrollView showsVerticalScrollIndicator={false} backgroundColor={surfaceContainerLowest} marginTop={"$4"}>
       <More onMenuPress={props.onClose} />
     </ScrollView>
   </Drawer>
 );
}

