export const light_orange = {
primary: '#8b5000',
onPrimary: '#ffffff',
primaryContainer: '#ffdcbe',
onPrimaryContainer: '#2c1600',
primaryFixed: '#ffdcbe',
onPrimaryFixed: '#2c1600',
primaryFixedDim: '#ffb870',
onPrimaryFixedVariant: '#693c00',
secondary: '#725a42',
onSecondary: '#ffffff',
secondaryContainer: '#ffdcbe',
onSecondaryContainer: '#291806',
secondaryFixed: '#ffdcbe',
onSecondaryFixed: '#291806',
secondaryFixedDim: '#e1c1a4',
onSecondaryFixedVariant: '#59422c',
tertiary: '#58633a',
onTertiary: '#ffffff',
tertiaryContainer: '#dce8b4',
onTertiaryContainer: '#161e01',
tertiaryFixed: '#dce8b4',
onTertiaryFixed: '#161e01',
tertiaryFixedDim: '#c0cc9a',
onTertiaryFixedVariant: '#414b24',
error: '#ba1a1a',
onError: '#ffffff',
errorContainer: '#ffdad6',
onErrorContainer: '#410002',
outline: '#837468',
background: '#fffbff',
background2: '#f2f4f7',
onBackground: '#201b16',
surface: '#fff8f5',
onSurface: '#201b16',
surfaceVariant: '#f2dfd1',
onSurfaceVariant: '#51453a',
inverseSurface: '#352f2b',
inverseOnSurface: '#faefe7',
inversePrimary: '#ffb870',
shadow: '#000000',
surfaceTint: '#8b5000',
outlineVariant: '#d5c3b5',
scrim: '#000000',
surfaceContainerHighest: '#ebe0d9',
surfaceContainerHigh: '#f1e6df',
surfaceContainer: '#f7ece4',
surfaceContainerLow: '#fdf2ea',
surfaceContainerLowest: '#ffffff',
surfaceBright: '#fff8f5',
surfaceDim: '#e3d8d1',
primaryOpacity8P: '#8b500014',
primaryOpacity12P: '#8b50001f',
primaryOpacity16P: '#8b500029',
onPrimaryOpacity8P: '#ffffff14',
onPrimaryOpacity12P: '#ffffff1f',
onPrimaryOpacity16P: '#ffffff29',
primaryContainerOpacity8P: '#ffdcbe14',
primaryContainerOpacity12P: '#ffdcbe1f',
primaryContainerOpacity16P: '#ffdcbe29',
onPrimaryContainerOpacity8P: '#2c160014',
onPrimaryContainerOpacity12P: '#2c16001f',
onPrimaryContainerOpacity16P: '#2c160029',
primaryFixedOpacity8P: '#ffdcbe14',
primaryFixedOpacity12P: '#ffdcbe1f',
primaryFixedOpacity16P: '#ffdcbe29',
onPrimaryFixedOpacity8P: '#2c160014',
onPrimaryFixedOpacity12P: '#2c16001f',
onPrimaryFixedOpacity16P: '#2c160029',
primaryFixedDimOpacity8P: '#ffb87014',
primaryFixedDimOpacity12P: '#ffb8701f',
primaryFixedDimOpacity16P: '#ffb87029',
onPrimaryFixedVariantOpacity8P: '#693c0014',
onPrimaryFixedVariantOpacity12P: '#693c001f',
onPrimaryFixedVariantOpacity16P: '#693c0029',
secondaryOpacity8P: '#725a4214',
secondaryOpacity12P: '#725a421f',
secondaryOpacity16P: '#725a4229',
onSecondaryOpacity8P: '#ffffff14',
onSecondaryOpacity12P: '#ffffff1f',
onSecondaryOpacity16P: '#ffffff29',
secondaryContainerOpacity8P: '#ffdcbe14',
secondaryContainerOpacity12P: '#ffdcbe1f',
secondaryContainerOpacity16P: '#ffdcbe29',
onSecondaryContainerOpacity8P: '#29180614',
onSecondaryContainerOpacity12P: '#2918061f',
onSecondaryContainerOpacity16P: '#29180629',
secondaryFixedOpacity8P: '#ffdcbe14',
secondaryFixedOpacity12P: '#ffdcbe1f',
secondaryFixedOpacity16P: '#ffdcbe29',
onSecondaryFixedOpacity8P: '#29180614',
onSecondaryFixedOpacity12P: '#2918061f',
onSecondaryFixedOpacity16P: '#29180629',
secondaryFixedDimOpacity8P: '#e1c1a414',
secondaryFixedDimOpacity12P: '#e1c1a41f',
secondaryFixedDimOpacity16P: '#e1c1a429',
onSecondaryFixedVariantOpacity8P: '#59422c14',
onSecondaryFixedVariantOpacity12P: '#59422c1f',
onSecondaryFixedVariantOpacity16P: '#59422c29',
tertiaryOpacity8P: '#58633a14',
tertiaryOpacity12P: '#58633a1f',
tertiaryOpacity16P: '#58633a29',
onTertiaryOpacity8P: '#ffffff14',
onTertiaryOpacity12P: '#ffffff1f',
onTertiaryOpacity16P: '#ffffff29',
tertiaryContainerOpacity8P: '#dce8b414',
tertiaryContainerOpacity12P: '#dce8b41f',
tertiaryContainerOpacity16P: '#dce8b429',
onTertiaryContainerOpacity8P: '#161e0114',
onTertiaryContainerOpacity12P: '#161e011f',
onTertiaryContainerOpacity16P: '#161e0129',
tertiaryFixedOpacity8P: '#dce8b414',
tertiaryFixedOpacity12P: '#dce8b41f',
tertiaryFixedOpacity16P: '#dce8b429',
onTertiaryFixedOpacity8P: '#161e0114',
onTertiaryFixedOpacity12P: '#161e011f',
onTertiaryFixedOpacity16P: '#161e0129',
tertiaryFixedDimOpacity8P: '#c0cc9a14',
tertiaryFixedDimOpacity12P: '#c0cc9a1f',
tertiaryFixedDimOpacity16P: '#c0cc9a29',
onTertiaryFixedVariantOpacity8P: '#414b2414',
onTertiaryFixedVariantOpacity12P: '#414b241f',
onTertiaryFixedVariantOpacity16P: '#414b2429',
errorOpacity8P: '#ba1a1a14',
errorOpacity12P: '#ba1a1a1f',
errorOpacity16P: '#ba1a1a29',
onErrorOpacity8P: '#ffffff14',
onErrorOpacity12P: '#ffffff1f',
onErrorOpacity16P: '#ffffff29',
errorContainerOpacity8P: '#ffdad614',
errorContainerOpacity12P: '#ffdad61f',
errorContainerOpacity16P: '#ffdad629',
onErrorContainerOpacity8P: '#41000214',
onErrorContainerOpacity12P: '#4100021f',
onErrorContainerOpacity16P: '#41000229',
outlineOpacity8P: '#83746814',
outlineOpacity12P: '#8374681f',
outlineOpacity16P: '#83746829',
backgroundOpacity8P: '#fffbff14',
backgroundOpacity12P: '#fffbff1f',
backgroundOpacity16P: '#fffbff29',
onBackgroundOpacity8P: '#201b1614',
onBackgroundOpacity12P: '#201b161f',
onBackgroundOpacity16P: '#201b1629',
surfaceOpacity8P: '#fff8f514',
surfaceOpacity12P: '#fff8f51f',
surfaceOpacity16P: '#fff8f529',
onSurfaceOpacity8P: '#201b1614',
onSurfaceOpacity12P: '#201b161f',
onSurfaceOpacity16P: '#201b1629',
surfaceVariantOpacity8P: '#f2dfd114',
surfaceVariantOpacity12P: '#f2dfd11f',
surfaceVariantOpacity16P: '#f2dfd129',
onSurfaceVariantOpacity8P: '#51453a14',
onSurfaceVariantOpacity12P: '#51453a1f',
onSurfaceVariantOpacity16P: '#51453a29',
inverseSurfaceOpacity8P: '#352f2b14',
inverseSurfaceOpacity12P: '#352f2b1f',
inverseSurfaceOpacity16P: '#352f2b29',
inverseOnSurfaceOpacity8P: '#faefe714',
inverseOnSurfaceOpacity12P: '#faefe71f',
inverseOnSurfaceOpacity16P: '#faefe729',
inversePrimaryOpacity8P: '#ffb87014',
inversePrimaryOpacity12P: '#ffb8701f',
inversePrimaryOpacity16P: '#ffb87029',
shadowOpacity8P: '#00000014',
shadowOpacity12P: '#0000001f',
shadowOpacity16P: '#00000029',
surfaceTintOpacity8P: '#8b500014',
surfaceTintOpacity12P: '#8b50001f',
surfaceTintOpacity16P: '#8b500029',
outlineVariantOpacity8P: '#d5c3b514',
outlineVariantOpacity12P: '#d5c3b51f',
outlineVariantOpacity16P: '#d5c3b529',
scrimOpacity8P: '#00000014',
scrimOpacity12P: '#0000001f',
scrimOpacity16P: '#00000029',
surfaceContainerHighestOpacity8P: '#ebe0d914',
surfaceContainerHighestOpacity12P: '#ebe0d91f',
surfaceContainerHighestOpacity16P: '#ebe0d929',
surfaceContainerHighOpacity8P: '#f1e6df14',
surfaceContainerHighOpacity12P: '#f1e6df1f',
surfaceContainerHighOpacity16P: '#f1e6df29',
surfaceContainerOpacity8P: '#f7ece414',
surfaceContainerOpacity12P: '#f7ece41f',
surfaceContainerOpacity16P: '#f7ece429',
surfaceContainerLowOpacity8P: '#fdf2ea14',
surfaceContainerLowOpacity12P: '#fdf2ea1f',
surfaceContainerLowOpacity16P: '#fdf2ea29',
surfaceContainerLowestOpacity8P: '#ffffff14',
surfaceContainerLowestOpacity12P: '#ffffff1f',
surfaceContainerLowestOpacity16P: '#ffffff29',
surfaceBrightOpacity8P: '#fff8f514',
surfaceBrightOpacity12P: '#fff8f51f',
surfaceBrightOpacity16P: '#fff8f529',
surfaceDimOpacity8P: '#e3d8d114',
surfaceDimOpacity12P: '#e3d8d11f',
surfaceDimOpacity16P: '#e3d8d129'
};
export const dark_orange = {
primary: '#ffb870',
onPrimary: '#4a2800',
primaryContainer: '#693c00',
onPrimaryContainer: '#ffdcbe',
primaryFixed: '#ffdcbe',
onPrimaryFixed: '#2c1600',
primaryFixedDim: '#ffb870',
onPrimaryFixedVariant: '#693c00',
secondary: '#e1c1a4',
onSecondary: '#402c18',
secondaryContainer: '#59422c',
onSecondaryContainer: '#ffdcbe',
secondaryFixed: '#ffdcbe',
onSecondaryFixed: '#291806',
secondaryFixedDim: '#e1c1a4',
onSecondaryFixedVariant: '#59422c',
tertiary: '#c0cc9a',
onTertiary: '#2b3410',
tertiaryContainer: '#414b24',
onTertiaryContainer: '#dce8b4',
tertiaryFixed: '#dce8b4',
onTertiaryFixed: '#161e01',
tertiaryFixedDim: '#c0cc9a',
onTertiaryFixedVariant: '#414b24',
error: '#ffb4ab',
onError: '#690005',
errorContainer: '#93000a',
onErrorContainer: '#ffdad6',
outline: '#9d8e81',
background: '#201b16',
background2: '#f2f4f7',
onBackground: '#ebe0d9',
surface: '#17120e',
onSurface: '#cfc5be',
surfaceVariant: '#51453a',
onSurfaceVariant: '#d5c3b5',
inverseSurface: '#ebe0d9',
inverseOnSurface: '#201b16',
inversePrimary: '#8b5000',
shadow: '#000000',
surfaceTint: '#ffb870',
outlineVariant: '#51453a',
scrim: '#000000',
surfaceContainerHighest: '#3a342f',
surfaceContainerHigh: '#2e2924',
surfaceContainer: '#241f1a',
surfaceContainerLow: '#201b16',
surfaceContainerLowest: '#120d0a',
surfaceBright: '#3e3833',
surfaceDim: '#17120e',
primaryOpacity8P: '#ffb87014',
primaryOpacity12P: '#ffb8701f',
primaryOpacity16P: '#ffb87029',
onPrimaryOpacity8P: '#4a280014',
onPrimaryOpacity12P: '#4a28001f',
onPrimaryOpacity16P: '#4a280029',
primaryContainerOpacity8P: '#693c0014',
primaryContainerOpacity12P: '#693c001f',
primaryContainerOpacity16P: '#693c0029',
onPrimaryContainerOpacity8P: '#ffdcbe14',
onPrimaryContainerOpacity12P: '#ffdcbe1f',
onPrimaryContainerOpacity16P: '#ffdcbe29',
primaryFixedOpacity8P: '#ffdcbe14',
primaryFixedOpacity12P: '#ffdcbe1f',
primaryFixedOpacity16P: '#ffdcbe29',
onPrimaryFixedOpacity8P: '#2c160014',
onPrimaryFixedOpacity12P: '#2c16001f',
onPrimaryFixedOpacity16P: '#2c160029',
primaryFixedDimOpacity8P: '#ffb87014',
primaryFixedDimOpacity12P: '#ffb8701f',
primaryFixedDimOpacity16P: '#ffb87029',
onPrimaryFixedVariantOpacity8P: '#693c0014',
onPrimaryFixedVariantOpacity12P: '#693c001f',
onPrimaryFixedVariantOpacity16P: '#693c0029',
secondaryOpacity8P: '#e1c1a414',
secondaryOpacity12P: '#e1c1a41f',
secondaryOpacity16P: '#e1c1a429',
onSecondaryOpacity8P: '#402c1814',
onSecondaryOpacity12P: '#402c181f',
onSecondaryOpacity16P: '#402c1829',
secondaryContainerOpacity8P: '#59422c14',
secondaryContainerOpacity12P: '#59422c1f',
secondaryContainerOpacity16P: '#59422c29',
onSecondaryContainerOpacity8P: '#ffdcbe14',
onSecondaryContainerOpacity12P: '#ffdcbe1f',
onSecondaryContainerOpacity16P: '#ffdcbe29',
secondaryFixedOpacity8P: '#ffdcbe14',
secondaryFixedOpacity12P: '#ffdcbe1f',
secondaryFixedOpacity16P: '#ffdcbe29',
onSecondaryFixedOpacity8P: '#29180614',
onSecondaryFixedOpacity12P: '#2918061f',
onSecondaryFixedOpacity16P: '#29180629',
secondaryFixedDimOpacity8P: '#e1c1a414',
secondaryFixedDimOpacity12P: '#e1c1a41f',
secondaryFixedDimOpacity16P: '#e1c1a429',
onSecondaryFixedVariantOpacity8P: '#59422c14',
onSecondaryFixedVariantOpacity12P: '#59422c1f',
onSecondaryFixedVariantOpacity16P: '#59422c29',
tertiaryOpacity8P: '#c0cc9a14',
tertiaryOpacity12P: '#c0cc9a1f',
tertiaryOpacity16P: '#c0cc9a29',
onTertiaryOpacity8P: '#2b341014',
onTertiaryOpacity12P: '#2b34101f',
onTertiaryOpacity16P: '#2b341029',
tertiaryContainerOpacity8P: '#414b2414',
tertiaryContainerOpacity12P: '#414b241f',
tertiaryContainerOpacity16P: '#414b2429',
onTertiaryContainerOpacity8P: '#dce8b414',
onTertiaryContainerOpacity12P: '#dce8b41f',
onTertiaryContainerOpacity16P: '#dce8b429',
tertiaryFixedOpacity8P: '#dce8b414',
tertiaryFixedOpacity12P: '#dce8b41f',
tertiaryFixedOpacity16P: '#dce8b429',
onTertiaryFixedOpacity8P: '#161e0114',
onTertiaryFixedOpacity12P: '#161e011f',
onTertiaryFixedOpacity16P: '#161e0129',
tertiaryFixedDimOpacity8P: '#c0cc9a14',
tertiaryFixedDimOpacity12P: '#c0cc9a1f',
tertiaryFixedDimOpacity16P: '#c0cc9a29',
onTertiaryFixedVariantOpacity8P: '#414b2414',
onTertiaryFixedVariantOpacity12P: '#414b241f',
onTertiaryFixedVariantOpacity16P: '#414b2429',
errorOpacity8P: '#ffb4ab14',
errorOpacity12P: '#ffb4ab1f',
errorOpacity16P: '#ffb4ab29',
onErrorOpacity8P: '#69000514',
onErrorOpacity12P: '#6900051f',
onErrorOpacity16P: '#69000529',
errorContainerOpacity8P: '#93000a14',
errorContainerOpacity12P: '#93000a1f',
errorContainerOpacity16P: '#93000a29',
onErrorContainerOpacity8P: '#ffdad614',
onErrorContainerOpacity12P: '#ffdad61f',
onErrorContainerOpacity16P: '#ffdad629',
outlineOpacity8P: '#9d8e8114',
outlineOpacity12P: '#9d8e811f',
outlineOpacity16P: '#9d8e8129',
backgroundOpacity8P: '#201b1614',
backgroundOpacity12P: '#201b161f',
backgroundOpacity16P: '#201b1629',
onBackgroundOpacity8P: '#ebe0d914',
onBackgroundOpacity12P: '#ebe0d91f',
onBackgroundOpacity16P: '#ebe0d929',
surfaceOpacity8P: '#17120e14',
surfaceOpacity12P: '#17120e1f',
surfaceOpacity16P: '#17120e29',
onSurfaceOpacity8P: '#cfc5be14',
onSurfaceOpacity12P: '#cfc5be1f',
onSurfaceOpacity16P: '#cfc5be29',
surfaceVariantOpacity8P: '#51453a14',
surfaceVariantOpacity12P: '#51453a1f',
surfaceVariantOpacity16P: '#51453a29',
onSurfaceVariantOpacity8P: '#d5c3b514',
onSurfaceVariantOpacity12P: '#d5c3b51f',
onSurfaceVariantOpacity16P: '#d5c3b529',
inverseSurfaceOpacity8P: '#ebe0d914',
inverseSurfaceOpacity12P: '#ebe0d91f',
inverseSurfaceOpacity16P: '#ebe0d929',
inverseOnSurfaceOpacity8P: '#201b1614',
inverseOnSurfaceOpacity12P: '#201b161f',
inverseOnSurfaceOpacity16P: '#201b1629',
inversePrimaryOpacity8P: '#8b500014',
inversePrimaryOpacity12P: '#8b50001f',
inversePrimaryOpacity16P: '#8b500029',
shadowOpacity8P: '#00000014',
shadowOpacity12P: '#0000001f',
shadowOpacity16P: '#00000029',
surfaceTintOpacity8P: '#ffb87014',
surfaceTintOpacity12P: '#ffb8701f',
surfaceTintOpacity16P: '#ffb87029',
outlineVariantOpacity8P: '#51453a14',
outlineVariantOpacity12P: '#51453a1f',
outlineVariantOpacity16P: '#51453a29',
scrimOpacity8P: '#00000014',
scrimOpacity12P: '#0000001f',
scrimOpacity16P: '#00000029',
surfaceContainerHighestOpacity8P: '#3a342f14',
surfaceContainerHighestOpacity12P: '#3a342f1f',
surfaceContainerHighestOpacity16P: '#3a342f29',
surfaceContainerHighOpacity8P: '#2e292414',
surfaceContainerHighOpacity12P: '#2e29241f',
surfaceContainerHighOpacity16P: '#2e292429',
surfaceContainerOpacity8P: '#241f1a14',
surfaceContainerOpacity12P: '#241f1a1f',
surfaceContainerOpacity16P: '#241f1a29',
surfaceContainerLowOpacity8P: '#201b1614',
surfaceContainerLowOpacity12P: '#201b161f',
surfaceContainerLowOpacity16P: '#201b1629',
surfaceContainerLowestOpacity8P: '#120d0a14',
surfaceContainerLowestOpacity12P: '#120d0a1f',
surfaceContainerLowestOpacity16P: '#120d0a29',
surfaceBrightOpacity8P: '#3e383314',
surfaceBrightOpacity12P: '#3e38331f',
surfaceBrightOpacity16P: '#3e383329',
surfaceDimOpacity8P: '#17120e14',
surfaceDimOpacity12P: '#17120e1f',
surfaceDimOpacity16P: '#17120e29'
};
