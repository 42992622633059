import React from "react";
import Slider from '../../components/Slider';
import Hero from '../../components/Hero';
// import Analytics from '../../components/Analytics';
// import Cards from '../../components/Cards';
// import Newsletter from '../../components/Newsletter';
import { ToastContainer } from 'react-toastify';
import Navbar from '../../components/Navbar';
import { HomeAppBar, Footer, Screen, CenteredLayout, ArrowCarousel } from "../../components";
import { Image, XStack } from "tamagui";
import { useDimensions } from "src/hooks";
import { useBreakpoint } from "src/elements";


function HomeCarousel(){
  const dimension = useDimensions();    
  // subtract dimension and twice of centerlayout margin as per breakpoint
  const widthByBreakpoint = useBreakpoint({
    gtXXs: dimension.width - 2*16,
    gtSm: dimension.width - 2*56,
    gtMd: dimension.width - 2*88,
    gtTablet: dimension.width - 2*88,
    gtLg: dimension.width - 2*176,
    gtXXl: 'auto'
  });
 
  const width = Number(widthByBreakpoint.gt) || Number(widthByBreakpoint.lt);

  
  return (
      <ArrowCarousel arrowVariant='circular'>
        <XStack flex={1} gap="$2">
          <Image
            source={{
              uri: 'https://picsum.photos/200/300',
              width: width,
              height: 500,
            }}
          />
          <Image
            source={{
              uri: 'https://picsum.photos/200/300',
              width: width,
              height: 500,
            }}
          />
          <Image
            source={{
              uri: 'https://picsum.photos/200/300',
              width: width,
              height: 500,
            }}
          />                    
        </XStack>
      </ArrowCarousel>            
  )
}


export function HomeScreen(){
  
  const [token, setToken] = React.useState('');

  return (
    <Screen
        appBar={<HomeAppBar />}
        context={{ name: 'HomeScreen' }}
        preset="fixed"
        footer={<Footer />}
    >
      <CenteredLayout marginTop={"$8"} marginBottom={"$3"}>
        <HomeCarousel />
      </CenteredLayout>      
    </Screen>
  )
}


// export function HomeScreen(){
  
//   const [token, setToken] = React.useState('');
  
//   return (
//     <div className='w-full'>
//       <ToastContainer/>
//       {/* <Navbar setToken={setToken} Token={token}/> */}
//       <Navbar />  
//       <Slider />
//       <Hero token={token} />
//       <Footer/>
//       {/* <Analytics /> */}
//       {/* <Newsletter /> */}
//       {/* <Cards />     */}
//     </div>
//   )
// }