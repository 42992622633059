//custom colors common to all themes
//it will render separate hexcode for light and dark modes
export const custom_light = {
    warning: '#8d4f00ff',
    onWarning: '#ffffffff',
    warningContainer: '#ffdcc0ff',
    onWarningContainer: '#2d1600ff',
    success: '#006d3cff',
    onSuccess: '#ffffffff',
    successContainer: '#70fda7ff',
    onSuccessContainer: '#00210eff',
    teal: '#006a60ff',
    onTeal: '#ffffffff',
    tealContainer: '#74f8e5ff',
    onTealContainer: '#00201cff',
    cyan: '#006876ff',
    onCyan: '#ffffffff',
    cyanContainer: '#a1efffff',
    onCyanContainer: '#001f25ff',
    lightblue: '#006493ff',
    onLightblue: '#ffffffff',
    lightblueContainer: '#cae6ffff',
    onLightblueContainer: '#001e30ff',
    indigo: '#4355b9ff',
    onIndigo: '#ffffffff',
    indigoContainer: '#dee0ffff',
    onIndigoContainer: '#00105cff',
    deeppurple: '#6f43c0ff',
    onDeeppurple: '#ffffffff',
    deeppurpleContainer: '#ebddffff',
    onDeeppurpleContainer: '#250059ff',
    purple: '#9a25aeff',
    onPurple: '#ffffffff',
    purpleContainer: '#ffd6feff',
    onPurpleContainer: '#35003fff',
    pink: '#b2008aff',
    onPink: '#ffffffff',
    pinkContainer: '#ffd8ebff',
    onPinkContainer: '#3b002cff',
    rose: '#bd0143ff',
    onRose: '#ffffffff',
    roseContainer: '#ffd9dcff',
    onRoseContainer: '#400011ff',
    yellow: '#695f00ff',
    onYellow: '#ffffffff',
    yellowContainer: '#f9e534ff',
    onYellowContainer: '#201c00ff',
    amber: '#785900ff',
    onAmber: '#ffffffff',
    amberContainer: '#ffdf9eff',
    onAmberContainer: '#261a00ff',
    orange: '#8b5000ff',
    onOrange: '#ffffffff',
    orangeContainer: '#ffdcbeff',
    onOrangeContainer: '#2c1600ff',
    deeporange: '#b02f00ff',
    onDeeporange: '#ffffffff',
    deeporangeContainer: '#ffdbd1ff',
    onDeeporangeContainer: '#3b0900ff',
    lime: '#5b6300ff',
    onLime: '#ffffffff',
    limeContainer: '#dded49ff',
    onLimeContainer: '#1a1d00ff',
    lightgreen: '#3e6a00ff',
    onLightgreen: '#ffffffff',
    lightgreenContainer: '#b9f474ff',
    onLightgreenContainer: '#0f2000ff',
    green: '#006e1cff',
    onGreen: '#ffffffff',
    greenContainer: '#94f990ff',
    onGreenContainer: '#002204ff'
   };
   
   
   export const custom_dark = {
    warning: '#ffb875ff',
    onWarning: '#4b2800ff',
    warningContainer: '#272019',
    onWarningContainer: '#ffdcc0ff',
    success: '#51df8eff',
    onSuccess: '#00391dff',
    successContainer: '#00522cff',
    onSuccessContainer: '#70fda7ff',
    teal: '#53dbc9ff',
    onTeal: '#003731ff',
    tealContainer: '#005048ff',
    onTealContainer: '#74f8e5ff',
    cyan: '#44d8f1ff',
    onCyan: '#00363eff',
    cyanContainer: '#004e59ff',
    onCyanContainer: '#a1efffff',
    lightblue: '#8dcdffff',
    onLightblue: '#00344fff',
    lightblueContainer: '#004b70ff',
    onLightblueContainer: '#cae6ffff',
    indigo: '#bac3ffff',
    onIndigo: '#08218aff',
    indigoContainer: '#293ca0ff',
    onIndigoContainer: '#dee0ffff',
    deeppurple: '#d3bbffff',
    onDeeppurple: '#3f008dff',
    deeppurpleContainer: '#5727a6ff',
    onDeeppurpleContainer: '#ebddffff',
    purple: '#f9abffff',
    onPurple: '#570066ff',
    purpleContainer: '#7b008fff',
    onPurpleContainer: '#ffd6feff',
    pink: '#ffaedcff',
    onPink: '#600049ff',
    pinkContainer: '#880069ff',
    onPinkContainer: '#ffd8ebff',
    rose: '#ffb2baff',
    onRose: '#670020ff',
    roseContainer: '#910031ff',
    onRoseContainer: '#ffd9dcff',
    yellow: '#dbc90aff',
    onYellow: '#363100ff',
    yellowContainer: '#4f4800ff',
    onYellowContainer: '#f9e534ff',
    amber: '#fabd00ff',
    onAmber: '#3f2e00ff',
    amberContainer: '#5b4300ff',
    onAmberContainer: '#ffdf9eff',
    orange: '#ffb870ff',
    onOrange: '#4a2800ff',
    orangeContainer: '#693c00ff',
    onOrangeContainer: '#ffdcbeff',
    deeporange: '#ffb5a0ff',
    onDeeporange: '#5f1500ff',
    deeporangeContainer: '#862200ff',
    onDeeporangeContainer: '#ffdbd1ff',
    lime: '#c1d02cff',
    onLime: '#2f3300ff',
    limeContainer: '#444b00ff',
    onLimeContainer: '#dded49ff',
    lightgreen: '#9ed75bff',
    onLightgreen: '#1e3700ff',
    lightgreenContainer: '#2e4f00ff',
    onLightgreenContainer: '#b9f474ff',
    green: '#78dc77ff',
    onGreen: '#00390aff',
    greenContainer: '#005313ff',
    onGreenContainer: '#94f990ff'
   };
   
   
   
   
   
   