//common colors to all theme
export const common = {
    white: '#ffffff',
    black: '#000000',
    white50: '#ffffff80',
    whiteOpacity80P: '#ffffff80',
    whiteOpacity30P: '#ffffff30',
    whiteOpacity70P: '#ffffff70',
    blackOpacity60P: '#00000060',
    blackOpacity50P: '#00000050',
    blackOpacity80P: '#00000080',
    blackOpacity20P: '#00000020'
};   