// import { useFontByBreakpoint } from "./useFontByBreakpoint";


export function useFontVariantMapping(variant) {
    const mapVariantToFont = (variant) => {
      let font = '$body';
  
      switch (variant) {
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
          font = '$heading';
          break;
        case 'subtitle1':
        case 'subtitle2':
          font = '$subtitle';
          break;
        case 'body1':
        case 'body2':
          font = '$body';
          break;
        case 'caption':
          font = '$caption';
          break;
        case 'overline':
          font = '$overline';
          break;
        default:
          font = '$body';
      }
  
      return font;
    };
  
    const font = mapVariantToFont(variant);
    // return useFontByBreakpoint(font);
    return font;    
  }