import { ContentLoader } from '../ui/loaders';
import {
  Screen as ElementsScreen,
  ScreenProps as ElementsScreenProps,
  Text
} from '../../elements';


export type ScreenProps = ElementsScreenProps & {
  isLoading?: boolean;
  offline?: boolean;
  isPageNotFound?: boolean;
  context: {
    name: string;
  } & Record<string, any>;
};

export function Screen({
  context,
  children,
  offline = false,
  isLoading,
  isPageNotFound = false,
  footer,
  ...props
}: ScreenProps) {
  
  return offline ? (
    <ElementsScreen
      backgroundColor={'$background2'}
      {...props}
      footer={isLoading ? <ContentLoader /> : footer}>
      {/* <PageNotFound isPageNotFound={isPageNotFound}>{children}</PageNotFound> */}
      {children}
    </ElementsScreen>
  ) : (
    <ElementsScreen
      backgroundColor={'$background2'}
      preset="fixed"
      {...props}
      footer={isLoading ? <ContentLoader /> : footer}>
      {/* <NoNetwork variant={NoNetworkVariants.CARD}>
        <PageNotFound isPageNotFound={isPageNotFound}>{children}</PageNotFound>
      </NoNetwork> */}
      {children}
    </ElementsScreen>
  );
}