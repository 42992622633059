import { SpaceTokens, useBreakpoint } from '../../elements';


export function useGridSpacing() {
 const spacing = useBreakpoint({
   sm: '$2' as SpaceTokens,
   gtSm: '$3' as SpaceTokens
 });


 return spacing.lt || spacing.gt || ('$2' as SpaceTokens);
}




