import { useLockBodyScroll, useThemeColorTokens } from '../hooks';
import { AnimatePresence, Stack, StackProps, XStack } from 'tamagui';
import React, { useEffect, useState } from 'react';
import { Icon } from 'src/components';
import { X } from '@tamagui/lucide-icons';

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  
    return windowDimensions;
  }

export type DrawerPops = StackProps & {
 orientation?: 'right' | 'left';
 onClose: () => void;
 drawerWidth?: number;
 open: boolean;
 canCloseOnBackgroundPress?: boolean;
};

export const Drawer = React.memo(function Drawer({
 open,
 drawerWidth: drawerWidthProp,
 orientation = 'left',
 children,
 onClose
}: DrawerPops) {
    const { surfaceContainerLowest } = useThemeColorTokens();            
 const drawerWidth = drawerWidthProp || 328;

 useLockBodyScroll(open);
 const dimensions = useWindowDimensions();
 const originX = orientation == 'right' ? dimensions.width - drawerWidth : 0;


 return (
   <XStack
     pointerEvents="auto"
     width={'100vw'}
     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
     // @ts-ignore
     position={'fixed'}
     zIndex={2000}>
     <AnimatePresence>
       {open && (
         <Stack
           key={'foreground'}
           height={'100vh'}
           pointerEvents="auto"
           zIndex={202}
           // This is necessary to window back scroll behind drawer
           onPress={(e) => {
             // eslint-disable-next-line @typescript-eslint/ban-ts-comment
             // @ts-ignore
             if (e.target.type != 'file') {
               e.preventDefault();
             }
           }}
           x={originX}
           enterStyle={{ x: orientation == 'right' ? originX : -drawerWidth }}
           exitStyle={{ x: orientation == 'right' ? dimensions.width : -drawerWidth }}
           animation={'fast'}
           width={drawerWidth}
           backgroundColor={surfaceContainerLowest}>
           {children}
         </Stack>
       )}
     </AnimatePresence>
     {/* <AnimatePresence>
       {open && (
         <Stack
           key={'background'}
           position="absolute"
           left={0}
           width={'100%'}
           height={'100%'}
           opacity={1}
           enterStyle={{ opacity: 0 }}
           exitStyle={{ opacity: 0 }}
           animation={'fast'}
           pointerEvents="auto"
           flex={1}
           backgroundColor={'$onSurfaceOpacity16P'}
           onPress={onClose}
         />
       )}
     </AnimatePresence> */}
   </XStack>
 );
});





