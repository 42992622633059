import { useCancelBookingMutation, useGetAllBookingsQuery } from "../../services/api";
import { Accordion, CenteredLayout, Footer, HomeAppBar, Screen } from "../../components";
import { useCallback, useMemo } from "react";
import { Typography, useThemeColorTokens } from "src/elements";
import { Avatar, Button, Card, Image, ScrollView, Separator, Stack, Text, XStack, YStack, H2, Paragraph, Spinner } from "tamagui";
import { isNilOrEmpty } from "../../utils/app";
import dayjs from "dayjs";
import { Link } from "react-router-dom";



function BookingItem({ bookingData }: { bookingData: any }){
    const {outlineOpacity16P, primary, inverseOnSurface } = useThemeColorTokens();
    const [cancelBooking, {isLoading}] = useCancelBookingMutation();

    const booking = useMemo(()=>{
        if(!isNilOrEmpty(bookingData)){
            const { fullName, bookingDate, bookingStatus, ancestralAddress, residentialAddress, id, hotelName, hotelContactNumber, vehicleOwnerName, vehicleOwnerContactNumber } = bookingData
            // const { numberOfYatri, numberOfPoojaDays } = bookingData.extraBookingInfo
            const bookingStatusText = bookingStatus.split('_').join(" ");
            return {
                bookingId: id,
                yatriName: fullName,
                bookingDate: dayjs(bookingDate).format("DD MMM'YY hh:mm A"),
                // numberOfYatri,
                // numberOfPoojaDays,
                rAdd: Object.values(residentialAddress || {}).join(" "),
                ancAdd: Object.values(ancestralAddress || {}).join(" "), 
                bookingStatus: bookingStatus.split('_').join(" "),
                meta: [bookingStatusText, `Booking Id - ${id}`].join(' · '),
                hotelName,
                hotelContactNumber,
                vehicleOwnerName,
                vehicleOwnerContactNumber
            }
        }
    }, [bookingData])

    async function onCancelBookingPress(bookingId){
        await cancelBooking(bookingId);
    }

    return (
        <Card elevate size="$2" bordered        
        // width={342}
        overflow="hidden"
        paddingHorizontal={"$3"}
        paddingVertical={"$4"}
        >
            <YStack gap="$4">
                <XStack justifyContent="space-between" alignItems="center">
                    <YStack gap="$2">
                        <Typography variant="h5">{booking.yatriName}</Typography>
                        <Typography variant="body2" textTransform="capitalize">{booking.meta}</Typography>
                    </YStack>                    
                    {/* <Link to={`/bookings/detail?bookingId=${booking.bookingId}`}>
                        <Button padding={20} backgroundColor={primary} color={'white'} children={'View & Manage Booking'}/>                    
                    </Link> */}
                    {/* <Button padding={20} backgroundColor={primary} color={'white'} children={'Cancel Booking'} onPress={()=>onCancelBookingPress(booking.bookingId)} />                     */}
                    {!booking.bookingStatus.includes("CANCELLED") && 
                        <Button padding={20} backgroundColor={primary} color={'white'} onPress={()=>onCancelBookingPress(booking.bookingId)}>
                            <Typography variant="body2" color={inverseOnSurface}>Cancel Booking</Typography>
                            {isLoading &&
                                <Spinner size="small" color={inverseOnSurface} />
                            }                        
                        </Button>
                    }
                </XStack>
                <Separator
                    backgroundColor={outlineOpacity16P}
                    borderColor={outlineOpacity16P}
                    height={1}
                />
                <YStack gap={"$2"}>
                    <Typography variant="body2" textTransform="capitalize">{`From: ${booking.bookingDate}`}</Typography>
                    <YStack gap={"$1"}>
                        <Typography variant="subtitle2" textTransform="capitalize">Resedential Address:</Typography>
                        <Typography variant="body2" textTransform="capitalize">{`${booking.rAdd}`}</Typography>                
                    </YStack>
                    <YStack gap={"$1"}>
                        <Typography variant="subtitle2" textTransform="capitalize">Ancestral Address:</Typography>                                
                        <Typography variant="body2" textTransform="capitalize">{`${booking.ancAdd}`}</Typography>                                
                    </YStack>          
                    {(!!booking.hotelName || !!booking.hotelContactNumber) && 
                        <YStack gap={"$1"}>
                            <Typography variant="subtitle2" textTransform="capitalize">Hotel Info:</Typography>                                
                            {!!booking.hotelName && 
                                <Typography variant="body2" textTransform="capitalize">{`${booking.hotelName}`}</Typography>            
                            }
                            {!!booking.hotelContactNumber && 
                                <Typography variant="body2" textTransform="capitalize">{`${booking.hotelContactNumber}`}</Typography>                            
                            }
                        </YStack>
                    }
                    {(!!booking.vehicleOwnerName || !!booking.vehicleOwnerContactNumber) && 
                        <YStack gap={"$1"}>
                            <Typography variant="subtitle2" textTransform="capitalize">Vehicle Info:</Typography>  
                            {!!booking.vehicleOwnerName &&
                                <Typography variant="body2" textTransform="capitalize">{`${booking.vehicleOwnerName}`}</Typography>                                
                            }
                            {!!booking.vehicleOwnerContactNumber &&
                                <Typography variant="body2" textTransform="capitalize">{`${booking.vehicleOwnerContactNumber}`}</Typography>                                                        
                            }
                        </YStack>
                    }
                </YStack>
            </YStack>
        </Card>        
    )
}

function BookingList(){
    const { data: bookings, error, isLoading, refetch } = useGetAllBookingsQuery({});     
    const renderItem = useCallback((item, index) => <BookingItem key={index} bookingData={item} />, [bookings])
    return (
        (bookings || []).map(renderItem)
    )
}

export function BookingsScreen(){
    const { data: bookings, error, isLoading, refetch } = useGetAllBookingsQuery({}); 
    console.log("BookingsScreen - bookings ::", bookings);
    return (
        <Screen
            appBar={<HomeAppBar />}
            context={{ name: 'BookingsScreen' }}
            preset="fixed"
            footer={<Footer />}
        >
            <CenteredLayout marginTop={"$10"} marginBottom={"$3"} gap={"$4"}>
                <BookingList />
            </CenteredLayout>
        </Screen>
    );
}