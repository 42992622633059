import { ReactElement, ReactNode } from "react";
import { Stack, StackProps } from "tamagui";

export function ChildrensWithSeparator({
    children,
    separator,
    ...props
   }: StackProps & { separator: ReactElement | ReactNode }) {
    const childrens = Array.isArray(children) ? children.filter((child) => !!child) : [children];
    return (
      <Stack {...props}>
        {childrens.map((child, index) => {
          return (
            child && (
              <>
                {child}
                {index !== childrens.length - 1 && separator}
              </>
            )
          );
        })}
      </Stack>
    );
   }
   
   
   
   