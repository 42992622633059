import { Home as HomeIcon } from "@tamagui/lucide-icons";
import { Link, useLocation } from "react-router-dom";
import { Container, Typography, XStack } from "../../elements";
import { Icon } from "../ui";
import { ReactNode } from "react";

export function Home() {   
    return (
        <MoreCTAWithLink
        screenName={'HomeScreen'}
        icon={<HomeIcon />}
        title={'Home'}
        url={'/dashboard'}
        />
    );
}
   
type MoreCTAProps = {
    icon: ReactNode;
    title: string;
    onPress?: () => void;
    mixPanelEvent?: string;
    error?: boolean;
    screenName?: string;
    badge?: number | string;
    url?: string;
    target?: '_self' | '_blank';
   };
   
   
   export function MoreCTA({
    icon,
    title,
    onPress,
    mixPanelEvent,
    error = false,
    screenName,
    badge
   }: MoreCTAProps) {
    // const currentScreenName = useNavigationState((state) => state.routes[state.index].name);
    const location = useLocation();
    // Extract the current pathname
    const currentScreenName = location.pathname;    
   
    function onButtonPress() {
        onPress?.();
    }
   
   
    return (
      <Container
        cursor={'pointer'}
        hoverStyle={{
          backgroundColor: '$surfaceContainerLow',
        //   borderRadius: Platform.select({ native: 0 })
        }}
        pressStyle={{
          backgroundColor: '$surfaceContainerLow',
        //   borderRadius: Platform.select({ native: 0 })
        }}
        borderColor="$outlineVariant"
        width={'100%'}
        padding="$2"
        container="lowest"
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
        backgroundColor={currentScreenName === screenName ? '$primaryOpacity8P' : 'transparent'}
        onPress={() => { setTimeout(onButtonPress, 250) }}>
        <XStack gap="$1" alignItems="center" flex={1}>
          <Icon
            icon={icon}
            size={'$3'}
            color={error ? '$error' : '$primary'}
          />
          <Typography color={error ? '$error' : '$onSurface'} variant={'subtitle1'}>
            {title}
          </Typography>
        </XStack>
        {/* {!!badge && (
          <Chip
            backgroundColor={'$errorContainer'}
            size={'sm'}
            label={badge.toString()}
            labelProps={{ color: '$onErrorContainer' }}
          />
        )} */}
      </Container>
    );
   }
   

export function MoreCTAWithLink(props: MoreCTAProps) {
 return props.url ? (
   <Link to={props.url} target={props.target || '_self'} style={{ display: 'flex' }}>
     <MoreCTA {...props} />
   </Link>
 ) : (
   <MoreCTA {...props} />
 );
}
