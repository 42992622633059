import { ChevronLeft, ChevronRight } from "@tamagui/lucide-icons";
import { ReactNode, useRef, useState } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';
import React from 'react';
import { useDimensions } from "../../../hooks";
import { Stack, StackProps, useMedia, XStack } from "tamagui";
import { Surface, useThemeColorTokens } from "../../../elements";
import { IconButton } from "../button";

type ArrowVariantType = 'circular' | 'square';

interface positionProps {
 top?: string | number;
 left?: string | number;
 bottom?: string | number;
 right?: string | number;
}

function ScrollArrow({
 variant,
 icon,
 addOpacity,
 position,
 onPress
}: {
 variant: ArrowVariantType;
 icon: ReactNode;
 addOpacity: boolean;
 position: positionProps;
 onPress: () => void;
}) {
  const { surfaceContainerHighest, onSurfaceVariant } = useThemeColorTokens();

 switch (variant) {
   case 'circular':
     return (
       <Stack onPress={onPress} position="absolute" zIndex={1} {...position}>
         <Surface
           justifyContent="center"
           alignItems="center"
           display="flex"
           shadow="md"
           background={surfaceContainerHighest}
           overflow="hidden"
           borderRadius={50}
           opacity={addOpacity ? 0.5 : 1}>
           <IconButton icon={icon} variant="text" size="md" color={onSurfaceVariant} />
         </Surface>
       </Stack>
     );
   case 'square':
     return (
       <TouchableOpacity onPress={onPress}>
         <Surface
           shadow="sm"
           justifyContent="center"
           alignItems="center"
           display="flex"
           borderRadius="$1">
           <IconButton icon={icon} variant="text" size="md" />
         </Surface>
       </TouchableOpacity>
     );
   default:
     return (
       <TouchableOpacity onPress={onPress}>
         <Surface
           shadow="sm"
           justifyContent="center"
           alignItems="center"
           display="flex"
           borderRadius="$1">
           <IconButton icon={icon} variant="text" size="md" />
         </Surface>
       </TouchableOpacity>
     );
 }
}


export function ArrowCarousel({
 arrowVariant,
 children,
 ...props
}: { arrowVariant: ArrowVariantType } & StackProps) {
 const carouselRef = useRef<ScrollView>(null);


 const dimension = useDimensions();
 const media = useMedia();


 const [containerWidth, setContainerWidth] = useState(dimension.width);
 const [contentOffset, setContentOffset] = useState(0);
 const [elementContentSize, setElementContentSize] = useState({
   width: 0,
   height: 0
 });


 const [isAtStart, setIsAtStart] = useState(true);
 const [isAtEnd, setIsAtEnd] = useState(false);


 function handleScroll(event) {
   const { contentOffset } = event.nativeEvent;
   const maxContainerOffset = Math.max(0, elementContentSize.width - containerWidth);


   setContentOffset(contentOffset.x);
   setIsAtStart(contentOffset.x <= 0);
   setIsAtEnd(contentOffset.x >= maxContainerOffset);
 }


 function handleContainerLayout(event) {
   const { width } = event.nativeEvent.layout;
   setContainerWidth(width);
 }


 function handleContentLayout(contentWidth, contentHeight) {
   setElementContentSize({ width: contentWidth, height: contentHeight });
 }


 function scrollToNext() {
   const nextPosition = contentOffset + containerWidth;
   const maxOffset = elementContentSize.width - containerWidth;


   if (nextPosition > maxOffset) {
     carouselRef.current?.scrollToEnd({ animated: true });
   } else {
     carouselRef.current?.scrollTo({ x: nextPosition, animated: true });
   }
 }


 function scrollToPrev() {
   const scrollPosition = Math.max(contentOffset - containerWidth, 0);
   carouselRef.current?.scrollTo({ x: scrollPosition, animated: true });
 }


 // const debouncedScrollToNext = useDebounce(scrollToNext, 100);
 // const debouncedScrollToPrev = useDebounce(scrollToPrev, 100);


 return (
   <XStack justifyContent="center" alignItems="center" onLayout={handleContainerLayout} {...props}>
     {media.gtMd && elementContentSize.width > containerWidth && (
       <ScrollArrow
         variant={arrowVariant}
         icon={<ChevronLeft />}
         addOpacity={isAtStart}
         position={{ top: '45%', left: 0 }}
         onPress={scrollToPrev}
       />
     )}
     <ScrollView
       ref={carouselRef}
       horizontal
       showsHorizontalScrollIndicator={false}
       onScroll={handleScroll}
       onContentSizeChange={handleContentLayout}
       scrollEventThrottle={200}
       decelerationRate="normal"
       contentContainerStyle={{ flexGrow: 1 }}>
       {children}
     </ScrollView>
     {media.gtMd && elementContentSize.width > containerWidth && (
       <ScrollArrow
         variant={arrowVariant}
         icon={<ChevronRight />}
         addOpacity={isAtEnd}
         position={{ top: '45%', right: 0 }}
         onPress={scrollToNext}
       />
     )}
   </XStack>
 );
}
