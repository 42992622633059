import { retry } from '@reduxjs/toolkit/query/react';
import { selectAccessToken } from '../../store';
// import { selectToken } from '../../store/auth/auth-reducer';

// returns a function which creates an axios base query
export function createAxiosBaseQueryCreator(axiosInstance, maxRetries = 1) {
 
 return retry(
   async (requestOpts, { getState }) => {
     try {
        const reduxState = getState()
        console.log("createAxiosBaseQueryCreator - reduxState::", reduxState);
        const token = reduxState.authSlice?.accessToken; //selectAccessToken(reduxState); //undefined;
        // const token = selectAccessToken(reduxState); //undefined;        
        const result = await axiosInstance({
         ...requestOpts,
         params: {
           ...requestOpts.params,
         },
         headers: {
           ...requestOpts.headers,
           Authorization: token ? `Bearer ${token}` : undefined, // Use the fetched token
         }
       });
       console.log("axios result ::", result);
       return { data: result.data };
     } catch (axiosError) {
      console.log("axiosError ::", axiosError)
       const err = axiosError;
       return { error: { status: err.response?.status, data: err.response?.data } };
     }
   },
   { maxRetries }
 );
}
