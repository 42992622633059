import { FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { Typography, useThemeColorTokens } from '../../../elements';
import { Input, InputProps, YStack } from 'tamagui';

type TextFieldProps = {
  size?: string; 
  placeholder?: string; 
  helperText?: string;
  error?: boolean;
}

type FormTextFieldProps<T extends FieldValues> = UseControllerProps<T> & TextFieldProps & InputProps

export function FormTextField<T extends FieldValues>({
  name, 
  control,
  defaultValue,    
  error,    
  size, 
  placeholder, 
  helperText,
  ...props
}: FormTextFieldProps<T>){
  const {field, fieldState} = useController({control, name, defaultValue});
  const { onSurface, error: errorClr } = useThemeColorTokens();
  // console.log("field ::", field);
  // console.log("fieldState ::", fieldState);
  // console.log("fieldState - error::", error);  
  // console.log("helperText ::", helperText);

  return (
      <YStack width={"100%"}>
          <Input {...props} size={size} placeholder={placeholder}
            onChangeText={field.onChange} 
            value={field.value.toString()}
            fontFamily={'$body'}
            color={onSurface}            
          />
          {(error || !!helperText) &&
              <Typography variant="caption" color={error ? errorClr : onSurface}>{helperText}</Typography>
          }
      </YStack>
  )
}
