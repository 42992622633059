import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './styles/CustomScrollbar.css';
import { Provider } from 'react-redux';
import { persistor, store } from './store/config';
// import { ElementProvider } from './components';
import {Provider as ElementsProvider} from './elements/components'
import { AppRoutes } from './routes';
import { Buffer } from "buffer";
import "./libs/dayjs";
import { useAppInitialization } from './hooks';
import { PersistGate } from 'redux-persist/integration/react';

// Make Buffer available globally
(window as any).Buffer = Buffer;

function InitializedApp(){
  return <AppRoutes />
}

function RehydratedApp(){
  const {isInitialized, isError} = useAppInitialization();
  return (
    <>
      {isInitialized && <InitializedApp />}
      {/* Todo: <LoaderScreen /> */}
    </>
  )
}

function AppProvider(): React.JSX.Element{
  
  return (
      <Provider store={store}>
        <ElementsProvider>
          <PersistGate loading={null} persistor={persistor}>
            {/* <AppRoutes /> */}
            <RehydratedApp />
          </PersistGate>
        </ElementsProvider>
      </Provider>    
  )
}

function AppRoot(){

  return (
    <React.StrictMode>
      <AppProvider />
    </React.StrictMode>    
  )  
}

export default AppRoot;

// function App() {
//   const [token, setToken] = React.useState('');
//   return (
//       <div className='w-full'>
//         <ToastContainer/>
//         <Navbar setToken={setToken} Token={token}/>
//         <Slider/>
//         <Hero Token={token} />
//         <Footer/>
//         {/* <Analytics /> */}
//         {/* <Newsletter /> */}
//         {/* <Cards /> */}
//       </div>
//   );
// }

// export default App;
