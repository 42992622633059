//tonal color common to all theme which does not have light and dark variant
//it will render same hexcode for light and dark mode
export const tones = {
    warningTone0: '#000000ff',
    warningTone5: '#1e0d00ff',
    warningTone10: '#2d1600ff',
    warningTone15: '#3c1f00ff',
    warningTone20: '#4b2800ff',
    warningTone25: '#5b3100ff',
    warningTone30: '#6b3b00ff',
    warningTone35: '#7c4500ff',
    warningTone40: '#8d4f00ff',
    warningTone50: '#b06400ff',
    warningTone60: '#d57a00ff',
    warningTone70: '#f9920dff',
    warningTone80: '#ffb875ff',
    warningTone90: '#ffdcc0ff',
    warningTone95: '#ffeee1ff',
    warningTone98: '#fff8f5ff',
    warningTone99: '#fffbffff',
    warningTone100: '#ffffffff',
    successTone0: '#000000ff',
    successTone5: '#001507ff',
    successTone10: '#00210eff',
    successTone15: '#002d15ff',
    successTone20: '#00391dff',
    successTone25: '#004524ff',
    successTone30: '#00522cff',
    successTone35: '#005f34ff',
    successTone40: '#006d3cff',
    successTone50: '#00894dff',
    successTone60: '#00a65fff',
    successTone70: '#2bc374ff',
    successTone80: '#51df8eff',
    successTone90: '#70fda7ff',
    successTone95: '#c2ffd1ff',
    successTone98: '#e9ffebff',
    successTone99: '#f5fff3ff',
    successTone100: '#ffffffff',
    tealTone0: '#000000ff',
    tealTone5: '#001411ff',
    tealTone10: '#00201cff',
    tealTone15: '#002c27ff',
    tealTone20: '#003731ff',
    tealTone25: '#00443dff',
    tealTone30: '#005048ff',
    tealTone35: '#005d54ff',
    tealTone40: '#006a60ff',
    tealTone50: '#008679ff',
    tealTone60: '#00a293ff',
    tealTone70: '#2cbfaeff',
    tealTone80: '#53dbc9ff',
    tealTone90: '#74f8e5ff',
    tealTone95: '#b4fff2ff',
    tealTone98: '#e4fff9ff',
    tealTone99: '#f2fffbff',
    tealTone100: '#ffffffff',
    cyanTone0: '#000000ff',
    cyanTone5: '#001418ff',
    cyanTone10: '#001f25ff',
    cyanTone15: '#002a31ff',
    cyanTone20: '#00363eff',
    cyanTone25: '#00424cff',
    cyanTone30: '#004e59ff',
    cyanTone35: '#005b68ff',
    cyanTone40: '#006876ff',
    cyanTone50: '#008394ff',
    cyanTone60: '#009fb4ff',
    cyanTone70: '#00bcd4ff',
    cyanTone80: '#44d8f1ff',
    cyanTone90: '#a1efffff',
    cyanTone95: '#d4f7ffff',
    cyanTone98: '#eefcffff',
    cyanTone99: '#f7fdffff',
    cyanTone100: '#ffffffff',
    lightblueTone0: '#000000ff',
    lightblueTone5: '#001320ff',
    lightblueTone10: '#001e30ff',
    lightblueTone15: '#00293fff',
    lightblueTone20: '#00344fff',
    lightblueTone25: '#003f5fff',
    lightblueTone30: '#004b70ff',
    lightblueTone35: '#005881ff',
    lightblueTone40: '#006493ff',
    lightblueTone50: '#007eb8ff',
    lightblueTone60: '#0099deff',
    lightblueTone70: '#30b5ffff',
    lightblueTone80: '#8dcdffff',
    lightblueTone90: '#cae6ffff',
    lightblueTone95: '#e6f2ffff',
    lightblueTone98: '#f6f9ffff',
    lightblueTone99: '#fcfcffff',
    lightblueTone100: '#ffffffff',
    indigoTone0: '#000000ff',
    indigoTone5: '#000841ff',
    indigoTone10: '#00105cff',
    indigoTone15: '#001776ff',
    indigoTone20: '#08218aff',
    indigoTone25: '#1b2f95ff',
    indigoTone30: '#293ca0ff',
    indigoTone35: '#3649acff',
    indigoTone40: '#4355b9ff',
    indigoTone50: '#5d6fd4ff',
    indigoTone60: '#7789f0ff',
    indigoTone70: '#97a5ffff',
    indigoTone80: '#bac3ffff',
    indigoTone90: '#dee0ffff',
    indigoTone95: '#f0efffff',
    indigoTone98: '#fbf8ffff',
    indigoTone99: '#fefbffff',
    indigoTone100: '#ffffffff',
    deeppurpleTone0: '#000000ff',
    deeppurpleTone5: '#18003fff',
    deeppurpleTone10: '#250059ff',
    deeppurpleTone15: '#320073ff',
    deeppurpleTone20: '#3f008dff',
    deeppurpleTone25: '#4b169bff',
    deeppurpleTone30: '#5727a6ff',
    deeppurpleTone35: '#6336b3ff',
    deeppurpleTone40: '#6f43c0ff',
    deeppurpleTone50: '#895edbff',
    deeppurpleTone60: '#a478f7ff',
    deeppurpleTone70: '#bc99ffff',
    deeppurpleTone80: '#d3bbffff',
    deeppurpleTone90: '#ebddffff',
    deeppurpleTone95: '#f7edffff',
    deeppurpleTone98: '#fef7ffff',
    deeppurpleTone99: '#fffbffff',
    deeppurpleTone100: '#ffffffff',
    purpleTone0: '#000000ff',
    purpleTone5: '#24002bff',
    purpleTone10: '#35003fff',
    purpleTone15: '#460052ff',
    purpleTone20: '#570066ff',
    purpleTone25: '#69007aff',
    purpleTone30: '#7b008fff',
    purpleTone35: '#8c10a1ff',
    purpleTone40: '#9a25aeff',
    purpleTone50: '#b744caff',
    purpleTone60: '#d560e6ff',
    purpleTone70: '#f07effff',
    purpleTone80: '#f9abffff',
    purpleTone90: '#ffd6feff',
    purpleTone95: '#ffebfbff',
    purpleTone98: '#fff7faff',
    purpleTone99: '#fffbffff',
    purpleTone100: '#ffffffff',
    pinkTone0: '#000000ff',
    pinkTone5: '#29001dff',
    pinkTone10: '#3b002cff',
    pinkTone15: '#4e003aff',
    pinkTone20: '#600049ff',
    pinkTone25: '#740059ff',
    pinkTone30: '#880069ff',
    pinkTone35: '#9d0079ff',
    pinkTone40: '#b2008aff',
    pinkTone50: '#d32ca5ff',
    pinkTone60: '#f44bc1ff',
    pinkTone70: '#ff7ed0ff',
    pinkTone80: '#ffaedcff',
    pinkTone90: '#ffd8ebff',
    pinkTone95: '#ffecf3ff',
    pinkTone98: '#fff8f8ff',
    pinkTone99: '#fffbffff',
    pinkTone100: '#ffffffff',
    roseTone0: '#000000ff',
    roseTone5: '#2c0009ff',
    roseTone10: '#400011ff',
    roseTone15: '#530019ff',
    roseTone20: '#670020ff',
    roseTone25: '#7c0029ff',
    roseTone30: '#910031ff',
    roseTone35: '#a7003aff',
    roseTone40: '#bd0143ff',
    roseTone50: '#e12b5aff',
    roseTone60: '#ff4f74ff',
    roseTone70: '#ff8798ff',
    roseTone80: '#ffb2baff',
    roseTone90: '#ffd9dcff',
    roseTone95: '#ffecedff',
    roseTone98: '#fff8f7ff',
    roseTone99: '#fffbffff',
    roseTone100: '#ffffffff',
    yellowTone0: '#000000ff',
    yellowTone5: '#141100ff',
    yellowTone10: '#201c00ff',
    yellowTone15: '#2b2600ff',
    yellowTone20: '#363100ff',
    yellowTone25: '#433c00ff',
    yellowTone30: '#4f4800ff',
    yellowTone35: '#5c5300ff',
    yellowTone40: '#695f00ff',
    yellowTone50: '#847800ff',
    yellowTone60: '#a09200ff',
    yellowTone70: '#bdad00ff',
    yellowTone80: '#dbc90aff',
    yellowTone90: '#f9e534ff',
    yellowTone95: '#fff39eff',
    yellowTone98: '#fff9e8ff',
    yellowTone99: '#fffbffff',
    yellowTone100: '#ffffffff',
    amberTone0: '#000000ff',
    amberTone5: '#180f00ff',
    amberTone10: '#261a00ff',
    amberTone15: '#322300ff',
    amberTone20: '#3f2e00ff',
    amberTone25: '#4d3800ff',
    amberTone30: '#5b4300ff',
    amberTone35: '#6a4e00ff',
    amberTone40: '#785900ff',
    amberTone50: '#977100ff',
    amberTone60: '#b78a00ff',
    amberTone70: '#d8a300ff',
    amberTone80: '#fabd00ff',
    amberTone90: '#ffdf9eff',
    amberTone95: '#ffefd4ff',
    amberTone98: '#fff8f2ff',
    amberTone99: '#fffbffff',
    amberTone100: '#ffffffff',
    orangeTone0: '#000000ff',
    orangeTone5: '#1d0d00ff',
    orangeTone10: '#2c1600ff',
    orangeTone15: '#3b1f00ff',
    orangeTone20: '#4a2800ff',
    orangeTone25: '#593200ff',
    orangeTone30: '#693c00ff',
    orangeTone35: '#7a4600ff',
    orangeTone40: '#8b5000ff',
    orangeTone50: '#ae6600ff',
    orangeTone60: '#d27c00ff',
    orangeTone70: '#f79300ff',
    orangeTone80: '#ffb870ff',
    orangeTone90: '#ffdcbeff',
    orangeTone95: '#ffeee1ff',
    orangeTone98: '#fff8f5ff',
    orangeTone99: '#fffbffff',
    orangeTone100: '#ffffffff',
    deeporangeTone0: '#000000ff',
    deeporangeTone5: '#280500ff',
    deeporangeTone10: '#3b0900ff',
    deeporangeTone15: '#4d0f00ff',
    deeporangeTone20: '#5f1500ff',
    deeporangeTone25: '#731b00ff',
    deeporangeTone30: '#862200ff',
    deeporangeTone35: '#9b2800ff',
    deeporangeTone40: '#b02f00ff',
    deeporangeTone50: '#db3e03ff',
    deeporangeTone60: '#ff5722ff',
    deeporangeTone70: '#ff8b69ff',
    deeporangeTone80: '#ffb5a0ff',
    deeporangeTone90: '#ffdbd1ff',
    deeporangeTone95: '#ffede8ff',
    deeporangeTone98: '#fff8f6ff',
    deeporangeTone99: '#fffbffff',
    deeporangeTone100: '#ffffffff',
    limeTone0: '#000000ff',
    limeTone5: '#101200ff',
    limeTone10: '#1a1d00ff',
    limeTone15: '#242800ff',
    limeTone20: '#2f3300ff',
    limeTone25: '#393f00ff',
    limeTone30: '#444b00ff',
    limeTone35: '#505700ff',
    limeTone40: '#5b6300ff',
    limeTone50: '#737d00ff',
    limeTone60: '#8c9800ff',
    limeTone70: '#a6b400ff',
    limeTone80: '#c1d02cff',
    limeTone90: '#dded49ff',
    limeTone95: '#ecfb57ff',
    limeTone98: '#f9ffb4ff',
    limeTone99: '#feffd8ff',
    limeTone100: '#ffffffff',
    lightgreenTone0: '#000000ff',
    lightgreenTone5: '#081400ff',
    lightgreenTone10: '#0f2000ff',
    lightgreenTone15: '#172b00ff',
    lightgreenTone20: '#1e3700ff',
    lightgreenTone25: '#264300ff',
    lightgreenTone30: '#2e4f00ff',
    lightgreenTone35: '#365c00ff',
    lightgreenTone40: '#3e6a00ff',
    lightgreenTone50: '#52840bff',
    lightgreenTone60: '#6a9f2aff',
    lightgreenTone70: '#83bb43ff',
    lightgreenTone80: '#9ed75bff',
    lightgreenTone90: '#b9f474ff',
    lightgreenTone95: '#d2ff9dff',
    lightgreenTone98: '#f0ffd8ff',
    lightgreenTone99: '#f8ffe9ff',
    lightgreenTone100: '#ffffffff',
    greenTone0: '#000000ff',
    greenTone5: '#001502ff',
    greenTone10: '#002204ff',
    greenTone15: '#002d07ff',
    greenTone20: '#00390aff',
    greenTone25: '#00460fff',
    greenTone30: '#005313ff',
    greenTone35: '#006018ff',
    greenTone40: '#006e1cff',
    greenTone50: '#22892fff',
    greenTone60: '#41a447ff',
    greenTone70: '#5dc05eff',
    greenTone80: '#78dc77ff',
    greenTone90: '#94f990ff',
    greenTone95: '#c8ffc0ff',
    greenTone98: '#ecffe4ff',
    greenTone99: '#f6fff0ff',
    greenTone100: '#ffffffff'
   };
   
   
   
   
   
   