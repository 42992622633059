import { Typography, useBreakpoint, useThemeColorTokens } from '../../elements';
// import { selectOlympusDetails, useAppSelector } from '../../store';
import { Image, XStack } from 'tamagui';


export type AppLogoProps = {
 cache?: boolean;
};


export function AppLogo(props: AppLogoProps) {
    const { primary } = useThemeColorTokens();                
 const { gt } = useBreakpoint({ gtLg: 32, gtXXs: 28 });


 function onPress() {
//    linkTo('/dashboard');
 }


 return (
   <XStack minWidth={'$1'} gap={"$1"} alignItems='center'>
        <Image
          cursor="pointer"
          source={{ uri: 'https://media-hosting.imagekit.io/2bb546c0c345443a/logo192.png?Expires=1838144803&Key-Pair-Id=K2ZIVPTIP2VGHC&Signature=u2RKIoJWKvBUNUde-zcLsGmGIjRToEFlU7aQ7vdm54BwmATkSuiXa~v7p-lHUTAcQiB6~np7xvoQf0L9VdyY8vIzLmogVulkEhMZE-k1VP506b9i6kCo~WpipxpJXBJJsuYK~cbfBYEXgk0dK9QKiL3fzFRcGfXzPGHkn4sqoPSw84N5eGLsUGKxaZUDsz0Gi-zv~mE1gCuzLjjnp2J4EBXuSdCCrT~qTXaNx8X1Yzp4COPLFxq-CHa-SY-AWiGSHy7UtVtOxXOAirdmXAo2ArTrztmbXFsmcrypbu3bfeOKyWoersRWIk9ErxFaKQgzPikrSdIpGODJbit52q4xew__' }}
          width={35}
          height={35}
        />

        <Typography
          variant='subtitle1' 
          numberOfLines={1}
          ellipsizeMode="tail"
          // textDecorationLine="underline"
          color={primary}
        >
          Panda Seva
        </Typography> 
   </XStack>
 );
}



