import axios from 'axios';
import { createAxiosBaseQueryCreator } from '../utils';
import { createApi } from '@reduxjs/toolkit/query/react';

const ayanshalaBackendAxiosInstance = axios.create({
    baseURL: 'https://pitrapaksha.com/v1/'
});

const axiosBaseQuery = () => {
    return createAxiosBaseQueryCreator(ayanshalaBackendAxiosInstance, 1);
};

const ayanshalaBackendBaseQuery = axiosBaseQuery();

export const ayanshalaBackendApi = createApi({
    reducerPath: 'ayanshalaBackend',
    baseQuery: ayanshalaBackendBaseQuery,
    tagTypes: ['CancelBooking'],    
    endpoints: () => ({}),
    keepUnusedDataFor: 60 * 60 * 24 // 24 hours
});

export default ayanshalaBackendApi;
