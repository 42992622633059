import { ayanshalaBackendApi } from '../ayanshala-backend';
import { GetBookingDetailsRequest, GetBookingDetailsResponse, GetPublicFeedbacksRequest, GetPublicFeedbacksResponse, GetPublicPandaProfileRequest, GetPublicPandaProfileResponse } from './types';

const bookingApi = ayanshalaBackendApi.injectEndpoints({
  endpoints: (builder) => ({
    createBooking: builder.mutation({
      query: (bookingData) => ({
        url: `client/createBooking`,
        method: 'POST',
        data: bookingData,
      }),
    }),
    cancelBooking: builder.mutation({
      query: (bookingId) => ({
        url: `client/cancelBooking?bookingId=${bookingId}`, // Use bookingId in the URL
        method: 'PUT',
      }),
      invalidatesTags: ['CancelBooking']      
    }),
    getAllBookings: builder.query({
      query: () => ({
        url: `client/getAllBookings`,
        method: 'GET',
      }),
      providesTags: ['CancelBooking']
    }),
    getPublicPandaProfile: builder.query<GetPublicPandaProfileResponse, GetPublicPandaProfileRequest>({
      query: (data) => ({
        url: `data/public/getPandaProfileData`,
        method: 'GET',
        params: {
          pandaId: data.panda_id
        }
      }),
    }),
    publicBookingRequest: builder.mutation({
      query: (bookingData) => ({
        url: `data/public/bookingRequest`,
        method: 'POST',
        data: bookingData,
      }),
    }),
    getPublicFeedbacks: builder.query<GetPublicFeedbacksResponse, GetPublicFeedbacksRequest>({
      query: (data) => ({
        url: `data/public/getFeedbacks`,
        method: 'GET',
        params: {
          pandaId: data.panda_id
        }
      }),
    }),
    getBookingDetails: builder.query<GetBookingDetailsResponse, GetBookingDetailsRequest>({
        query: ({bookingId}) => ({
            url: 'user/bookingDetails',
            method: 'GET',
            params: {
                bookingId
            }
        }),
    }),

    // Add more endpoints as needed
  }),
});

export const {
  useCreateBookingMutation,
  useCancelBookingMutation,
  useGetAllBookingsQuery,
  useGetPublicPandaProfileQuery,
  useLazyGetPublicPandaProfileQuery,
  usePublicBookingRequestMutation,
  useGetPublicFeedbacksQuery,
  useGetBookingDetailsQuery
} = bookingApi;
