import { either, isEmpty, isNil } from "ramda";

export function getFirstName(fullName) {
    if(!fullName){
      return '';
    }
    // Find the index of the first space
    const firstSpaceIndex = fullName.indexOf(" ");
    
    // If there is no space, return the full name (single name case)
    // Otherwise, return the substring from the start to the first space
    return firstSpaceIndex === -1 ? fullName : fullName.substring(0, firstSpaceIndex);
  }

  export function isNilOrEmpty(value: any): value is null | undefined | boolean {
    return either(isNil, isEmpty)(value);
  }
  